import React from 'react';
import { ReactComponent as SvgLogo } from 'assets/icons/logo-full.svg';
import { ReactComponent as SvgArrow } from 'assets/icons/arrow.svg';
import { Button } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { Form, Formik } from 'formik';
import { requestResetPassword } from 'services/users';
import { useToast } from 'hooks';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
	const show = useToast();
	const navigate = useNavigate();
	const [isSending, setIsSending] = React.useState(false);
	const [backendError, setBackendError] = React.useState<Record<string, any>>(
		{}
	);

	return (
		<div className="w-full min-h-screen flex flex-col items-center justify-between relative">
			<button
				className="absolute top-10 left-10 w-12 h-12 flex items-center justify-center"
				onClick={() => navigate(-1)}
			>
				<SvgArrow />
			</button>
			<div className="w-full flex flex-col max-w-sm mt-20">
				<SvgLogo className="svg-no-prefill w-4/5 mb-10" />

				<p className="text-sm font-medium text-gray-700 mb-2.5">
					Enter your email address and we will send you a password reset link.
				</p>

				<Formik
					initialValues={{
						email: ''
					}}
					validationSchema={Yup.object().shape({
						email: Yup.string()
							.email('Invalid email format')
							.required('This field is required')
					})}
					onSubmit={(values, { resetForm }) => {
						setIsSending(true);

						requestResetPassword(values)
							.then(() => {
								show({
									type: 'success',
									headerText: 'Success!',
									message: 'Request sent successfully, check your email'
								});
								resetForm();
								setBackendError({});
								setIsSending(false);
							})
							.catch(error => {
								if (error.response.data) {
									setBackendError(error.response.data);
								}
								setIsSending(false);
							});
					}}
				>
					{props => {
						const { errors, submitCount } = props;

						return (
							<Form className="login-form flex flex-col">
								<FieldInput
									name="email"
									placeholder="Enter your email"
									id="email"
									className="w-full"
									error={
										submitCount > 0 &&
										((errors.email && errors.email) ||
											(backendError.error && backendError.error) ||
											'')
									}
								/>

								<Button
									type="submit"
									text="Submit"
									rounded="medium"
									className="w-2/3 mt-10"
									disabled={isSending}
									loading={isSending}
								/>
							</Form>
						);
					}}
				</Formik>
			</div>
			<span className="text-gray-500 text-sm text-center my-8">
				2022. Megatrend poslovna rješenja d.o.o.
			</span>
		</div>
	);
};

export default ForgotPassword;
