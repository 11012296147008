import React from 'react';
import { useField } from 'formik';

import {
	CustomSelect,
	ICustomSelectProps,
	IOption
} from 'components/form-elements';

export const FieldSelect: React.FC<ICustomSelectProps> = ({
	name,
	options,
	initialValue,
	onChange,
	...rest
}) => {
	const [field, meta, helpers] = useField(name);

	const { value } = field;
	const { setValue, setTouched } = helpers;
	const { error } = meta;
	const valueObject = options.find(
		(option: IOption) => option.value === value
	);

	React.useEffect(() => {
		setValue(value || options[0].value);
	}, [options[0].value]);

	return (
		<CustomSelect
			className="w-full"
			name={name}
			value={valueObject}
			options={options}
			onChange={value => {
				setValue(value.value, true);
				setTouched(true, false);
				onChange && onChange(value);
			}}
			initialValue={initialValue}
			error={error}
			{...rest}
		/>
	);
};
