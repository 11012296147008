import axios from 'axios';
import { IEmailTemplateData } from '.';

export const postEmailTemplate = async (
	emailTemplateData: IEmailTemplateData
) => {
	return await axios
		.post<IEmailTemplateData>(
			`${process.env.REACT_APP_API_URL}/email_templates/`,
			emailTemplateData
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error posting email template:`, error);
			return Promise.reject(error);
		});
};
