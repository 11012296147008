import React, { useState } from 'react';
import * as Yup from 'yup';
import {SketchPicker} from 'react-color';

import { Button, Loader } from 'components/common';
import { CustomInput, IOption } from 'components/form-elements';

import { useToast } from 'hooks';
import { savePdf} from 'utils';
import { usePostSurveyPdfQuery } from 'services/surveys';
import { ISurvey } from 'services/surveys/interfaces';

import { ReactComponent as SvgClipboard } from 'assets/icons/clipboard.svg';
import qrMockup from 'assets/images/qrMockup.png';
import {  Form, Formik } from 'formik';
import { FieldCheckbox, FieldMultiSelect, FieldSelect, FieldTextArea } from 'components/formik-elements';
import { CustomFileUpload } from 'components/form-elements/CustomFileUpload';
import { postFile } from 'services/files';
import { postQrCode, postQrCodeLogo} from 'services/surveys/postQrCode';
import { savePng } from 'utils/savePng';
import { useGetSurveyQRCodeQuery } from 'services/surveys/getSurveyQRCode';
import { hexToRgb } from '../helpers/hexToRgb';
import { Layout } from 'components/shared';



interface ISurveyOptionsProps {
	survey: ISurvey;
}

export const SurveyOptions: React.FC<ISurveyOptionsProps> = ({ survey }) => {
	const show = useToast();

	const [pdfData, setPdfData] = React.useState<any>({});
	const { data: pdf, isLoading: isLoadingPdfData } = usePostSurveyPdfQuery(
		survey.survey_id,
		{
			invitation_text: pdfData?.invitation_text,
			language_list: pdfData?.language_list?.map((item: any) => item.value)
		}
	);

	const {data: surveyQrCodeData, isLoading: isSurveyQRCodeLoading} = useGetSurveyQRCodeQuery(survey.survey_id);

	
	const surveyLink = React.useMemo(
		() => (survey?.survey_link ? survey.survey_link : ''),
		[survey]
	);

	//@todo: this is not a perfect solution but it works, try to fix it
	React.useEffect(() => {
		if (survey.survey_link === null) {
			location.reload();
		}
	}, [survey.survey_link]);

	React.useEffect(() => {
		const qrCode = document.getElementById('qrCode');
		qrCode?.setAttribute('viewBox', '0 0 256 256');
		qrCode?.setAttribute('height', 'auto');
		qrCode?.setAttribute('width', '100%');
	}, []);

	const clipboardCopyHandler = () => {
		navigator.clipboard.writeText(surveyLink);
		show({
			type: 'success',
			headerText: 'Copied!',
			message: 'Successfully copied to clipboard'
		});
	};

	const pdfDownloadHandler = () => {
		savePdf(
			pdf,
			survey?.survey_name
				? survey.survey_name.split(' ').join('-')
				: 'surveyPdf'
		);
	};

	const qrDownloadHandler = () => {
		//const qrCode = document.getElementById('qrCode');
		savePng(
			surveyQrImage,
			survey?.survey_name
				? survey.survey_name.split(' ').join('')+'-qrCode'
				: 'surveyQrCode'
		);
	};

	

	
// Primary Color
const [sketchPickerColor, setSketchPickerColor] = useState(surveyQrCodeData?.single_color? hexToRgb(surveyQrCodeData?.single_color):{
	r: 0,
	g: 0,
	b: 0,
	a: 1,
  });
  const { r: primaryR, g: primaryG, b: primaryB, a: primaryA } = sketchPickerColor;
  const [isSketchPickerVisible, setIsSketchPickerVisible] = useState(false);
  const toggleSketchPicker = () => {
	setIsSketchPickerVisible(!isSketchPickerVisible);
	setIsGradientSketchPickerVisible(false);
	setIsBackgroundSketchPickerVisible(false);
  };

  const [primaryHtml, setPrimaryHtml] = useState(surveyQrCodeData?.single_color? surveyQrCodeData.single_color:"#000000");
  
  // Gradient Color
  const [gradientSketchPickerColor, setGradientSketchPickerColor] = useState(surveyQrCodeData?.gradient_color? hexToRgb(surveyQrCodeData?.gradient_color):{
	r: 119,
	g: 190,
	b: 250,
	a: 1,
  });
  const { r: gradientR, g: gradientG, b: gradientB, a: gradientA } = gradientSketchPickerColor;
  const [isGradientSketchPickerVisible, setIsGradientSketchPickerVisible] = useState(false);
  const toggleGradientSketchPicker = () => {
	setIsGradientSketchPickerVisible(!isGradientSketchPickerVisible);
	setIsSketchPickerVisible(false);
	setIsBackgroundSketchPickerVisible(false);
  };

  const [isQrGradient, setIsQrGradient] = useState(surveyQrCodeData?.gradient!==null && surveyQrCodeData?.gradient!==undefined ? surveyQrCodeData?.gradient : false);
  const [gradientHtml, setGradientHtml] = useState(surveyQrCodeData?.gradient_color? surveyQrCodeData.gradient_color:"#77BEFE");

  // Background Color
  const [backgroundSketchPickerColor, setBackgroundSketchPickerColor] = useState(surveyQrCodeData?.background_color? hexToRgb(surveyQrCodeData?.background_color):{
	r: 255,
	g: 255,
	b: 255,
	a: 255,
  });
  const { r: backgroundR, g: backgroundG, b: backgroundB, a: backgroundA } = backgroundSketchPickerColor;
  const [isBackgroundSketchPickerVisible, setIsBackgroundSketchPickerVisible] = useState(false);
  const toggleBackgroundSketchPicker = () => {
	setIsBackgroundSketchPickerVisible(!isBackgroundSketchPickerVisible);
	setIsSketchPickerVisible(false);
	setIsGradientSketchPickerVisible(false);
  };
  const [backgroundHtml, setBackgroundHtml] = useState(surveyQrCodeData?.background_color? surveyQrCodeData.background_color:"#ffffff");


	const languageOptions = [
		{
			value: 'en',
			label: 'English'
		},
		{
			value: 'de',
			label: 'German'
		},
		{
			value: 'hr',
			label: 'Croatian'
		},
		{
			value: 'es',
			label: 'Spanish'
		},
		{
			value: 'fr',
			label: 'French'
		},
		{
			value: 'rs',
			label: 'Serbian'
		},
		{
			value: 'it',
			label: 'Italian'
		},
		{
			value: 'al',
			label: 'Albanian'
		},
		{
			value: 'si',
			label: 'Slovenian'
		}
	];

	const qrtypes = [
		{
		  value: 'square',
		  display: 'Square'
		},
		{
		  value: 'horizontal',
		  display: 'Horizontal'
		},
		{
			value: 'vertical',
			display: 'Vertical'
		  },
		  {
			value: 'gapped_square',
			display: 'Gapped square'
		  },
		  {
			value: 'round',
			display: 'Rounded'
		  },
		  {
			value: 'circle',
			display: 'Circle'
		  },
	  ];

	  const [moduleDrawer,setModuleDrawer]= React.useState<IOption>(surveyQrCodeData && surveyQrCodeData?.module_drawer!==null ? qrtypes.find(x=> x.value==surveyQrCodeData.module_drawer) || qrtypes[0]: qrtypes[0]);
	  

	  const handleModuleDrawerChange = (selectedOption: IOption) => {
		setModuleDrawer(selectedOption);
	  };
	  const [surveyLogo, setSurveyLogo] = useState<string | null>(surveyQrCodeData?.qr_logo || null);
	  const handleFileUpload = (e: any) => {
		postFile(e.target.files)
		  .then((res) => {
			setSurveyLogo(res.file_url); // Update the surveyLogo state with the file URL
			postQrCodeLogo(survey.survey_id,{url:res.file_url});
		  })
		  .catch((error) => {
			// Handle the error, if any
			console.error('Error uploading file:', error);
		  });
	  };
	  const clearQrLogo = () => {
		postQrCodeLogo(survey.survey_id,{url:null})
		  .then(() => {
			setSurveyLogo(null); 
			;
		  })
		  .catch((error) => {
			console.error('Error clearing logo:', error);
		  });
	  };

	  const[isLoadingQrCode, setIsLoadingQrCode] = useState(false);
	  
	  const generateQrCode = () => {
		setIsLoadingQrCode(true);
		postQrCode(survey.survey_id, {
			single_color: primaryHtml,
			gradient: isQrGradient,
			gradient_color: gradientHtml,
			background_color: backgroundHtml,
			module_drawer: typeof moduleDrawer.value === 'string' ? moduleDrawer.value : 'square'
		  })
			.then((qr) => {
			  setSurveyQrCode(qr.qr_code);
			  setSurveyQrImage(qr.qr_code_base64);
			})
			.catch((error) => {
			  // Handle the error, if any
			  console.error('Error generating QR code:', error);
			})
			.finally(() =>{
				setIsLoadingQrCode(false);
			}
				
			);
	  }
	
	  const [surveyQrCode, setSurveyQrCode] = useState(surveyQrCodeData?.qr_code);
	  const [surveyQrImage, setSurveyQrImage] = useState(surveyQrCodeData?.qr_code_base64);
	
	  React.useEffect(() => {
		if (surveyQrCodeData && surveyQrCodeData.single_color) {
		  setSketchPickerColor(
			surveyQrCodeData.single_color
			  ? hexToRgb(surveyQrCodeData.single_color)
			  : {
				  r: 119,
				  g: 190,
				  b: 250,
				  a: 1,
				}
		  );
	  
		  setPrimaryHtml(
			surveyQrCodeData.single_color ? surveyQrCodeData.single_color : '#77BEFE'
		  );
		}

		if (surveyQrCodeData && surveyQrCodeData.background_color) {
			setBackgroundSketchPickerColor(
			  surveyQrCodeData.background_color
				? hexToRgb(surveyQrCodeData.background_color)
				: {
					r: 255,
					g: 255,
					b: 255,
					a: 1,
				  }
			);
		
			setBackgroundHtml(
			  surveyQrCodeData.background_color ? surveyQrCodeData.background_color : '#FFFFFF'
			);
		  }

		  if (surveyQrCodeData && surveyQrCodeData.gradient_color) {
			setGradientSketchPickerColor(
			  surveyQrCodeData.gradient_color
				? hexToRgb(surveyQrCodeData.gradient_color)
				: {
					r: 119,
					g: 190,
					b: 250,
					a: 1,
				  }
			);
		
			setGradientHtml(
			  surveyQrCodeData.gradient_color ? surveyQrCodeData.gradient_color : '#77BEFE'
			);
		  }

		  if (surveyQrCodeData && surveyQrCodeData.qr_logo) {
			setSurveyLogo(surveyQrCodeData.qr_logo)
			}
		if (surveyQrCodeData && surveyQrCodeData.module_drawer) {
				setModuleDrawer(qrtypes.find(x=> x.value==surveyQrCodeData.module_drawer) || qrtypes[0])
		}
		if(surveyQrCodeData && surveyQrCodeData.qr_code){
			setSurveyQrCode(surveyQrCodeData.qr_code);
			setSurveyQrImage(surveyQrCodeData.qr_code_base64);
		}
		if(surveyQrCodeData && surveyQrCodeData.gradient != null){
			setIsQrGradient(surveyQrCodeData.gradient);
		}
		
	  }, [surveyQrCodeData]);
	  if (
		isSurveyQRCodeLoading
	) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	} else
	return (
		<>
			<CustomInput
				name="share_url"
				label="Share URL"
				value={surveyLink}
				disabled
				customIcon={
					<button onClick={clipboardCopyHandler}>
						<SvgClipboard />
					</button>
				}
				className="mb-10"
			/>
			<p className="block text-sm font-medium text-gray-700 truncate mb-4 w-full">
					QR code generator
				</p>
			<div  className="p-10 grid grid-cols-3 gap-6 border-2 border-solid border-grey rounded-md">
				<div className='col-span-1 flex justify-center'>
					QR color
				</div>
				<div className=' flex justify-center'>
					QR code type
				</div>
				<div className=' flex justify-center'>
					QR logo
				</div>
				<div className="col-span-1">
					<div className="grid grid-rows-3 gap-6">
					<div className="flex items-center">
						<h6 className='w-36'>Background color</h6>					
						<div
						className={`rounded-md flex justify-center items-center`}
						style={{
							backgroundColor: `rgba(${backgroundR},${backgroundG},${backgroundB},${backgroundA})`,
							color:  backgroundHtml==='#000000'?'white':'black',
							width: 100,
							height: 50,
							border: "2px solid #121212",
							cursor: "pointer",
						}}
						onClick={toggleBackgroundSketchPicker}
						>{backgroundHtml.toUpperCase()}</div>
						{/* Conditionally render SketchPicker based on state */}
						{isBackgroundSketchPickerVisible && (
						<div className="relative z-[10000]">
							<button onClick={toggleBackgroundSketchPicker} type='button' className='absolute bottom-0 w-7 h-7 bg-red-500 text-white' >X</button>
						
							<div className="absolute right-50">
							<SketchPicker
								onChange={(color) => {
								setBackgroundSketchPickerColor({
									r: typeof color.rgb.r === 'number' ? color.rgb.r : 1,
									g: typeof color.rgb.g === 'number' ? color.rgb.g : 1,
									b: typeof color.rgb.b === 'number' ? color.rgb.b : 1,
									a: typeof color.rgb.a === 'number' ? color.rgb.a : 1,
								});
								setBackgroundHtml(color.hex);
								}}
								color={backgroundSketchPickerColor}
							/>
							</div>
						</div>
						)}
					</div>
						<div className="flex items-center">
							<h6 className='w-36'>Primary color</h6>
						<div
						className="rounded-md flex justify-center items-center"
						style={{
							backgroundColor: `rgba(${primaryR},${primaryG},${primaryB},${primaryA})`,
							color:  primaryHtml==='#000000'?'white':'black',
							width: 100,
							height: 50,
							border: "2px solid #121212",
							cursor: "pointer",
						}}
						onClick={toggleSketchPicker}
						>{primaryHtml.toUpperCase()}</div>
						
						{isSketchPickerVisible && (
						<div className="relative z-[10000]">
							<button onClick={toggleSketchPicker} type='button' className='absolute bottom-0 w-7 h-7 bg-red-500 text-white' >X</button>
						
							<div className="absolute right-50">
							<SketchPicker
								onChange={(color) => {
								setSketchPickerColor({
									r: typeof color.rgb.r === 'number' ? color.rgb.r : 1,
									g: typeof color.rgb.g === 'number' ? color.rgb.g : 1,
									b: typeof color.rgb.b === 'number' ? color.rgb.b : 1,
									a: typeof color.rgb.a === 'number' ? color.rgb.a : 1,
								});
								setPrimaryHtml(color.hex);
								}}
								color={sketchPickerColor}
							/>
							</div>
						</div>
						)}
					</div>
					<div>
					<FieldCheckbox
						name="gradient"
						id="gradient"
						label="QR code with gradient?"
						type="checkbox"
						variant="toggler"
						value={isQrGradient}
						className='mb-0'
						onChange={(e) => setIsQrGradient(e.target.checked)}
					/>
						<div className={`flex items-center ${!isQrGradient ? "opacity-25":""}`}>
							<h6 className='w-36'>Gradient color</h6>
						<div
						className={`rounded-md flex justify-center items-center ${
							!isQrGradient ? "pointer-events-none" : ""
						}`}
						style={{
							backgroundColor: `rgba(${gradientR},${gradientG},${gradientB},${gradientA})`,
							color:  gradientHtml==='#000000'?'white':'black',
							width: 100,
							height: 50,
							border: "2px solid #121212",
							cursor: "pointer",
						}}
						onClick={isQrGradient ? toggleGradientSketchPicker : undefined}
						>{gradientHtml.toUpperCase()}</div>
						
						{isGradientSketchPickerVisible && isQrGradient && (
						<div className="relative z-[10000]">
						
							
							<div className="absolute right-50">
							<SketchPicker
								onChange={(color) => {
								setGradientSketchPickerColor({
									r: typeof color.rgb.r === 'number' ? color.rgb.r : 1,
									g: typeof color.rgb.g === 'number' ? color.rgb.g : 1,
									b: typeof color.rgb.b === 'number' ? color.rgb.b : 1,
									a: typeof color.rgb.a === 'number' ? color.rgb.a : 1,
								});
								setGradientHtml(color.hex);
								}}
								color={gradientSketchPickerColor}
							/>
							</div>
							<button onClick={toggleGradientSketchPicker} type='button' className='absolute bottom-0 w-7 h-7 bg-red-500 text-white' >X</button>
						
							
						</div>
						)}
					</div>
					</div>
					</div>
				</div>
				<div className="col-span-1 flex-col  justify-center items-center">
					
							<div className="flex w-full justify-center max-w-md items-center">
								<FieldSelect
									name="survey_type"
									id="survey_type"
									label=""
									placeholder="QR code type"
									options={qrtypes}
									className="mb-6 w-3/4"
									value={moduleDrawer}
									onChange={handleModuleDrawerChange}
								/>
							</div>
							<div className="flex w-full justify-center max-w-md items-center">
								
								<img src={require(`assets/images/${moduleDrawer.value}.png`)} className='w-1/2' />
								
							</div>
						
					</div>
					<div className="col-span-1 flex flex-col justify-center items-center">
					
					<div
						className={`flex ${
							surveyLogo === '' ? 'items-center' : 'items-start'
						}`}
					>
						{surveyLogo !== null && surveyLogo!=undefined && surveyLogo !== ''   && (
							
							<img
								src={surveyLogo}
								alt="qr logo"
								className="object-contain w-3/4 max-w-3/4 mx-auto"
							/>
							
						)}

						<CustomFileUpload
							name="logo"
							id="logo"
							onUpload={handleFileUpload}
							onClear={() => {
								clearQrLogo();
							}}
							hasValue={surveyLogo}
						/>
						</div>
					</div>
					<div className="col-span-3 flex justify-center" >
						<Button
						text="Generate new QR code"
						variant="light"
						className="mt-6"
						onClick={generateQrCode}
						loading={isLoadingQrCode}
				/>
					</div>
					<div className="col-span-3 flex justify-center">

					{surveyQrCode && (
							<img id="qrCode" className='w-1/4' src={surveyQrCode}></img>	
						)}
					</div>
					<div className="col-span-3 flex justify-center">
					<Button
							text="Download QR code"
							size="small"
							className="mt-6"
							onClick={qrDownloadHandler}
							disabled={!surveyQrImage}
						/>
					</div>
				</div>



			

			

			<div className="flex flex-col mt-10">
				<p className="block text-sm font-medium text-gray-700 truncate mb-4 w-full">
					PDF options
				</p>
				<Formik
					validationSchema={Yup.object().shape({
						invitation_text: Yup.string()
							.min(5, 'Minimum 5 characters')
							.required('This field is required'),
						language_list: Yup.array()
							.min(1, 'Minimum 1 choice')
							.max(6, 'Maximum 6 choices')
					})}
					initialValues={{
						invitation_text: '',
						language_list: []
					}}
					onSubmit={values => setPdfData(values)}
				>
					{props => {
						const { submitCount, errors } = props;

						return (
							<Form className="flex">
								<img className="mr-8" src={qrMockup} alt="qr code pdf mockup" />

								<div className="flex flex-col w-1/2 flex-1">
									<FieldTextArea
										rows={4}
										maxLength={120}
										name="invitation_text"
										label="Invitation text"
										id="invitation_text"
										className="mb-2"
										error={
											submitCount > 0 && errors.invitation_text
												? errors.invitation_text
												: ''
										}
									/>

									<FieldMultiSelect
										name="language_list"
										id="language_list"
										label="Languages"
										options={languageOptions}
										error={
											submitCount > 0 && errors?.language_list
												? String(errors?.language_list)
												: ''
										}
										className="w-full"
										isMulti
									/>

									<div className="flex mt-auto">
										<Button
											text="Set PDF info"
											type="submit"
											className="w-full mr-4"
											disabled={isLoadingPdfData}
											loading={isLoadingPdfData}
										/>
										<Button
											text="Download PDF"
											type="button"
											variant="light"
											className="w-full"
											onClick={pdfDownloadHandler}
											disabled={!pdf}
										/>
									</div>
								</div>
							</Form>
						);
					}}
				</Formik>
			</div>
		</>
	);
};
