import React from 'react';
import { ISubmission } from 'services/submissions/interfaces';

export const SubmissionInfo: React.FC<{ submission: ISubmission }> = ({
	submission
}) => {
	return (
		<div className="ml-20 xxl:ml-56 print:hidden">
			<div className="px-8 pt-4 text-gray-400 text-sm flex space-x-4  w-full mx-auto max-w-5xl ">
				<p>
					{submission.user && (
						<>
							Submitted by{' '}
							<strong className="capitalize">
								{typeof submission.user === 'string'
									? submission.user.split('_').join(' ')
									: submission.user.firstname + ' ' + submission.user.surname}
							</strong>
						</>
					)}{' '}
					{submission.end && (
						<>
							on <strong>{new Date(submission.end).toLocaleString()}</strong>
						</>
					)}
				</p>
			</div>
		</div>
	);
};
