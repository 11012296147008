import React from 'react';
import { Field } from 'formik';
import {
	CustomDatePicker,
	ICustomDatePickerProps
} from 'components/form-elements';

export const FieldDatePicker: React.FC<ICustomDatePickerProps> = ({
	className,
	name,
	ref,
	...rest
}) => {
	return (
		<Field name={name}>
			{(props: any) => {
				const {
					field: { name, value, onChange, onBlur, onFocus }
				} = props;
				return (
					<CustomDatePicker
						className={className}
						ref={ref as any}
						name={name}
						value={value}
						onChange={(e: any) => onChange(e)}
						onBlur={(e: any) => onBlur(e)}
						onFocus={(e: any) => onFocus(e)}
						{...rest}
					/>
				);
			}}
		</Field>
	);
};
