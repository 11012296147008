import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ITimeFilterTypes } from './interfaces';

export type IUseGetSubmissionsCountByIdOptions = UseQueryOptions<
	any,
	any,
	Record<string, number>
>;

export const getSubmissionsCountById = async (
	config?: IGetSubmissionsCountByIdRequestConfig
) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/submissions_count/`, {
			...config
		})
		.then(response => {
			return Promise.resolve(response.data[0]);
		})
		.catch(error => {
			console.error(`Error getting submissions count:`, error);
			return Promise.reject(error);
		});
};

export const useGetSubmissionsCountByIdQuery = (
	config?: IGetSubmissionsCountByIdRequestConfig,
	queryOptions?: IUseGetSubmissionsCountByIdOptions
) => {
	return useQuery(
		['submissions_count', config?.params] as QueryKey,
		() => getSubmissionsCountById(config),
		queryOptions
	);
};

interface IGetSubmissionsCountByIdRequestConfig extends AxiosRequestConfig {
	params?: IGetSubmissionsCountQueryParams;
}

type IGetSubmissionsCountQueryParams = {
	survey_id?: string;
	time_filter?: ITimeFilterTypes;
};
