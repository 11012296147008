import { Button } from 'components/common';
import { FieldInput, FieldSelect } from 'components/formik-elements';
import { Form, Formik } from 'formik';
import { useToast } from 'hooks';
import React from 'react';
import { editProperty } from 'services/properties';
import { IProperty, IPropertyType } from 'services/properties/interfaces';
import * as Yup from 'yup';
import { getPropertyTypeOptions } from '../helpers/propertyHelper';

export interface IEditPropertyFormProps {
	property: IProperty;
	propertyTypes?: IPropertyType[];
	className?: string;
	onSuccess?: () => void;
}

export const EditPropertyForm: React.FC<IEditPropertyFormProps> = ({
	property,
	propertyTypes,
	className,
	onSuccess
}) => {
	const show = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [backendError, setBackendError] = React.useState<Record<string, any>>(
		{}
	);

	const handleSubmit = (values: IProperty) => {
		setIsSubmitting(true);

		if (property.property_id) {
			editProperty(property.property_id, values)
				.then(() => {
					show({
						type: 'success',
						headerText: 'Edited!',
						message: 'Successfully edited property.'
					});
					setIsSubmitting(false);
					onSuccess && onSuccess();
				})
				.catch(e => {
                    if (e.response.data) {
                        setBackendError(e.response.data);
                    }
					console.info(e);
					setIsSubmitting(false);
				});
		}

		setIsSubmitting(false);
	};

	return (
		<Formik
			initialValues={{
				property_type_id: property.property_type_id,
                name: property.name,
                city: property.city,
                address: property.address,
                longitude: property.longitude,
                latitude: property.latitude,
                country: property.country,
				client_id: property.client_id,
				booking_id: property.booking_id
			}}
			validationSchema={Yup.object().shape({
				name: Yup.string().required('This field is required'),
                address: Yup.string().required('This field is required'),
                city: Yup.string().required('This field is required'),
                country: Yup.string().required('This field is required').matches(/^[A-Za-z]+$/, 'Country must contain only letters'),
                longitude: Yup.number().required('This field is required').min(-180, 'Longitude must be greater than or equal to -180')
                .max(180, 'Longitude must be less than or equal to 180'),
                latitude: Yup.number().required('This field is required').min(-90, 'Latitude must be greater than or equal to -90')
                .max(90, 'Latitude must be less than or equal to 90'),
			})}
			onSubmit={handleSubmit}
		>
			{props => {
				const { errors, submitCount } = props;

				return (
					<Form id="property-edit-form" className={className || ''}>
						<FieldSelect
                                name="property_type_id"
                                label="Select property type"
                                id="property_type_id"
                                className="mb-6"
                                options={getPropertyTypeOptions(propertyTypes)}
                            />
						<FieldInput
							name="name"
							id="name"
							label="Name"
							placeholder="Add name"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.name && errors.name) ||
									(backendError.name && backendError.name[0]) ||
									'')
							}
						/>
						<FieldInput
							name="address"
							id="address"
							label="Address"
							placeholder="Add address and house number"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.address && errors.address) ||
									(backendError.address && backendError.address[0]) ||
									'')
							}
						/>
                        <FieldInput
                                name="city"
                                id="city"
                                label="City"
                                placeholder="Add city"
                                className="mb-6"
                                error={
                                    submitCount > 0 &&
                                    ((errors.city && errors.city) ||
                                        (backendError.city && backendError.city[0]) ||
                                        '')
                                }
						/>
                        <FieldInput
                                name="country"
                                id="country"
                                label="Country"
                                placeholder="Add country"
                                className="mb-6"
                                error={
                                    submitCount > 0 &&
                                    ((errors.country && errors.country) ||
                                        (backendError.country && backendError.country[0]) ||
                                        '')
                                }
						/>
                        <FieldInput
                                name="longitude"
                                id="longitude"
                                label="GPS Longitude"
                                placeholder="Add longitude"
                                className="mb-6"
                                error={
                                    submitCount > 0 &&
                                    ((errors.longitude && errors.longitude) ||
                                        (backendError.longitude && backendError.longitude[0]) ||
                                        '')
                                }
						/>
                        <FieldInput
                                name="latitude"
                                id="latitude"
                                label="GPS Latitude"
                                placeholder="Add latitude"
                                className="mb-6"
                                error={
                                    submitCount > 0 &&
                                    ((errors.latitude && errors.latitude) ||
                                        (backendError.latitude && backendError.latitude[0]) ||
                                        '')
                                }
						/>

						<Button
							text="Update"
							type="submit"
							disabled={isSubmitting}
							loading={isSubmitting}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
