import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { useToast } from 'hooks';
import {
	INotificationRule,
	updateRule,
	useGetNotificationTypesQuery,
	useGetRuleByIdQuery
} from 'services/notifications';
import { useGetAvailableSurveysQuery } from 'services/surveys';

import { Loader } from 'components/common';
import {
	notificationRuleValidationSchema,
	Rule
} from 'components/notifications';
import { Layout } from 'components/shared';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { useGetProperties } from 'services/properties';

function Notification() {
	const show = useToast();
	const params = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [isUpdating, setIsUpdating] = React.useState(false);

	const { data: notificationTypes, isLoading: isLoadingNotificationTypes } =
		useGetNotificationTypesQuery();

	const { data: surveys, isLoading: isLoadingSurveys } =
		useGetAvailableSurveysQuery({
			refetchOnWindowFocus: false
		});

	const { data: notificationRule, isLoading: isLoadingNotificationRule } =
		useGetRuleByIdQuery(params.notificationId);

	const { data: properties } = useGetProperties();
	if (
		isLoadingSurveys ||
		isLoadingNotificationTypes ||
		isLoadingNotificationRule
	) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!surveys || !notificationTypes || !notificationRule) {
		return (
			<Layout pageTitle="Notification rule">
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">No notification rule</p>
				</div>
			</Layout>
		);
	}

	const initialValues = {
		rule_name: notificationRule.rule_name,
		notifications: notificationRule.notifications,
		question_rules: notificationRule.question_rules
	};

	const handleSubmit = (values: INotificationRule) => {
		setIsUpdating(true);
		if (params.notificationId) {
			updateRule(params.notificationId, values).then(() => {
				queryClient.invalidateQueries('notification_rule');
				show({
					type: 'success',
					headerText: 'Updated!',
					message: 'Successfully updated notification rule'
				});
				navigate(`/notifications`);
				setIsUpdating(false);
			});
		} else {
			show({
				type: 'error',
				headerText: 'Error!',
				message: 'Something went wrong'
			});
			setIsUpdating(false);
		}
	};

	return (
		<Layout pageTitle={notificationRule.rule_name}>
			<Formik
				validationSchema={notificationRuleValidationSchema}
				initialValues={initialValues}
				onSubmit={handleSubmit}
			>
				{props => {
					return (
						<Form className="space-y-6 pb-6" id="notification-rule-form">
							<Rule
								notificationTypes={notificationTypes}
								surveys={surveys}
								formProps={props}
								properties={properties}
							/>
						</Form>
					);
				}}
			</Formik>

			{isUpdating && (
				<div className="fixed z-50 top-0 left-0 flex items-center justify-center w-full h-screen bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
					<Loader />
				</div>
			)}
		</Layout>
	);
}

export default Notification;
