import React from 'react';
import ModalPlugin from 'react-modal';
import { AnimatePresence, motion } from 'framer-motion';

import { ModalHeader } from './ModalHeader';
import { ModalFooter } from './ModalFooter';
import { Button, IBtnVariants } from 'components/common';

export interface IModalProps extends ReactModal.Props {
	className?: string;
	headerText?: string;
	fullScreen?: boolean;
	noPadding?: boolean;
	noScroll?: boolean;
	maxWidth?: 'xl' | '2xl';
	maxHeight?: string;
	buttons?: IModalButton[];
	branded?: boolean;
	slotFixed?: React.ReactElement;
	disableDismiss?: boolean;
	hideClose?: boolean;
}

interface IModalComposition {
	Footer: React.FC;
}

export interface IModalButton {
	text: string;
	type?: IBtnVariants;
	handler?: () => void;
}

const sizes = {
	xl: 'max-w-xl w-full',
	'2xl': 'max-w-2xl w-full'
};

export const Modal: React.FC<IModalProps> & IModalComposition = ({
	isOpen,
	onRequestClose,
	children,
	headerText,
	fullScreen = false,
	maxWidth = 'xl',
	buttons,
	branded,
	disableDismiss,
	slotFixed,
	hideClose,
	className,
	...rest
}) => {
	// init and split sub components

	React.useEffect(() => {
		if (typeof window !== 'undefined') {
			ModalPlugin.setAppElement('#root');
		}
	}, []);

	const content = React.Children.map(children, (child: any) => {
		if (child?.key === null) {
			return React.cloneElement(child);
		}
	});

	const footer = React.Children.map(children, (child: any) => {
		if (child?.key && child?.key.includes('ModalFooter')) {
			return React.cloneElement(child);
		}
	});

	const hasFooter = footer && footer?.length > 0;

	const headerRef = React.useRef<HTMLDivElement>(null);
	const fixedRef = React.useRef<HTMLDivElement>(null);
	const footerRef = React.useRef<HTMLDivElement>(null);

	// handle events

	const onClose = (e: any) => {
		if (!disableDismiss) {
			onRequestClose && onRequestClose(e);
		}
	};

	// Alert modal specific

	const onButtonClick = async (button: IModalButton, i: number, e: any) => {
		const isAsync =
			button.handler && button.handler.constructor.name === 'AsyncFunction';
		button.handler &&
			(isAsync
				? await onAsyncButtonClick(button, i)
				: await onAsyncButtonClick(button, i));
		if (!disableDismiss) {
			onRequestClose && onRequestClose(e);
		}
	};

	const [isLoading, setIsLoading] = React.useState<{
		ind: number;
		loading: boolean;
	}>({ ind: -1, loading: false });

	const onAsyncButtonClick = async (button: IModalButton, i: number) => {
		try {
			setIsLoading({ ind: i, loading: true });
			if (button.handler) {
				await button.handler();
			}
			setIsLoading({ ind: i, loading: false });
		} catch (e) {
			setIsLoading({ ind: i, loading: false });
			return Promise.reject(e);
		}
	};

	return (
		<AnimatePresence>
			{isOpen && (
				<ModalPlugin
					overlayClassName={`bg-black bg-opacity-50 fixed inset-0 z-[70] outline-none`}
					className="inset-0 flex items-end justify-center w-full h-full bg-transparent pointer-events-none md:items-center"
					onRequestClose={onClose}
					isOpen={isOpen}
					{...rest}
				>
					<motion.div
						className={`block relative bg-white pointer-events-auto rounded-b-none outline-none overflow-y-auto overscroll-contain
							${className || ''}
							${
								fullScreen
									? 'w-[896px] px-16 h-full ml-auto'
									: `mx-auto px-6 max-h-[90vh] ${sizes[maxWidth]}`
							}
							${!fullScreen ? 'rounded-t-xl md:rounded-xl' : ''}
						`}
						key="modal"
						initial={{ opacity: 0, translateX: 200 }}
						animate={{ opacity: 1, translateX: 0 }}
						exit={{ opacity: 0, translateX: 200 }}
						transition={{ duration: 0.25 }}
					>
						<div ref={headerRef} className="pb-5 sticky top-0 bg-white z-[101]">
							<ModalHeader
								headerText={headerText}
								branded={branded}
								onRequestClose={onClose}
								hideClose={hideClose}
							/>
						</div>

						{slotFixed && (
							<div ref={fixedRef} className={`pt-7 md:pt-10`}>
								{slotFixed}
							</div>
						)}

						{content}

						{buttons && (
							<div className="flex flex-col mt-7 px-10 pb-8">
								{buttons.map((button, i) => (
									<Button
										className="mt-4 first:mt-0"
										data-testid="alert_btn"
										key={button.text}
										text={button.text}
										variant={button.type || 'default'}
										loading={isLoading.loading && isLoading.ind === i}
										onClick={async (e: any) => {
											await onButtonClick(button, i, e);
										}}
									/>
								))}
							</div>
						)}

						{hasFooter && (
							<div
								ref={footerRef}
								className="pt-5 pb-10 pr-8 pl-7 md:px-14 md:pb-12"
							>
								{footer}
							</div>
						)}
					</motion.div>
				</ModalPlugin>
			)}
		</AnimatePresence>
	);
};

Modal.Footer = ModalFooter;
export default Modal;
