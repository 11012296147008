import axios from 'axios';

export const resendRegistrationEmail = async (data: Record<string, number>) => {
	return await axios
		.post<any>(
			`${process.env.REACT_APP_API_URL}/resend_registration_email/`,
			data
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error resending email:`, error);
			return Promise.reject(error);
		});
};
