import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldArray, FieldHelperProps, FormikErrors } from 'formik';

import { Button } from 'components/common';
import { FieldInput, FieldTextArea } from 'components/formik-elements/';
import { LanguageBar } from 'components/shared';
import { SurveyQuestion } from 'components/survey';
import { addQuestion, getAllQuestions } from 'components/survey/helpers';

import { ShowJson } from 'utils';
import { alertShowAction, setCurrentLanguage } from 'reducers';
import {
	SurveyCollapseProvider,
	useAlertDispatchContext,
	useLanguageContext,
	useLanguageDispatchContext
} from 'context';
import { IQuestion, ISurveyUI } from 'services/surveys/interfaces';

import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { EditAllTranslationsModal } from 'components/survey/EditAllTranslationsModal';
import { SurveyContext } from 'context/SurveyContext';
import { getPropertiesOptions } from 'components/property/helpers/propertyHelper';


export interface ISurveyProps {
	survey: ISurveyUI;
	errors?: FormikErrors<ISurveyUI>;
	isSubmitting?: boolean;
	properties:any;
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>;
}

export const Template: React.FC<ISurveyProps> = ({
	survey,
	errors,
	isSubmitting,
	properties,
	getFieldHelpers
}) => {
	const [animationParent] = useAutoAnimate({
		duration: 500
	});
	const navigate = useNavigate();
	const alertDispatch = useAlertDispatchContext();
	const { currentLanguage, availableLanguages, defaultLanguage } =
		useLanguageContext();
	const languageDispatch = useLanguageDispatchContext();

	const handleCancel = () => {
		alertDispatch(
			alertShowAction({
				header: `Cancel`,
				message: `Are you sure you want to cancel?`,

				buttons: [
					{
						text: `Close`,
						handler: () => null,
						type: 'light'
					},
					{
						text: `Cancel`,
						handler: () => navigate('/templates')
					}
				]
			})
		);
	};

	const [editAllTranslationsModal, setEditAllTranslationsModal] = React.useState<
	Record<string, any>
	>({
		isOpen: false,
		item: '',
		itemType: ''
	});

	const actionButtons = (itemForTranslation?: Record<string,string>, itemForTranslationType?: string) => {
		return (
			<div className={`flex items-center justify-between ml-4 mt-9 h-min`}>

				{availableLanguages.length > 1 && (
					<button
						type="button"
						onClick={() =>
							setEditAllTranslationsModal({
								isOpen: true,
								item: itemForTranslation,
								itemType: itemForTranslationType
							})
						}
						className={`flex items-center justify-center mr-2 w-5 h-5 hover:text-blue-700`}
					>
						<SvgEdit />
					</button>
				)}

				
			</div>
		);
	};


	React.useEffect(() => {
		if (
			isSubmitting &&
			errors &&
			!JSON.stringify(errors).includes(currentLanguage && 'survey_name')
		) {
			languageDispatch(
				setCurrentLanguage(
					availableLanguages.filter(lang =>
						JSON.stringify(errors).includes(`"${lang}":`)
					)[0] || defaultLanguage
				)
			);
		}
	}, [isSubmitting, errors]);

	return (
		<SurveyCollapseProvider
			idsToCollapse={{
				sections: survey.sections.map(section => section.name),
				questions: getAllQuestions(survey).map(question => question.name)
			}}
		>
			<ShowJson data={survey} />
			<SurveyContext.Provider value={survey}>
					<LanguageBar/>
			</SurveyContext.Provider>
			<div ref={animationParent as any}>
				<FieldInput
					name="survey_name"
					id="survey_name"
					label="Library name"
					type="text"
					error={errors?.survey_name}
					className="mb-6"
				/>

				<FieldTextArea
					name={`survey_description.${currentLanguage}`}
					id="survey_description"
					label="Library description"
					className="mb-12"
				/>
				<FieldArray
					name="general_questions"
					render={arrayHelpers => {
						return (
							<>
								{survey.general_questions.map(
									(question: IQuestion, index: number) => (
										<SurveyQuestion
											groupName="general_questions"
											key={question.name}
											indexInGroup={index}
											questionIndex={index}
											formValues={survey}
											properties={getPropertiesOptions(null,properties?.results)}
											{...{
												arrayHelpers,
												getFieldHelpers,
												errors: errors?.general_questions?.[index],
												question
											}}
										/>
									)
								)}
								<Button
									className="mr-3 mt-6"
									type="button"
									text="New question"
									size="small"
									variant="dark"
									iconLeft={<SvgPlus />}
									onClick={() => addQuestion(arrayHelpers)}
								/>
							</>
						);
					}}
				/>
			</div>
			<EditAllTranslationsModal
				isOpen={editAllTranslationsModal.isOpen}
				onRequestClose={() =>
					setEditAllTranslationsModal({
						isOpen: false,
						item: '',
						itemType: '',

					})
				}
				item={editAllTranslationsModal.item}
				itemType={editAllTranslationsModal.itemType}
			/>
			{survey.general_questions.some(x => x.is_sensitive===true) && (
				<div className="mb-6 flex justify-between">
					<FieldTextArea
						name={`consent_text.${currentLanguage}`}
						id="consent_text"
						label="Consent text"
						className="mb-6 mt-6"
						error={errors?.consent_text?`Consent text should be filled for all languages or none`:undefined}
					/>
					{actionButtons(survey.consent_text,"consent_text")}
				</div>
				)}
			<hr className="my-6" />
			<div className="flex">
				<Button
					type="submit"
					text="Save library"
					size="small"
					className="mr-3"
				/>
				<Button
					type="button"
					text="Cancel"
					size="small"
					variant="light"
					onClick={handleCancel}
				/>
			</div>
		</SurveyCollapseProvider>
	);
};
