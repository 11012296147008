import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';

import { Layout } from 'components/shared';
import { Loader } from 'components/common';
import { Survey } from 'components/survey';

import { useToast } from 'hooks';
import {
	surveyValidationSchema,
	postProcessSurvey,
	fillPropertyTypeChoices
} from 'components/survey/helpers';
import { postSurvey } from 'services/surveys';
import { ISurveyUI } from 'services/surveys/interfaces';
import { useLanguageContext } from 'context';
import { useGetClientInfoQuery } from 'services/users';
import { useGetSurveyTypes } from 'services/surveys/getSurveyTypes';
import { useGetProperties } from 'services/properties';

const NewSurvey = () => {
	const navigate = useNavigate();
	const show = useToast();
	const { availableLanguages, defaultLanguage } = useLanguageContext();

	const { data: surveyTypes } = useGetSurveyTypes();

	const { data: properties } = useGetProperties();

	const { data: clientInfo, isLoading: isLoadingClientInfo } =
		useGetClientInfoQuery();

	const [isSending, setIsSending] = React.useState(false);

	const handleSubmit = (
		values: ISurveyUI,
		{ setSubmitting }: FormikHelpers<ISurveyUI>
	) => {
		setIsSending(true);
		fillPropertyTypeChoices(values.general_questions, availableLanguages);
		fillPropertyTypeChoices( values.sections?.flatMap(section => section.questions), availableLanguages);
		postSurvey(postProcessSurvey(values, availableLanguages, defaultLanguage))
			.then(res => {
				show({
					type: 'success',
					headerText: 'Created!',
					message: 'Successfully created survey'
				});
				navigate(`/surveys/${res.survey_id}`);
			})
			.catch(error => {
				console.info(error);

				show({
					type: 'error',
					headerText: 'Error!',
					message: `Your session may be expired, try saving the survey again.`
				});
				setIsSending(false);
			});
		setSubmitting(false);
	};

	if (isLoadingClientInfo) {
		return (
			<Layout pageTitle="New survey">
				<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
			</Layout>
		);
	}

	return (
		<Layout pageTitle="New survey">
			<Formik
				initialValues={{
					survey_name: '',
					survey_title : {[defaultLanguage]:''},
					survey_description : {[defaultLanguage]:''},
					general_questions: [],
					sections: [],
					survey_languages: availableLanguages,
					survey_default_lang: defaultLanguage,

					logo: '',
					return_url: clientInfo?.return_url,
					tags: [],
					multi_page: false
				}}
				validationSchema={surveyValidationSchema(availableLanguages)}
				onSubmit={handleSubmit}
			>
				{props => {
					const {
						values: survey,
						getFieldHelpers,
						submitCount,
						errors,
						isSubmitting
					} = props;

					const errorElement = Array.from(
						document.querySelectorAll('.errorElement')
					)?.[0];

					isSubmitting &&
						errors &&
						errorElement?.scrollIntoView({
							behavior: 'smooth',
							block: 'center'
						});

					return (
						<Form className={`pb-16 ${isSending ? 'pointer-events-none' : ''}`}>
							<Survey
								{...{
									getFieldHelpers,
									surveyTypes,
									survey,
									isSubmitting,
									properties,
									errors: submitCount > 0 && errors ? errors : undefined
								}}
							/>
						</Form>
					);
				}}
			</Formik>

			{isSending && (
				<div className="fixed z-50 top-0 left-0 flex items-center justify-center w-full h-screen bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
					<Loader />
				</div>
			)}
		</Layout>
	);
};

export default NewSurvey;
