// FileUploadComponent.tsx
import React, { ChangeEvent } from 'react';

interface IFileUploadProps {
    onFileUpload: (content: string) => void;
}

export const FileUploadComponent: React.FC<IFileUploadProps> = ({ onFileUpload }) => {
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result as string;
                onFileUpload(content);
            };
            reader.readAsText(file);
        }
    };

    return (
        <div className='border p-4 mb-2 border-blue-300 '>
            <p className='pb-2'>Import json</p>
            <input
                type="file"
                accept=".json"
                onChange={handleFileChange}
            />
        </div>
    );
}