/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';

const CustomCheckboxGroupContext = React.createContext([
	'' as any,
	(data: any): void => {
		return;
	}
] as const);

export const useCustomCheckboxGroupContext = () => {
	const context = React.useContext(CustomCheckboxGroupContext);
	if (context === undefined) {
		throw new Error(
			'useCustomCheckboxGroupContext must be used within a CustomCheckboxGroupContextProvider'
		);
	}
	return context;
};

export interface ICustomCheckboxGroupProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	value?: any;
	onChange?: (e: any) => void;
	initialState?: any;
}

export const CustomCheckboxGroup: React.FC<ICustomCheckboxGroupProps> = ({
	children,
	name,
	onChange,
	initialState,
	value,
	...rest
}) => {
	const [state, setState]: any = React.useState(() => initialState || []);

	React.useEffect(() => {
		setState(value);
	}, [value]);

	const handleOnChange = (name: string) => {
		const nextState = [...state];

		if (!state.includes(name)) {
			nextState.push(name);
		} else {
			nextState.splice(nextState.indexOf(name), 1);
		}
		setState(nextState);
		onChange && onChange(nextState);
	};

	return (
		<CustomCheckboxGroupContext.Provider value={[state, handleOnChange]}>
			<div id={name} {...rest}>
				{children}
			</div>
		</CustomCheckboxGroupContext.Provider>
	);
};
