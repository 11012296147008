import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from 'components/common';
import { CustomInput } from 'components/form-elements';

import { useToast } from 'hooks';
import { copySurvey } from 'services/surveys';
import { ISurvey } from 'services/surveys/interfaces';

export interface ISurveyCloneModalProps {
	modalTitle: string;
	survey: ISurvey;
	isOpen: boolean;
	onClose: () => void;
	isTemplate?: boolean;
}

export const SurveyCloneModal: React.FC<ISurveyCloneModalProps> = ({
	modalTitle,
	survey,
	isOpen,
	onClose,
	isTemplate
}) => {
	const navigate = useNavigate();
	const show = useToast();

	const [newCopyName, setNewCopyName] = React.useState(
		`${survey?.survey_name} copy`
	);
	const [isLoading, setIsLoading] = React.useState(false);

	React.useEffect(
		() => setNewCopyName(`${survey?.survey_name} copy`),
		[survey?.survey_name]
	);

	const copySubmit = (survey: ISurvey) => {
		setIsLoading(true);
		copySurvey({
			survey_id: survey.survey_id as string,
			survey_name: newCopyName,
			isTemplate: isTemplate
		})
			.then(res => {
				show({
					type: 'success',
					headerText: 'Copied!',
					message: isTemplate
						? 'Successfully copied library'
						: 'Successfully copied survey'
				});
				isTemplate
					? navigate(`/templates/${res.survey_id}`)
					: navigate(`/surveys/${res.survey_id}`);
			})
			.catch(() => {
				show({
					type: 'error',
					headerText: 'Error!',
					message: 'Something went wrong'
				});
				onClose;
			});
	};

	return (
		<Modal headerText={modalTitle} isOpen={isOpen} onRequestClose={onClose}>
			<div className="pt-2 pb-12 flex gap-4">
				<CustomInput
					name="new-copy-name"
					value={newCopyName}
					onChange={e => setNewCopyName(e.target.value)}
					className="flex-1"
				/>
				<Button
					text="Post"
					onClick={() => copySubmit(survey as ISurvey)}
					size="small"
					loading={isLoading}
				/>
			</div>
		</Modal>
	);
};
