import { useState, useEffect } from 'react';

export function useMediaQuery(query: any) {
	const [matches, setMatches] = useState<boolean | undefined>();

	useEffect(() => {
		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}
		const listener = () => {
			setMatches(media.matches);
		};
		media.addListener(listener);
		return () => media.removeListener(listener);
	}, [matches, query]);

	return matches;
}

// export const useMediaQuery = (mediaQuery: any) => {
// 	const isClient = typeof window === 'object';
// 	const isApiSupported = (api: any) => api in window;
// 	if (!isClient || !isApiSupported('matchMedia')) {
// 		// eslint-disable-next-line no-console
// 		// console.warn(errorMessage);
// 		return null;
// 	}

// 	const [isVerified, setIsVerified] = useState(
// 		!!window.matchMedia(mediaQuery).matches
// 	);

// 	useEffect(() => {
// 		const mediaQueryList = window.matchMedia(mediaQuery);
// 		const documentChangeHandler = () => {
// 			setIsVerified(!!mediaQueryList.matches);
// 		};
// 		mediaQueryList.addListener(documentChangeHandler);

// 		documentChangeHandler();
// 		return () => {
// 			mediaQueryList.removeListener(documentChangeHandler);
// 		};
// 	}, [mediaQuery]);

// 	return isVerified;
// };
