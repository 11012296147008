import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const getSubmissionBySurveyId = async (
	id: string,
	config?: IGetSubmissionsBySurveyIdRequestConfig
) => {
	return await axios
		.get<any>(
			`${process.env.REACT_APP_API_URL}/submissions_api/?survey_id=${id}`,
			{
				...config
			}
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting submission:`, error);
			return Promise.reject(error);
		});
};

export const useGetSubmissionBySurveyIdQuery = (
	id?: string,
	config?: IGetSubmissionsBySurveyIdRequestConfig
) => {
	return useQuery(
		['submission', id, config?.params] as QueryKey,
		() =>
			id
				? getSubmissionBySurveyId(id, config)
				: Promise.reject(
						'Error in useGetSubmissionBySurveyIdQuery: No id provided.'
				  ),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};

interface IGetSubmissionsBySurveyIdRequestConfig extends AxiosRequestConfig {
	params?: IGetSubmissionsBySurveyIdQueryParams;
}

type IGetSubmissionsBySurveyIdQueryParams = {
	page?: number;
	page_size?: number;
	ordering?: string;
};
