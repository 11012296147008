import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { Button, Loader } from 'components/common';
import { CustomFileUpload } from 'components/form-elements/CustomFileUpload';
import { InputFeedback } from 'components/form-elements';
import { Layout } from 'components/shared';
import { FieldInput, FieldTextArea } from 'components/formik-elements';

import { useToast } from 'hooks';
import {
	IEmailTemplateData,
	updateEmailTemplate,
	useGetEmailTemplateByIdQuery
} from 'services/email-templates';
import { postFile } from 'services/files';
import { useGetClientInfoQuery } from 'services/users';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { ReactComponent as SvgImage } from 'assets/icons/image.svg';

function EmailTemplate() {
	const params = useParams();
	const show = useToast();
	const navigate = useNavigate();
	const { data: clientInfo } = useGetClientInfoQuery();

	const { data: emailTemplate, isLoading } = useGetEmailTemplateByIdQuery(
		params.emailTemplateId
	);

	const [isUpdating, setIsUpdating] = React.useState(false);

	const handleSubmit = (values: IEmailTemplateData) => {
		setIsUpdating(true);
		if (params.emailTemplateId) {
			updateEmailTemplate(params.emailTemplateId, values).then(() => {
				show({
					type: 'success',
					headerText: 'Updated!',
					message: 'Successfully updated email template'
				});
				navigate(`/emails?tab=1`);
				setIsUpdating(false);
			});
		} else {
			show({
				type: 'error',
				headerText: 'Error!',
				message: 'Something went wrong'
			});
			setIsUpdating(false);
		}
	};

	if (isLoading) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!emailTemplate) {
		return (
			<Layout pageTitle="Email template">
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">No email template</p>
				</div>
			</Layout>
		);
	}

	return (
		<Layout pageTitle={emailTemplate.template_name}>
			{isUpdating ? (
				<Loader className="pt-20" />
			) : (
				<Formik
					initialValues={{
						template_name: emailTemplate.template_name,
						invitation_text: emailTemplate.invitation_text,
						image_source: emailTemplate.image_source,
						company_name: emailTemplate.company_name,
						address: emailTemplate.address,
						phone_number: emailTemplate.phone_number,
						email: emailTemplate.email,
						website_link: emailTemplate.website_link
					}}
					validationSchema={Yup.object().shape({
						template_name: Yup.string().required('This field is required'),
						invitation_text: Yup.string().required('This field is required'),
						image_source: Yup.string(),
						company_name: Yup.string(),
						address: Yup.string(),
						phone_number: Yup.string()
							.matches(/^\+?[0-9]+$/, 'Phone number must contain only digits')
							.min(9, 'Minimum 9 characters')
							.max(14, 'Maximum 14 characters'),
						email: Yup.string().email('Invalid email format'),
						website_link: Yup.string()
							.matches(
								/^(http(s)?:\/\/)/, // eslint-disable-line
								'URL should contain http:// or https://'
							)
							.matches(
								/([\w.-]{2,})(\.[\w\.-]+)+([\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+)$/, // eslint-disable-line
								'Enter a valid URL'
							)
					})}
					onSubmit={handleSubmit}
				>
					{props => {
						const {
							values,
							errors,
							isSubmitting,
							submitCount,
							getFieldHelpers
						} = props;

						const errorElement = Array.from(
							document.querySelectorAll('.errorElement')
						)?.[0];

						isSubmitting &&
							errors &&
							errorElement?.scrollIntoView({
								behavior: 'smooth',
								block: 'center'
							});

						return (
							<div className="flex">
								<Form className="pb-16 space-y-6 w-1/2 mr-16">
									<FieldInput
										maxLength={100}
										name="template_name"
										id="template_name"
										label="Template name*"
										error={
											(submitCount > 0 &&
												errors &&
												errors.template_name &&
												(errors.template_name as string)) ||
											''
										}
									/>

									<FieldTextArea
										maxLength={2000}
										name="invitation_text"
										id="invitation_text"
										label="Invitation text*"
										error={
											(submitCount > 0 &&
												errors &&
												errors.invitation_text &&
												(errors.invitation_text as string)) ||
											''
										}
									/>

									<div className="mb-6">
										<label className="block text-sm font-medium text-gray-700 truncate mb-1">
											Image source
										</label>

										<div
											className={`flex flex-wrap ${
												values.image_source === ''
													? 'items-center'
													: 'items-center'
											}`}
										>
											{values.image_source && (
												<div className="w-full flex mt-2 mb-4">
													<img
														src={values.image_source}
														alt="logo"
														className="max-w-xs object-contain border border-gray-300 rounded-xl p-2"
													/>
												</div>
											)}

											<CustomFileUpload
												name="image_source"
												id="image_source"
												onUpload={(e: any) => {
													postFile(e.target.files).then(res => {
														getFieldHelpers('image_source').setValue(
															res.file_url
														);
													});
												}}
												onClear={() => {
													getFieldHelpers('image_source').setValue('');
												}}
												hasValue={values.image_source !== ''}
											/>
											{values.image_source !== clientInfo?.logo && (
												<>
													<span className="ml-4 mr-4 h-10 flex items-center">
														or
													</span>
													<Button
														text="use default"
														type="button"
														iconRight={<SvgImage className="w-4 h-4 ml-1" />}
														onClick={() => {
															getFieldHelpers('image_source').setValue(
																clientInfo?.logo
															);
														}}
														size="small"
													/>
												</>
											)}
										</div>

										{submitCount > 0 && errors && errors.image_source && (
											<InputFeedback
												name={'image_source'}
												error={errors.image_source as string}
											/>
										)}
									</div>

									<div className="flex flex-col space-y-6 pt-8">
										<FieldInput
											maxLength={100}
											name="company_name"
											id="company_name"
											label="Company name"
											error={
												(submitCount > 0 &&
													errors &&
													errors.company_name &&
													(errors.company_name as string)) ||
												''
											}
										/>
										<FieldInput
											maxLength={100}
											name="address"
											id="address"
											label="Address"
											error={
												(submitCount > 0 &&
													errors &&
													errors.address &&
													(errors.address as string)) ||
												''
											}
										/>
										<FieldInput
											name="phone_number"
											id="phone_number"
											label="Phone number"
											error={
												(submitCount > 0 &&
													errors &&
													errors.phone_number &&
													(errors.phone_number as string)) ||
												''
											}
										/>
										<FieldInput
											name="email"
											id="email"
											label="Email"
											error={
												(submitCount > 0 &&
													errors &&
													errors.email &&
													(errors.email as string)) ||
												''
											}
										/>
										<FieldInput
											name="website_link"
											id="website_link"
											label="Website link"
											error={
												(submitCount > 0 &&
													errors &&
													errors.website_link &&
													(errors.website_link as string)) ||
												''
											}
										/>
									</div>
									<Button text="Submit" type="submit" />
								</Form>

								<div
									className="w-1/2"
									style={{ fontFamily: 'arial, helvetica, sans-serif' }}
								>
									<span className="block text-sm font-medium text-gray-700 truncate mb-1">
										Preview
									</span>

									<div className="border border-gray-300 rounded-lg p-10">
										<div className="flex flex-col items-center">
											{values.image_source && (
												<img
													src={values.image_source}
													alt="logo"
													className="w-full mb-6"
												/>
											)}
											<span className="mb-8 text-xs">{`Dear {{name}} {{lastname}}, `}</span>
											<p className="text-sm text-center">
												{values.invitation_text}
											</p>
											<span className="py-3 px-4 text-white bg-[#4973b5] rounded-md border border-black mt-6">
												{`
											{{survey_name}}
											`}
											</span>
										</div>

										<hr className="mt-2.5 mb-6 border-t-2 border-[#c2bcbc]" />

										<div className="flex flex-col text-2xs">
											<span className="mb-2">{values.company_name}</span>
											<span className="mb-2">{values.address}</span>
											<span className="mb-2">Tel: {values.phone_number}</span>
											<span className="mb-6">E-mail: {values.email}</span>

											<span className="mb-5 text-blue-200">
												{values.website_link}
											</span>

											<p className="text-[8px]">
												If you want to unsubscribe from emails like this,{' '}
												<span className="text-blue-200">click here</span>
											</p>
										</div>
									</div>
								</div>
							</div>
						);
					}}
				</Formik>
			)}
		</Layout>
	);
}

export default EmailTemplate;
