import axios from 'axios';
import { INotificationRule } from '.';

export const postRule = async (ruleData: INotificationRule) => {
	return await axios
		.post<INotificationRule>(`${process.env.REACT_APP_API_URL}/rule/`, ruleData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error posting rule:`, error);
			return Promise.reject(error);
		});
};
