import axios from 'axios';
import { IEmail } from './interfaces';

export const postEmail = async (emailData: IEmail) => {
	return await axios
		.post<any>(`${process.env.REACT_APP_API_URL}/email_api/`, emailData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error posting email:`, error);
			return Promise.reject(error);
		});
};
