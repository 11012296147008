import axios from 'axios';
import { IEmailTemplateData } from '.';

export const updateEmailTemplate = async (
	id: string,
	data: IEmailTemplateData
) => {
	return await axios
		.put<IEmailTemplateData>(
			`${process.env.REACT_APP_API_URL}/email_templates/${id}/`,
			data
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating email template:`, error);
			return Promise.reject(error);
		});
};
