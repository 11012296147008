import {Buffer} from 'buffer';

export const savePng = async (base64Image: any, name: string) => {
    if (base64Image) {
      const byteArray= new Uint8Array(Buffer.from(base64Image, 'base64'));
      const blob = new Blob([byteArray], { type: 'image/png' });

      // Create a temporary anchor element to trigger the download
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = name + ".png"; // You can specify the filename here

      // Simulate a click event to trigger the download
      downloadLink.click();

      // Clean up by revoking the Object URL
      URL.revokeObjectURL(downloadLink.href);
      }
}