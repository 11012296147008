import React from 'react';
import { IModalButton } from 'components/common';
import { ModalAlert } from 'components/shared';
import { useReducerWithLogger } from 'hooks';
import { alertHideAction, alertReducer } from 'reducers';

export const initialAlertState: IAlertState = {
	isOpen: false,
	backdropDismiss: true,
	header: '',
	message: '',
	buttons: []
};

export const AlertStateContext =
	React.createContext<IAlertState>(initialAlertState);
export const AlertDispatchContext = React.createContext<any>({});

export const AlertProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducerWithLogger(
		alertReducer,
		initialAlertState,
		false && process.env.NODE_ENV === 'development'
	);
	const { isOpen, header, message, buttons, onClose } = state;

	const closeAlert = () => {
		dispatch(alertHideAction());
		onClose && onClose();
	};

	return (
		<AlertStateContext.Provider value={{ ...state }}>
			<AlertDispatchContext.Provider value={dispatch}>
				{children}
				<ModalAlert
					isOpen={isOpen}
					onRequestClose={closeAlert}
					title={header}
					message={message}
					buttons={buttons}
				/>
			</AlertDispatchContext.Provider>
		</AlertStateContext.Provider>
	);
};

export const useAlertStateContext = () => {
	const context = React.useContext(AlertStateContext);
	if (context === undefined) {
		throw new Error(
			'useAlertStateContext must be used within a AlertStateProvider'
		);
	}
	return context;
};

export const useAlertDispatchContext = () => {
	const context = React.useContext(AlertDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useAlertDispatchContext must be used within a AlertDispatchContext'
		);
	}
	return context;
};

export interface IAlertState {
	isOpen?: boolean;
	header: string;
	message?: string;
	backdropDismiss?: boolean;
	buttons?: IModalButton[];
	onClose?: () => void;
	// color: string;
}
