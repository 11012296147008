import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { IEmailTemplateData } from '.';

export const getEmailTemplateById = async (id?: string) => {
	return await axios
		.get<IEmailTemplateData>(
			`${process.env.REACT_APP_API_URL}/email_templates/${id}/`
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting email template:`, error);
			return Promise.reject(error);
		});
};

export const useGetEmailTemplateByIdQuery = (id?: string) => {
	return useQuery(
		['email_template', id] as QueryKey,
		() =>
			id
				? getEmailTemplateById(id)
				: Promise.reject(
						'Error in useGetEmailTemplateByIdQuery: No id provided.'
				  ),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};
