import { Button } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { Form, Formik } from 'formik';
import { useToast } from 'hooks';
import React from 'react';
import { editPassword } from 'services/users/editPassword';
import * as Yup from 'yup';

export interface IEditPasswordFormProps {
	className?: string;
}

export const EditPasswordForm: React.FC<IEditPasswordFormProps> = ({
	className
}) => {
	const show = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [backendError, setBackendError] = React.useState<Record<string, any>>(
		{}
	);

	return (
		<Formik
			initialValues={{
				old_password: '',
				new_password: '',
				confirm_password: ''
			}}
			validationSchema={Yup.object().shape({
				old_password: Yup.string()
					.min(8, 'Minimum 8 characters')
					.required('This field is required'),
				new_password: Yup.string()
					.min(8, 'Minimum 8 characters')
					.required('This field is required'),
				confirm_password: Yup.string()
					.oneOf([Yup.ref('new_password'), null], 'Passwords do not match')
					.required('This field is required')
			})}
			onSubmit={(values, { resetForm }) => {
				setIsSubmitting(true);

				editPassword(values)
					.then(() => {
						show({
							type: 'success',
							headerText: 'Updated!',
							message: 'Successfully edited password'
						});
						resetForm();
						setIsSubmitting(false);
					})
					.catch(error => {
						if (error.response.data) {
							setBackendError(error.response.data);
						}
						setIsSubmitting(false);
					});
			}}
		>
			{props => {
				const { errors, submitCount } = props;

				return (
					<Form id="user-password-edit-form" className={className || ''}>
						<FieldInput
							name="old_password"
							id="old_password"
							label="Old password"
							type="password"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.old_password && errors.old_password) ||
									(backendError.old_password && backendError.old_password) ||
									'')
							}
						/>
						<FieldInput
							name="new_password"
							id="new_password"
							label="New password"
							type="password"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.new_password && errors.new_password) ||
									(backendError.new_password && backendError.new_password) ||
									'')
							}
						/>
						<FieldInput
							name="confirm_password"
							id="confirm_password"
							label="Confirm password"
							type="password"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.confirm_password && errors.confirm_password) ||
									(backendError.confirm_password &&
										backendError.confirm_password) ||
									'')
							}
						/>

						<Button
							text="Update"
							size="small"
							type="submit"
							disabled={isSubmitting}
							loading={isSubmitting}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
