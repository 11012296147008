export const ShowJson = (data: any) => {
	const isLocal = window.location.origin.includes('localhost');

	return isLocal ? (
		<details
			id="jsonPreview"
			className="max-h-96 fixed left-0 right-0 bottom-0 z-[101] overflow-auto overscroll-contain bg-gray-100 open:border-t-2 border-gray-300 "
		>
			<summary className="max-w-max fixed bottom-4 right-4 px-4 py-2 border-2 border-black bg-white rounded-xl cursor-pointer">
				Preview json
			</summary>
			<pre className="p-4 pl-8">{JSON.stringify(data, null, 2)}</pre>
		</details>
	) : (
		<></>
	);
};
