import React from 'react';

import { Layout } from 'components/shared';
import { Loader } from 'components/common';
import { Form, Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'hooks';
import { useLanguageContext } from 'context';
import { ISurveyUI } from 'services/surveys/interfaces';
import {
	fillPropertyTypeChoices,
	postProcessSurvey,
	templateValidationSchema
} from 'components/survey/helpers';
import { postSurvey } from 'services/surveys';
import { Template } from 'components/template/Template';
import { useGetProperties } from 'services/properties';

const NewTemplate = () => {
	const navigate = useNavigate();
	const show = useToast();
	const { availableLanguages, defaultLanguage } = useLanguageContext();
	const { data: properties } = useGetProperties();
	const [isSending, setIsSending] = React.useState(false);

	const initialFormState: ISurveyUI = {
		survey_name: '',
		survey_title : {[defaultLanguage]:''},
		survey_description : {[defaultLanguage]:''},
		general_questions: [],
		sections: [],
		survey_languages: availableLanguages,
		survey_default_lang: defaultLanguage
	};

	const handleSubmit = (
		values: ISurveyUI,
		{ setSubmitting }: FormikHelpers<ISurveyUI>
	) => {
		setIsSending(true);
		fillPropertyTypeChoices(values.general_questions, availableLanguages);
		postSurvey(
			postProcessSurvey(values, availableLanguages, defaultLanguage, true)
		)
			.then(res => {
				show({
					type: 'success',
					headerText: 'Created!',
					message: 'Successfully created library'
				});
				navigate(`/templates/${res.survey_id}`);
			})
			.catch(() => {
				show({
					type: 'error',
					headerText: 'Error!',
					message: `Your session may be expired, try saving the survey again.`
				});
				navigate(`/templates`);
				setIsSending(false);
			});
		setSubmitting(false);
	};

	return (
		<Layout pageTitle="New library">
			{isSending ? (
				<Loader className="pt-20" />
			) : (
				<Formik
					initialValues={initialFormState}
					validationSchema={templateValidationSchema(availableLanguages)}
					onSubmit={handleSubmit}
				>
					{props => {
						const {
							values: survey,
							getFieldHelpers,
							submitCount,
							errors,
							isSubmitting
						} = props;

						const errorElement = Array.from(
							document.querySelectorAll('.errorElement')
						)?.[0];

						isSubmitting &&
							errors &&
							errorElement?.scrollIntoView({
								behavior: 'smooth',
								block: 'center'
							});

						return (
							<Form className="pb-16">
								<Template
									{...{
										getFieldHelpers,
										survey,
										properties,
										isSubmitting,
										errors: submitCount > 0 && errors ? errors : undefined
									}}
								/>
							</Form>
						);
					}}
				</Formik>
			)}
		</Layout>
	);
};

export default NewTemplate;
