import React from 'react';

type ICheckboxGroupContextType = [string[], (name: string) => void];

const LocasticCheckboxGroupContext =
	React.createContext<ICheckboxGroupContextType>([[], () => {}]);

export const useLocasticCheckboxGroupContext = () => {
	const context = React.useContext(LocasticCheckboxGroupContext);
	if (context === undefined) {
		throw new Error(
			'useLocasticCheckboxGroupContext must be used within a LocasticCheckboxGroupContextProvider'
		);
	}
	return context;
};

export interface ILocasticCheckboxGroupProps
	extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	value?: any;
	onChange?: (e: any) => void;
	initialState?: string[];
}

export const LocasticCheckboxGroup: React.FC<ILocasticCheckboxGroupProps> = ({
	name,
	value,
	onChange,
	initialState,
	children,
	...rest
}) => {
	const [state, setState] = React.useState(initialState || []);

	React.useEffect(() => {
		setState(value);
	}, [value]);

	const handleOnChange = (name: string) => {
		const nextState = state && [...state];

		if (!state?.includes(name)) {
			nextState?.push(name);
		} else {
			nextState?.splice(nextState?.indexOf(name), 1);
		}

		setState(nextState);
		onChange && onChange(nextState);
	};

	return (
		<LocasticCheckboxGroupContext.Provider value={[state, handleOnChange]}>
			<div id={name} {...rest}>
				{children}
			</div>
		</LocasticCheckboxGroupContext.Provider>
	);
};
