import classNames from 'classnames';
import React from 'react';
import Select from 'react-select';
import { InputFeedback } from '../InputFeedback';
export interface ICustomMultiSelectOption {
	label?: string;
	value?: any;
	disabled?: boolean;
}
export interface ICustomMultiSelectProps {
	className?: string;
	id?: any;
	name: string;
	options: ICustomMultiSelectOption[];
	error?: string;
	label?: string;
	initialValue?: ICustomMultiSelectOption;
	value?: ICustomMultiSelectOption;
	onChange?: (value: ICustomMultiSelectOption[]) => void;
	disabled?: boolean;
	isMulti?: boolean;
}

export const getOptionValue = (option: ICustomMultiSelectOption) => {
	return option.value;
};

export const CustomMultiSelect: React.FC<ICustomMultiSelectProps> = ({
	className,
	id,
	name,
	options,
	error,
	label,
	initialValue,
	value,
	onChange,
	disabled = false,
	isMulti,
	...rest
}) => {
	const customStyles = {
		container: (provided: any) => ({
			...provided,
			borderRadius: '6px',
			boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)'
		}),
		valueContainer: (provided: any) => ({
			...provided,
			flexWrap: 'nowrap'
		}),
		control: (provided: any, state: any) => ({
			...provided,
			borderRadius: '6px',
			height: '2.5rem',
			borderColor: classNames({
				'#00396D': state.isFocused && !error,
				'#EF4444': (state.isFocused && error) || (!state.isFocused && error),
				'#D1D5DB': !state.isFocused && !error
			}),
			boxShadow: classNames({
				'0 0 0 1px #00396D': state.isFocused && !error,
				'#EF4444': (state.isFocused && error) || (!state.isFocused && error),
				'#D1D5DB': !state.isFocused && !error
			}),
			'&:hover': {
				borderColor: ''
			}
		}),
		option: (provided: any) => ({
			...provided,
			fontSize: '14px',
			backgroundColor: 'transparent',
			'&:hover': {
				backgroundColor: '#77BEFF'
			}
		}),
		indicatorSeparator: (provided: any) => ({
			...provided,
			display: 'none'
		}),
		multiValue: (provided: any) => ({
			...provided,
			borderRadius: '6px',
			padding: '0 10px',
			backgroundColor: '#F5F5F4'
		}),
		multiValueRemove: (provided: any) => ({
			...provided,
			'&:hover': {
				backgroundColor: 'transparent',
				color: '#0079E7'
			}
		})
	};

	const [innerValue, setInnerValue] =
		React.useState<ICustomMultiSelectOption>();

	React.useEffect(() => {
		setInnerValue(value || initialValue);
	}, [value]);

	const handleOnChange = (value: any) => {
		setInnerValue(value);
		onChange && onChange(value);
	};

	return (
		<div className={className || ''}>
			{label && (
				<label
					className={`block text-sm font-medium mb-1 text-gray-700 ${
						disabled ? 'pointer-events-none' : ''
					}`}
					htmlFor={name}
				>
					{label}
				</label>
			)}
			<Select
				className={`
					${disabled ? 'bg-gray-100 pointer-events-none opacity-50' : 'bg-white'}
				`}
				tabIndex={disabled ? -1 : 0}
				id={id}
				instanceId={id}
				name={name}
				aria-label={name}
				defaultValue={initialValue}
				value={innerValue}
				options={options}
				styles={customStyles}
				onChange={handleOnChange}
				isMulti={isMulti}
				isSearchable={false}
				isDisabled={disabled}
				isClearable={false}
				placeholder={false}
				{...rest}
			/>
			{error && <InputFeedback name={name} error={error} />}
		</div>
	);
};
