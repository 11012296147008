import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FieldArray, FieldHelperProps, FormikErrors } from 'formik';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import { Button, FormikCheckbox, Modal } from 'components/common';
import {
	FieldInput,
	FieldMultiSelect,
	FieldSelect,
	FieldTextArea
} from 'components/formik-elements/';
import { SurveyQuestion, SurveySection } from 'components/survey';

import { ShowJson } from 'utils';
import { alertShowAction, setCurrentLanguage } from 'reducers';
import {
	SurveyCollapseProvider,
	useAlertDispatchContext,
//	useSurveyCollapseDispatchContext,
	useLanguageContext,
	useLanguageDispatchContext
} from 'context';
import { IQuestion, ISurveyType, ISurveyUI } from 'services/surveys/interfaces';
import {
	addQuestion,
	addQuestionToLastSection,
	addQuestionToSection,
	addSection,
	getAllQuestions,
	getSurveyTypeOptions,
	prepareQuestions
} from 'components/survey/helpers';

import { LanguageBar, ManageTagsForm } from 'components/shared';
import { AddFromTemplateModal } from 'components/template/AddFromTemplateModal';
import { postFile } from 'services/files';
import { CustomFileUpload } from 'components/form-elements/CustomFileUpload';
import { useGetClientInfoQuery } from 'services/users';
import { useGetTagsQuery } from 'services/tags';

import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgImage } from 'assets/icons/image.svg';
import { SurveyContext } from 'context/SurveyContext';

import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { EditAllTranslationsModal } from '../EditAllTranslationsModal';
import { getPropertiesOptions } from 'components/property/helpers/propertyHelper';

export interface ISurveyProps {
	survey: ISurveyUI;
	surveyTypes: ISurveyType[];
	errors?: FormikErrors<ISurveyUI>;
	isSubmitting?: boolean;
	properties: any;
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>;
}



export const Survey: React.FC<ISurveyProps> = ({
	survey,
	surveyTypes,
	errors,
	isSubmitting,
	properties,
	getFieldHelpers
}) => {
	const [animationParent] = useAutoAnimate({
		duration: 500
	});
	const navigate = useNavigate();
//	const surveyCollapseDispatch = useSurveyCollapseDispatchContext();
	const alertDispatch = useAlertDispatchContext();
	const { currentLanguage, availableLanguages, defaultLanguage } =
		useLanguageContext();
	const languageDispatch = useLanguageDispatchContext();
	const { data: clientInfo } = useGetClientInfoQuery();
	const { data: tags } = useGetTagsQuery();

	const [isManageTagsModalOpen, setIsManageTagsModalOpen] =
		React.useState(false);

	//handle addFromTemplate
	const [addFromTemplateModal, setAddFromTemplateModal] = React.useState<
		Record<string, any>
	>({
		isOpen: false,
		section: null
	});

	const handleCancel = () => {
		alertDispatch(
			alertShowAction({
				header: `Cancel`,
				message: `Are you sure you want to cancel?`,

				buttons: [
					{
						text: `Close`,
						handler: () => null,
						type: 'light'
					},
					{
						text: `Cancel`,
						handler: () => navigate('/surveys')
					}
				]
			})
		);
	};

	const [editAllTranslationsModal, setEditAllTranslationsModal] = React.useState<
	Record<string, any>
	>({
		isOpen: false,
		item: '',
		itemType: ''
	});


	const actionButtons = (itemForTranslation?: Record<string,string>, itemForTranslationType?: string) => {

		return (
			<div className={`flex items-center justify-between ml-4 mt-9 h-min`}>

				{availableLanguages.length > 1 && (
					<button
						type="button"
						onClick={() =>
							setEditAllTranslationsModal({
								isOpen: true,
								item: itemForTranslation,
								itemType: itemForTranslationType
							})
						}
						className={`flex items-center justify-center mr-2 w-5 h-5 hover:text-blue-700`}
					>
						<SvgEdit />
					</button>
				)}

				
			</div>
		);
	};

	React.useEffect(() => {
		if (
			isSubmitting &&
			errors &&
			!JSON.stringify(errors).includes(
				currentLanguage && 'survey_name'
			)
		) {
			languageDispatch(
				setCurrentLanguage(
					availableLanguages.filter(lang =>
						JSON.stringify(errors).includes(`"${lang}":`)
					)[0] || defaultLanguage
				)
			);
		}
	}, [isSubmitting, errors]);
	return (
		<>
			<SurveyCollapseProvider
				idsToCollapse={{
					questions: getAllQuestions(survey).map(question => question.name)
				}}
			>
				
				<SurveyContext.Provider value={survey}>
					<LanguageBar/>
				</SurveyContext.Provider>
				 
					
				
				<FieldInput
					name="survey_name"
					id="survey_name"
					label="Survey name"
					placeholder="Name displayed to admins"
					type="text"
					error={errors?.survey_name}
					className="mb-6"
				/>
				<div className="flex justify-between">
					<FieldInput
						name={`survey_title.${currentLanguage}`}
						id="survey_title"
						label="Survey title"
						placeholder="Name displayed to users"
						type="text"
						error={errors?.survey_title?.[currentLanguage]}
						className="mb-6 w-full"
					/>
					<div>
					{actionButtons(survey.survey_title, "survey_title")}
					</div>
				</div>

				<div className="mb-6">
					<label className="block text-sm font-medium text-gray-700 truncate mb-1">
						Logo
					</label>
					<div
						className={`flex ${
							survey.logo === '' ? 'items-center' : 'items-start'
						}`}
					>
						{survey.logo !== '' && survey.logo !== null && (
							<img
								src={survey.logo}
								alt="logo"
								className="max-w-xs object-contain mr-4"
							/>
						)}

						<CustomFileUpload
							name="logo"
							id="logo"
							onUpload={(e: any) => {
								postFile(e.target.files).then(res => {
									getFieldHelpers('logo').setValue(res.file_url);
								});
							}}
							onClear={() => {
								getFieldHelpers('logo').setValue('');
							}}
							hasValue={survey.logo}
						/>
						{survey.logo !== clientInfo?.logo && (
							<>
								<span className="ml-4 mr-4 h-10 flex items-center">or</span>
								<Button
									text="use default"
									type="button"
									iconRight={<SvgImage className="w-4 h-4 ml-1" />}
									onClick={() => {
										getFieldHelpers('logo').setValue(clientInfo?.logo);
									}}
									size="small"
								/>
							</>
						)}
					</div>
				</div>
				<div className="flex justify-between">
					<FieldTextArea
						name={`survey_description.${currentLanguage}`}
						id="survey_description"
						label="Survey description"
						className="mb-6"
					/>
					{actionButtons(survey.survey_description,"survey_description")}
				</div>
				<EditAllTranslationsModal
				isOpen={editAllTranslationsModal.isOpen}
				onRequestClose={() =>
					setEditAllTranslationsModal({
						isOpen: false,
						item: '',
						itemType: '',

					})
				}
				item={editAllTranslationsModal.item}
				itemType={editAllTranslationsModal.itemType}
			/>
				
				{surveyTypes && (
					<FieldSelect
						name="survey_type"
						id="survey_type"
						label="Survey analytics type"
						placeholder="Survey analytics type"
						options={getSurveyTypeOptions(surveyTypes)}
						error={errors?.survey_type}
						className="mb-6 max-w-md"
					/>
				)}
			
				{properties && (
					<FieldSelect
						name="property_id"
						id="property_id"
						label="Survey property"
						placeholder="All properties"
						options={getPropertiesOptions('None',properties.results)}
						error={errors?.property_id}
						className="mb-6 max-w-md"
					/>
				)}

				{tags && (
					<div className="flex items-center w-full">
						<FieldMultiSelect
							name="tags"
							id="tags"
							label="Survey tags"
							options={tags.map(tag => {
								return {
									value: tag.tag_id,
									label: tag.name
								};
							})}
							error={errors?.tags}
							className="mb-6 w-full max-w-md mr-2"
							isMulti
						/>
						<Button
							aria-label="tags settings"
							text="Add new"
							title="Manage tags"
							type="button"
							onClick={() => setIsManageTagsModalOpen(true)}
							size="small"
						/>
					</div>
				)}

				<FieldInput
					name="return_url"
					id="return_url"
					label="Return url"
					type="text"
					error={errors?.return_url}
					className="max-w-md mb-6"
				/>

				<FormikCheckbox
					name="multi_page"
					id="multi_page"
					label="Make this a multi page survey"
					type="checkbox"
					variant="toggler"
					tabIndex={0}
					className="mb-12"
				/>

				<div className="w-full" ref={animationParent as any}>
					<FieldArray
						name="general_questions"
						render={arrayHelpers => {
							return (
								<>
									{survey.general_questions.map(
										(question: IQuestion, index: number) => (
											<SurveyQuestion
												groupName="general_questions"
												key={question.name}
												indexInGroup={index}
												questionIndex={index}
												formValues={survey}
												properties={getPropertiesOptions(null,properties?.results)}
												{...{
													arrayHelpers,
													getFieldHelpers,
													errors: errors?.general_questions?.[index],
													question
												}}
												
											/>
										)
									)}
									<Button
										className="mr-3 mt-6"
										type="button"
										text="New question"
										size="small"
										variant="dark"
										iconLeft={<SvgPlus />}
										onClick={() => addQuestion(arrayHelpers)}
										/* disabled={
											survey.submissions_count &&
											survey?.submissions_count !== 0
												? true
												: false
										} */
									/>
									<Button
										className="mr-2"
										type="button"
										text="Add from library"
										size="small"
										iconLeft={<SvgPlus />}
										variant="dark"
										onClick={() =>
											setAddFromTemplateModal({
												isOpen: true,
												section: 'general_questions'
											})
										}
										/* disabled={
											survey.submissions_count &&
											survey?.submissions_count !== 0
												? true
												: false
										} */
									/>
								</>
							);
						}}
					/>

					<FieldArray
						name="sections"
						render={arrayHelpers => {
							return (
								<>
									{survey.sections.map((section, index) => (
										<SurveySection
											key={section.name}
											{...{
												index,
												arrayHelpers,
												errors,
												section,
												survey,
												properties,
												getFieldHelpers
											}}
										/>
									))}
								</>
							);
						}}
					/>

				

					{survey.sections.length > 0 && (
						<Button
							className="mr-3 mt-6"
							type="button"
							text="New question"
							size="small"
							variant="dark"
							iconLeft={<SvgPlus />}
							onClick={() => {
								addQuestionToLastSection(getFieldHelpers, survey);
								// surveyCollapseDispatch(
								// 	expandSection(survey.sections.at(-1)?.name)
								// );
							}}
							/* disabled={
								survey.submissions_count && survey?.submissions_count !== 0
									? true
									: false
							} */
						/>
					)}

					{survey.sections.length > 0 && (
						<Button
							className="mr-2"
							type="button"
							text="Add from library"
							size="small"
							iconLeft={<SvgPlus />}
							variant="dark"
							onClick={() =>
								setAddFromTemplateModal({
									isOpen: true,
									section: 'last_section'
								})
							}
							/* disabled={
								survey.submissions_count && survey?.submissions_count !== 0
									? true
									: false
							} */
						/>
					)}

					<Button
						className="mr-2"
						type="button"
						text="New section"
						size="small"
						iconLeft={<SvgPlus />}
						variant="dark"
						onClick={() => addSection(getFieldHelpers, survey)}
						/* disabled={
							survey.submissions_count && survey?.submissions_count !== 0
								? true
								: false
						} */
					/>
				{(survey.general_questions.some(x => x.is_sensitive===true) ||survey.sections.some(s => s.questions.some(x => x.is_sensitive===true) )) && (
				<div className="mb-6 flex justify-between">
					<FieldTextArea
						name={`consent_text.${currentLanguage}`}
						id="consent_text"
						label="Consent text"
						className="mb-6 mt-6"
						error={errors?.consent_text?`Consent text should be filled for all languages or none`:undefined}
					/>
					{actionButtons(survey.consent_text,"consent_text")}
				</div>
				)
				}
					<hr className="my-6" />
					<div className="flex">
						<Button
							type="submit"
							text="Save survey"
							size="small"
							className="mr-3"
						/>
						<Button
							type="button"
							text="Cancel"
							size="small"
							variant="light"
							onClick={handleCancel}
						/>
					</div>
				</div>
			</SurveyCollapseProvider>

			<AddFromTemplateModal
				isOpen={addFromTemplateModal.isOpen}
				onRequestClose={() =>
					setAddFromTemplateModal({
						isOpen: false,
						section: null
					})
				}
				surveyLanguages={availableLanguages}
				
				onSubmit={(values, consenttext) => {
					prepareQuestions(values, survey).map(question => {
						if (addFromTemplateModal.section === 'general_questions') {
							return survey.general_questions.push(question);
						} else
							return addQuestionToSection(
								getFieldHelpers,
								survey,
								survey.sections.length - 1,
								question
							);
					});
					if((survey.general_questions.some(x => x.is_sensitive===true) ||survey.sections.some(s => s.questions.some(x => x.is_sensitive===true) ))){	
						
						if(consenttext!==null ) {
							survey.consent_text=consenttext;
						}else{
							survey.consent_text = {[currentLanguage]:''};
							availableLanguages.map(lang =>{
								if (lang!==currentLanguage && survey && survey.consent_text){
									survey.consent_text[lang] = ''
								}
							})
						}
					}
				}}
			/>

		<ShowJson data={survey} />

			<Modal
				headerText={`Manage tags`}
				isOpen={isManageTagsModalOpen}
				onRequestClose={() => setIsManageTagsModalOpen(false)}
				className="pb-4"
			>
				<ManageTagsForm withoutActions />
			</Modal>
		</>
	);
};
