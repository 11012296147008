import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { Button } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { useUserDispatchContext } from 'context';
import { userLogin } from 'services/auth';

interface ILoginFormData {
	username: string;
	password: string;
}

export const LoginForm: React.FC = () => {
	const navigate = useNavigate();
	const userDispatch = useUserDispatchContext();
	const handleLogin = async (formData: ILoginFormData) => {
		await userLogin(formData, userDispatch).then(() => {
			navigate('/');
		});
	};
	return (
		<Formik
			initialValues={{
				username: '',
				password: ''
			}}
			onSubmit={handleLogin}
		>
			<Form className="login-form flex flex-col" action="#" method="POST">
				<FieldInput
					name="username"
					label="Username or Email"
					placeholder="Your username or email"
					id="username"
					type="text"
					autoComplete="username"
					required
					className="w-full"
				/>
				<FieldInput
					name="password"
					label="Password"
					placeholder="Type in your password"
					id="password"
					type="password"
					autoComplete="current-password"
					required
					className="w-full mt-6"
				/>

				<Button
					type="submit"
					text="Sign in"
					rounded="medium"
					className="w-2/3 mt-10"
				/>
				<Link
					to="/forgot-password"
					className="underline mt-4 hover:no-underline"
				>
					Forgot password?
				</Link>
			</Form>
		</Formik>
	);
};
