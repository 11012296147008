import axios from 'axios';

export const copySurvey = async (surveyData: {
	survey_id: string;
	survey_name: string;
	isTemplate?: boolean;
}) => {
	return await axios
		.post<any>(`${process.env.REACT_APP_API_URL}/survey_copy/`, surveyData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error copying survey:`, error);
			return Promise.reject(error);
		});
};
