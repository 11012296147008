import React from 'react';
import { useField } from 'formik';
import { ILocasticCheckboxProps, LocasticCheckbox } from '.';

export const FormikCheckbox: React.FC<ILocasticCheckboxProps> = ({
	name,
	className,
	...rest
}) => {
	const [field, meta] = useField(name);

	const { error } = meta;
	const { value, onChange } = field;

	return (
		<LocasticCheckbox
			name={name}
			error={error}
			checked={value}
			value={value}
			onChange={e => onChange(e)}
			className={className}
			{...rest}
		/>
	);
};
