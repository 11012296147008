import React from 'react';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import { Loader } from 'components/common';
export interface IAnalyticsDashboardProps {
	url: string;
}

export const AnalyticsDashboard: React.FC<IAnalyticsDashboardProps> = ({
	url
}) => {
	const dashboardRef = React.createRef<HTMLDivElement>();
	const [isLoading, setIsLoading] = React.useState(true);

	React.useEffect(() => {
		if (dashboardRef.current?.children.length === 0) {
			const options = {
				url: url,
				container: dashboardRef.current,
				scrolling: 'yes',
				height: '100%',
				locale: 'en-US',
				footerPaddingEnabled: true,
				printEnabled: true
			};
			const dashboard = QuickSightEmbedding.embedDashboard(options);

			dashboard.on('error', () => setIsLoading(false));
			dashboard.on('load', () => setIsLoading(false));
		}
	}, []);

	return (
		<div className="h-screen flex items-center justify-center">
			{isLoading && <Loader />}
			<div
				className={isLoading ? 'hidden' : 'w-full h-full'}
				ref={dashboardRef}
			></div>
		</div>
	);
};
