import React from 'react';
import { Column, HeaderGroup, usePagination, useTable } from 'react-table';
import { useSearchParamsWithHelpers } from 'hooks';
import { IUserInfo, useGetUsersQuery } from 'services/users';

import { Loader } from 'components/common';
import { CustomSelect } from 'components/form-elements';

import { ReactComponent as SvgChevron } from 'assets/icons/chevron.svg';
import { ReactComponent as SvgMinus } from 'assets/icons/minus.svg';
import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { ReactComponent as SvgSend } from 'assets/icons/email.svg';

export interface IUsersTableProps {
	onDelete: (user: IUserInfo) => void;
	onEdit: (user: IUserInfo) => void;
	onResendEmail: (user: IUserInfo) => void;
}

interface ITableData {
	first_name?: string;
	last_name?: string;
	email?: string;
	is_admin?: boolean;
	actions?: JSX.Element;
}

export const UsersTable: React.FC<IUsersTableProps> = ({
	onDelete,
	onEdit,
	onResendEmail
}) => {
	const { updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const initialPage = 1;
	const initialPerPage = 10;
	const initialOrdering = '-first_name';

	const page = getSearchParamWithInitial('page', initialPage);
	const page_size = getSearchParamWithInitial('page_size', initialPerPage);
	const ordering = getSearchParamWithInitial('ordering', initialOrdering);

	const { data: users, isLoading } = useGetUsersQuery(
		{
			params: {
				...(page !== initialPage ? { page } : {}),
				...(page_size !== initialPerPage ? { page_size } : {}),
				...(ordering !== initialOrdering
					? { ordering }
					: { ordering: initialOrdering })
			}
		},
		{
			refetchOnWindowFocus: false
		}
	);

	const totalItems = users?.count;
	const pageCount =
		totalItems === 0 ? 1 : Math.ceil(totalItems / (page_size || 10));

	const columns: Column<ITableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'First name',
				accessor: 'first_name',
				sortDescFirst: true
			},
			{
				Header: 'Last name',
				accessor: 'last_name',
				sortDescFirst: true
			},
			{
				Header: 'Email',
				accessor: 'email',
				sortDescFirst: true
			},
			{
				Header: 'Admin',
				accessor: 'is_admin',
				sortDescFirst: true
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				sortDescFirst: true
			}
		];
	}, []);

	const data: ITableData[] = React.useMemo(() => {
		if (!users) {
			return [];
		}
		return users?.results?.map((user: IUserInfo) => {
			return {
				first_name: user.first_name,
				last_name: user.last_name,
				email: user.email,
				is_admin: user.is_admin,
				actions: (
					<span className={`flex`}>
						<button
							title="Edit user"
							role="button"
							aria-label="edit user"
							onClick={() => onEdit(user)}
							className="flex items-center justify-center hover:text-blue-700 mr-1 w-8 h-8 text-gray-500"
						>
							<SvgEdit className="w-5 h-5" />
						</button>

						<button
							title="Delete user"
							role="button"
							aria-label="delete user"
							onClick={() => onDelete(user)}
							className={`flex items-center justify-center hover:text-blue-700 mr-1 w-8 h-8 ${
								user.is_admin
									? 'pointer-events-none text-gray-300'
									: 'text-gray-500'
							}`}
						>
							<SvgTrash className="w-5 h-5" />
						</button>

						{!user.is_active && (
							<button
								title="Resend confirmation email"
								role="button"
								aria-label="resend confirmation email"
								onClick={() => onResendEmail(user)}
								className="flex items-center justify-center hover:text-blue-700 text-gray-500 w-8 h-8"
							>
								<SvgSend className="w-5 h-5" />
							</button>
						)}
					</span>
				)
			};
		});
	}, [users]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				initialState: {
					pageSize: page_size
				},
				manualPagination: true,
				pageCount: pageCount
			},
			usePagination
		);

	const setOrderSearchParam = (column: HeaderGroup<ITableData>) => {
		const alreadySet = column.id === ordering;
		const newOrdering = alreadySet ? `-${column.id}` : column.id;
		const shouldRemove = newOrdering === initialOrdering;

		updateSearchParams({
			ordering: !shouldRemove ? newOrdering : undefined
		});
	};

	if (!data || !users || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<>
			<div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
				<table
					{...getTableProps()}
					className="min-w-full divide-y divide-gray-200"
				>
					<thead className="bg-gray-50">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										<React.Fragment key={column.id}>
											{(column.id === 'actions' && (
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													<span className="h-4">{column.render('Header')}</span>
												</th>
											)) || (
												<th
													onClick={() => setOrderSearchParam(column)}
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-blue-700 cursor-pointer"
												>
													<span className="flex items-center">
														<span className="h-4">
															{column.render('Header')}
														</span>

														{((ordering === column.id ||
															ordering === `-${column.id}`) && (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgChevron
																	className={`w-3 h-3 ${
																		ordering === column.id
																			? 'rotate-90'
																			: '-rotate-90'
																	}`}
																/>
															</span>
														)) || (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgMinus className="w-3 h-3" />
															</span>
														)}
													</span>
												</th>
											)}
										</React.Fragment>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={`${i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'}`}
								>
									{row.cells.map(cell => {
										return (
											<td
												{...cell.getCellProps()}
												className="whitespace-nowrap text-sm p-0"
											>
												{(cell.column.id === 'actions' && (
													<span className="px-6 py-4 block">
														{cell.render('Cell')}
													</span>
												)) ||
													(cell.column.id === 'is_admin' &&
														cell.value === true && (
															<span className="px-6 py-4 block">
																<span className="bg-green-100 text-green-900 text-xs capitalize px-2.5 py-1 rounded-full">
																	Admin
																</span>
															</span>
														)) || (
														<span className="px-6 py-4 block whitespace-normal line-clamp-2">
															{cell.render('Cell')}
														</span>
													)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{/* PAGINATION */}
			<div className="flex items-center justify-center relative mt-10">
				<div className="flex items-center justify-center gap-4">
					<button
						aria-label="previous page"
						disabled={page === 1}
						onClick={() => {
							updateSearchParams({
								page: page - 1 !== 1 ? String(page - 1) : undefined
							});
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === 1
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4 rotate-180" />
					</button>

					<p
						className={`h-10 w-20 px-4 flex items-center justify-center bg-white rounded-lg text-sm text-gray-900 border border-gray-300 shadow-sm text-right`}
					>
						<span>
							{page} / {pageCount}
						</span>
					</p>

					<button
						aria-label="next page"
						disabled={page === pageCount}
						onClick={() => {
							updateSearchParams({ page: String(page + 1) });
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === pageCount
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4" />
					</button>
				</div>

				<CustomSelect
					name="page_size"
					options={[
						{
							value: 10,
							display: '10'
						},
						{
							value: 25,
							display: '25'
						},
						{
							value: 40,
							display: '40'
						}
					]}
					initialValue={{
						value: page_size,
						display: String(page_size)
					}}
					onChange={e => {
						updateSearchParams({
							page_size:
								e.value === initialPerPage ? undefined : String(e.value),
							page: undefined
						});
					}}
					className="absolute right-0 top-1/2 -translate-y-1/2"
				/>
			</div>
		</>
	);
};
