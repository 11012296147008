import React from 'react';
import { BarChart } from 'components/common';
import { ITimeFilterTypes } from 'services/submissions/interfaces';
import { useGetSubmissionsCountByIdQuery } from 'services/submissions';
import { Loader } from 'components/common';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { CustomSelect } from 'components/form-elements';
import { capitalize } from 'utils';

export interface ISubmissionsChartProps {
	surveyId: string;
}

export const SubmissionsChart: React.FC<ISubmissionsChartProps> = ({
	surveyId
}) => {
	const [timeFilter, setTimeFilter] = React.useState<ITimeFilterTypes>('month');

	const { data, isLoading: isLoadingSubmissionsData } =
		useGetSubmissionsCountByIdQuery(
			{
				params: {
					survey_id: surveyId,
					time_filter: timeFilter
				}
			},
			{
				refetchOnWindowFocus: false,
				enabled: !!surveyId
			}
		);

	const filteredData = React.useMemo(() => {
		if (data) {
			return Object.entries(data)
				.filter(
					item =>
						item[0] !== 'survey_id' &&
						item[0] !== 'avg_time' &&
						item[0] !== 'avg_time_filter'
				)
				.reverse();
		}
	}, [data, timeFilter]);

	const labels = React.useMemo(() => {
		if (filteredData) {
			return filteredData?.map(item =>
				new Date(item[0]).toLocaleDateString(
					undefined,
					timeFilter === 'week' || timeFilter === 'month'
						? {
								day: '2-digit',
								month: '2-digit'
						  }
						: {
								month: '2-digit',
								year: '2-digit'
						  }
				)
			);
		} else {
			return [];
		}
	}, [filteredData]);

	const values = React.useMemo(() => {
		return filteredData?.map(item => item[1]);
	}, [filteredData]);

	const totalSubmissions = values?.reduce(
		(total, currentValue) => total + currentValue
	);

	return (
		<>
			<div className="flex justify-between mb-10">
				<CustomSelect
					name="time_filter"
					options={[
						{
							value: 'week',
							display: 'Week'
						},
						{
							value: 'month',
							display: 'Month'
						},
						{
							value: 'half_year',
							display: 'Half year'
						},
						{
							value: 'year',
							display: 'Year'
						}
					]}
					initialValue={{
						value: timeFilter,
						display: capitalize(timeFilter).replace('_', ' ')
					}}
					onChange={e => {
						setTimeFilter(e.value as ITimeFilterTypes);
					}}
					label="Time filter"
					className="w-40"
				/>

				{isLoadingSubmissionsData && (
					<div className="flex flex-col items-end">
						<span className="animate-pulse bg-gray-400 w-36 h-4 my-1"></span>
						<span className="animate-pulse bg-gray-400 w-44 h-4 my-1"></span>
						<span className="animate-pulse bg-gray-400 w-52 h-4 my-1"></span>
					</div>
				)}

				{data && !isLoadingSubmissionsData && (
					<div className="flex flex-col items-end max-w-max">
						<p>
							<span className=" text-sm font-medium text-gray-700 mb-1">
								Total submissions:
							</span>
							<span className="font-bold ml-1">{totalSubmissions}</span>
						</p>
						<p>
							<span className=" text-sm font-medium text-gray-700 mb-1">
								Average time total:
							</span>
							<span className="font-bold ml-1">
								{data['avg_time'].toFixed(1)} s
							</span>
						</p>
						<p>
							<span className=" text-sm font-medium text-gray-700 mb-1">
								Average time for {timeFilter.replace('_', ' ')}:
							</span>
							<span className="font-bold ml-1">
								{data['avg_time_filter'].toFixed(1)} s
							</span>
						</p>
					</div>
				)}
			</div>

			{isLoadingSubmissionsData && (
				<div className="w-full aspect-[2/1] flex items-center justify-center">
					<Loader />
				</div>
			)}

			{(data && <BarChart labels={labels} values={values as number[]} />) || (
				<div className="w-full aspect-[2/1] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-lg text-gray-500">
						No submissions for selected time frame
					</p>
				</div>
			)}
		</>
	);
};
