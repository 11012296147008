import axios from 'axios';
import { ISurvey } from './interfaces';

export const postSurvey = async (surveyData: ISurvey) => {
	return await axios
		.post<any>(`${process.env.REACT_APP_API_URL}/survey_api/`, surveyData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error posting survey:`, error);
			return Promise.reject(error);
		});
};
