import React from 'react';
import { Button, Modal } from 'components/common';
import { useGetTemplatesQuery } from 'services/templates';
import { IQuestion, ISurvey } from 'services/surveys/interfaces';
import { getLanguageFlag } from 'components/shared/helpers';
import { useGetSurveyByIdQuery } from 'services/surveys';
import { FieldCheckbox, FieldCheckboxGroup } from 'components/formik-elements';
import { Form, Formik } from 'formik';
import { ReactComponent as SvgCheckmark } from 'assets/icons/check.svg';
import { SkipLogicWarning } from '../SkipLogicWarning';

export interface IAddFromTemplateModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
	surveyLanguages: string[];
	onSubmit: (questions: IQuestion[], consent_text:Record<string,string>) => void;
}

export const AddFromTemplateModal: React.FC<IAddFromTemplateModalProps> = ({
	isOpen,
	onRequestClose,
	surveyLanguages,
	onSubmit
}) => {
	const [chooseQuestionsModalShown, setChooseQuestionsModalShown] =
		React.useState<string | undefined>(undefined);

	const { data: templates } = useGetTemplatesQuery(
		{
			params: {
				page: 1,
				page_size: 100,
				ordering: '-survey_name'
			}
		},
		{
			refetchOnWindowFocus: false
		}
	);

	const { data: template, isLoading: isLoadingTemplate } =
		useGetSurveyByIdQuery(chooseQuestionsModalShown);

	const missingLanguages = surveyLanguages
		.filter(lang => !template?.survey_languages.includes(lang))
		.map(lang => {
			return lang;
		});

	return (
		<>
			<Modal
				headerText={`Add from library`}
				isOpen={isOpen}
				onRequestClose={onRequestClose}
				fullScreen
				className={`transition-all duration-200
					${template && chooseQuestionsModalShown ? 'mr-[10%]' : ''}
				`}
			>
				<div className="pb-8">
					<div className="flex items-center justify-between py-4">
						<span className="text-gray-400 uppercase text-xs">Library</span>
						<span className="text-gray-400 uppercase text-xs">Languages</span>
					</div>
					<ul className="flex flex-col items-center">
						{templates?.results.map((template: ISurvey, index: number) => {
							const even = index % 2 === 0;

							return (
								<li
									key={index}
									className={`w-full rounded-lg ${
										even
											? 'bg-transparent hover:bg-blue-50'
											: 'bg-gray-50 hover:bg-blue-50'
									} group cursor-pointer`}
								>
									<button
										className="w-full flex items-center justify-between px-5 py-4"
										onClick={() =>
											setChooseQuestionsModalShown(template.survey_id)
										}
									>
										<span
											key={template.survey_id}
											className="flex items-center text-sm group-hover:underline w-1/2 text-left line-clamp-1 pr-4"
											title={template.survey_name}
										>
											{template.survey_name}
										</span>
										<span className="flex items-center justify-end space-x-2 w-1/2">
											{template.survey_languages
												.slice(
													0,
													template.survey_languages.length > 10 ? 9 : 10
												)
												.map(lang => {
													return (
														<span title={lang} key={lang}>
															{getLanguageFlag(lang, 'shrink-0')}
														</span>
													);
												})}
											{template.survey_languages.length > 9 &&
												template.survey_languages.length !== 10 && (
													<span
														className="font-bold"
														title={`${template.survey_languages
															.slice(9)
															.map(lang => {
																return lang;
															})}`}
													>
														+{template.survey_languages.length - 9}
													</span>
												)}
										</span>
									</button>
								</li>
							);
						})}
					</ul>
				</div>
			</Modal>

			{template && !isLoadingTemplate && (
				<Modal
					headerText={template.survey_name}
					isOpen={chooseQuestionsModalShown !== undefined}
					onRequestClose={() => setChooseQuestionsModalShown(undefined)}
					fullScreen
				>
					<Formik
						initialValues={{
							selectedQuestions: []
						}}
						onSubmit={values => {
							onSubmit(values.selectedQuestions, template.consent_text);
							setChooseQuestionsModalShown(undefined);
							onRequestClose();
						}}
					>
						{props => {
							const { values, setFieldValue } = props;

							return (
								<Form className="pb-8">
									<span className="block text-gray-400 uppercase text-xs py-4">
										Library languages
									</span>
									<div className="flex items-center space-x-2 mb-4">
										{template.survey_languages.map((lang: string) => {
											return (
												<React.Fragment key={lang}>
													{getLanguageFlag(lang)}
												</React.Fragment>
											);
										})}
									</div>
									{missingLanguages.length !== 0 && (
										<div className="w-full px-4 py-4 border border-red-700 bg-red-300 rounded-lg">
											<p className="text-red-700 font-bold">
												Some languages are supported by your survey but are
												missing from this library:
											</p>
											<div className="mt-2">
												{missingLanguages.map(lang => {
													return (
														<React.Fragment key={lang}>
															{getLanguageFlag(lang)}
														</React.Fragment>
													);
												})}
											</div>
										</div>
									)}
									{template.questions.length > 0 && (
										<>
											<span className="block text-gray-400 uppercase text-xs py-4 mt-4">
												Library questions
											</span>
											<button
												type="button"
												className="w-full flex items-center px-5 py-4 rounded-lg bg-gray-50"
												onClick={() => {
													if (
														values.selectedQuestions.length <
														template.questions.length
													) {
														setFieldValue(
															'selectedQuestions',
															template.questions
														);
													} else {
														setFieldValue('selectedQuestions', []);
													}
												}}
											>
												<span
													className={`w-8 h-8 overflow-hidden rounded-md transition duration-150 border relative ${
														values.selectedQuestions.length ===
														template.questions.length
															? 'bg-blue-700 border-blue-700 group-hover:bg-blue-700 group-hover:border-blue-700'
															: 'bg-white border-gray-300 group-hover:border-blue-700 focus:border-blue-700'
													}`}
												>
													{values.selectedQuestions.length ===
														template.questions.length && (
														<SvgCheckmark
															className={`absolute text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 w-2/3 h-2/3`}
														/>
													)}
												</span>

												<span className="ml-4 text-sm">
													{values.selectedQuestions.length <
													template.questions.length
														? 'Select all'
														: 'Deselect all'}
												</span>
											</button>
											<FieldCheckboxGroup name="selectedQuestions">
												{template.questions.map(
													(question: IQuestion, index: number) => {
														const odd = index % 2 === 0;

														return (
															<FieldCheckbox
																className={`px-5 py-4 rounded-lg ${
																	odd ? '' : 'bg-gray-50'
																}`}
																boxSize="lg"
																key={question.name}
																name={question.name}
																id={question.name}
																value={question}
																labelComponent={
																	<span className="w-full flex items-start space-x-4 ml-2">
																		<span className="flex flex-col w-[200px]">
																			<span className="block text-gray-400 uppercase text-xs mb-0.5">
																				Question
																			</span>
																			<p>
																				{
																					question?.label?.[
																						template.survey_languages[0]
																					]
																				}
																			</p>
																		</span>
																		<span className="flex flex-col w-[200px]">
																			<span className="block text-gray-400 uppercase text-xs mb-0.5">
																				Type
																			</span>
																			<p>{question?.type}</p>
																		</span>
																		<span className="flex flex-col w-[200px]">
																			<span className="block text-gray-400 uppercase text-xs mb-0.5">
																				Appearance
																			</span>
																			<p>{question?.appearance}</p>
																		</span>
																	</span>
																}
															/>
														);
													}
												)}
											</FieldCheckboxGroup>

											<SkipLogicWarning
												selectedQuestions={values.selectedQuestions}
												template={template}
											/>
										</>
									)}
									<hr className="my-8" />
									<Button
										type="submit"
										text={`Add selected questions ${
											values.selectedQuestions.length > 0
												? '(' + values.selectedQuestions.length + ')'
												: ''
										}`}
									/>
								</Form>
							);
						}}
					</Formik>
				</Modal>
			)}
		</>
	);
};
