import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { IRule } from '.';

export const getRules = async () => {
	return await axios
		.get<IRule[]>(`${process.env.REACT_APP_API_URL}/rule/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting rules:`, error);
			return Promise.reject(error);
		});
};

export const useGetRulesQuery = () => {
	return useQuery(['notification_rules'] as QueryKey, () => getRules(), {
		refetchOnWindowFocus: false
	});
};
