import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { IEmailRead } from './interfaces';

export type IUseGetEmailsOptions = UseQueryOptions<any, any, any>;

export const getEmails = async (config?: IGetEmailsRequestConfig) => {
	return await axios
		.get<IEmailRead>(`${process.env.REACT_APP_API_URL}/email_api/`, {
			...config
		})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting emails:`, error);
			return Promise.reject(error);
		});
};

export const useGetEmailsQuery = (
	config?: IGetEmailsRequestConfig,
	queryOptions?: IUseGetEmailsOptions
) => {
	return useQuery(
		['emails', config?.params] as QueryKey,
		() => getEmails(config),
		queryOptions
	);
};

interface IGetEmailsRequestConfig extends AxiosRequestConfig {
	params?: IGetEmailsQueryParams;
}

type IGetEmailsQueryParams = {
	page?: number;
	page_size?: number;
	ordering?: string;
};
