import React from 'react';
import { Field } from 'formik';
import {
	CustomCheckbox,
	ICustomCheckboxProps
} from 'components/form-elements';

export const FieldCheckbox: React.FC<ICustomCheckboxProps> = ({
	className,
	name,
	...rest
}) => {
	return (
		<Field name={name}>
			{(props: any) => {
				const {
					field: { name, value, onChange }
				} = props;
				return (
					<div className={className}>
						<CustomCheckbox
							name={name}
							value={value}
							onChange={e => {
								onChange(e);
							}}
							{...rest}
						/>
					</div>
				);
			}}
		</Field>
	);
};
