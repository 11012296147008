import { IQuestion, ISurveyUI } from 'services/surveys/interfaces';

export const getAllQuestions = (survey: ISurveyUI) => [
	...survey.general_questions,
	...survey.sections.map(s => s.questions).flat()
];

export const getQuestionByName = (
	questionName: string,
	questions: IQuestion[]
) => questions.find(question => question.name === questionName);

export const getQuestionCount = (survey: ISurveyUI): number =>
	getAllQuestions(survey).length;

export const getQuestionIndex = (question: IQuestion, survey: ISurveyUI) => {
	return getAllQuestions(survey).findIndex(q => q.name === question.name);
};

export const isLastInGroup = (
	question: IQuestion,
	survey: ISurveyUI
): boolean => {
	let isLast = false;

	survey.general_questions.forEach((q, i) => {
		if (q.name === question.name) {
			isLast = survey.general_questions.length - 1 === i;
		}
	});
	survey.sections.forEach(s => {
		s.questions.forEach((q, i) => {
			if (q.name === question.name) {
				isLast = s.questions.length - 1 === i;
			}
		});
	});
	return isLast;
};

export const createUniqueQuestionIndex = (survey: ISurveyUI): number => {
	const allKeys = getAllQuestions(survey)
		.map(q => {
			return Number(q.name.replace('q_', ''));
		})
		.sort((a, b) => b - a);
	return allKeys.length > 0 ? allKeys[0] + 1 : 0;
};

export const createUniqueChoiceIndex = (question: IQuestion): number => {
	/*const allChoices = question.choices
		.map(choice => Number(choice.name.replace('choice_', '')))
		.sort((a, b) => b - a);*/
	const allChoices = question.choices
		.map(choice => {
			const parts = choice.name.split('_');
			return Number(parts[parts.length - 1]);
		})
		.sort((a, b) => b - a);
	return allChoices.length > 0 ? allChoices[0] + 1 : 0;
};

export const createNewQuestion = (survey: ISurveyUI): IQuestion => ({
	analytics_type: false,
	type: 'text',
	name: `q_${createUniqueQuestionIndex(survey)}`,
	appearance: '',
	parent: null,
	choices: [],
	skip_logic: [],
	relevant: null,
	range_min: '0',
	range_step: '1',
	range_max: '100',
	is_sensitive : false,
});
