import axios from 'axios';
import { IUserInfo } from './interfaces';

export const postUser = async (userData: IUserInfo) => {
	return await axios
		.post<IUserInfo>(`${process.env.REACT_APP_API_URL}/users/`, userData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error creating user:`, error);
			return Promise.reject(error);
		});
};
