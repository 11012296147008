import { FieldHelperProps } from 'formik';
import { IOption } from 'components/form-elements';
import {
	ComparismentOperator,
	IAnswerType,
	IQuestion
} from 'services/surveys/interfaces';
import { getQuestionByName } from '.';

const typesToConditions: Record<
	Exclude<IAnswerType, 'begin_group' | 'end_group' | 'geopoint' | 'image'>,
	ComparismentOperator[]
> = {
	text: ['!=""', '=""', '=', '!='],
	email: ['!=""', '=""', '=', '!='],
	country: ['!=""', '=""', '=', '!='],
	select_one: ['!=""', '=""', '=', '!='],
	select_multiple: ['!=""', '=""', '=', '!=', 'selected'],
	property: ['!=""', '=""', '=', '!=', 'selected'],
	emoji_rating: ['!=""', '=""', '=', '!='],
	integer: ['!=""', '=""', '=', '!=', '>', '<', '>=', '<='],
	decimal: ['!=""', '=""', '=', '!=', '>', '<', '>=', '<='],
	date: ['!=""', '=""', '=', '!=', '>', '<', '>=', '<='],
	range: ['!=""', '=""', '=', '!=', '>', '<', '>=', '<='],
	age: ['!=""', '=""', '=', '!=', '>', '<', '>=', '<='],
	sex: ['!=""', '=""', '=', '!='],
	nps: ['!=""', '=""', '=', '!='],
	rating: ['!=""', '=""', '=', '!='],
	matrix_rating: ['!=""', '=""', '=', '!=']
};

export const questionConditions: Record<ComparismentOperator, string> = {
	'!=""': 'Was answered',
	'=""': 'Was not answered',
	'=': 'Is equal to',
	'!=': 'Is not equal to',
	'>': 'Is greater than',
	'<': 'Is less than',
	'>=': 'Is greater than or equal to',
	'<=': 'Is less than or equal to',
	'selected' : 'Is selected'
};
export const requiresSkipLogicAnswer = (operator: ComparismentOperator) => {
	return ['=""', '!=""', ''].includes(operator);
};
export const resetConditionOnChange = (
	questionIndex: number,
	conditionIndex: number,
	groupName: string,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>
) => {
	const condition = `${groupName}[${questionIndex}].skip_logic[${conditionIndex}]`;
	getFieldHelpers(`${condition}.operator`).setValue('!=""');
	getFieldHelpers(`${condition}.answer`).setValue('');
};
export const resetAnswerOnChange = (
	choice: IOption,
	questionIndex: number,
	conditionIndex: number,
	groupName: string,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>
) => {
	const condition = `${groupName}[${questionIndex}].skip_logic[${conditionIndex}]`;
	if (requiresSkipLogicAnswer(choice.value as ComparismentOperator)) {
		getFieldHelpers(`${condition}.answer`).setValue('');
	}
};
export const getQuestionOptions = (
	questions: IQuestion[],
	currentLanguage: string,
	exclude?: IQuestion
): IOption[] => [
	{
		display: 'Select Question',
		value: ''
	},
	...questions
		.filter(
			question =>
				question.label?.[currentLanguage] &&
				exclude?.name !== question.name &&
				question.type !== 'image' &&
				question.type !== 'geopoint'
		)
		.map(question => {
			return {
				display: String(question.label?.[currentLanguage]),
				value: String(question.name)
			};
		})
];

export const getQuestionChoices = (
	questionName: string,
	questions: IQuestion[],
	currentLanguage: string,
	defaultLanguage: string
): IOption[] => {
	const choices = getQuestionByName(questionName, questions)?.choices || [];

	return [
		...choices.map(choice => {
			return {
				display: String(
					choice.label?.[currentLanguage] || choice.label?.[defaultLanguage]
				),
				value: String(choice.name)
			};
		})
	];
};

export const getQuestionConditionOptions = (
	questionName: string,
	questions: IQuestion[]
): IOption[] => {
	const type = getQuestionByName(questionName, questions)?.type as
		| Exclude<IAnswerType, 'begin_group' | 'end_group' | 'geopoint' | 'image'>
		| undefined;
	const entries = Object.entries(questionConditions);

	const filteredByQuestionType = entries.filter(
		([value]) =>
			type && typesToConditions[type].includes(value as ComparismentOperator)
	);

	const mappedToSlectOptions = filteredByQuestionType.map(
		([value, display]) => ({
			value,
			display
		})
	);

	return mappedToSlectOptions.length
		? mappedToSlectOptions
		: [{ value: '', display: 'Set Condition' }];
};
