import React from 'react';
import { Field } from 'formik';
import { CustomTextArea, ICustomTextAreaProps } from 'components/form-elements';

export const FieldTextArea: React.FC<ICustomTextAreaProps> = ({
	className,
	name,
	label,
	placeholder,
	value,
	error,
	id,
	onChange,
	rows,
	...rest
}) => {
	return (
		<Field name={name}>
			{(props: any) => {
				const {
					field: { name, value, onChange }
				} = props;
				return (
					<CustomTextArea
						rows={rows}
						className={`${className || ''}`}
						id={id}
						name={name}
						label={label}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						error={error}
						{...rest}
					/>
				);
			}}
		</Field>
	);
};
