import { Layout } from 'components/shared';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
  } from "@/components/ui/accordion"
  
const Help = () => {

	return (
		<Layout
			pageTitle={
				<div className="flex items-center min-h-10">
					<p>Help page</p>
				</div>
			}
		>
			<span>If you want to check the detailed documentation read <a href='https://megatrendgrupa.atlassian.net/wiki/external/MTA2NDc4YTAwZTE3NDMwMWEwMWViYmJiOTRmNjk0NDE' target='_blank' className='underline text-blue-400 hover:text-blue-800'>here</a></span>

			<Accordion type="single" collapsible className="mt-6">
				<AccordionItem value="item-1">
					<AccordionTrigger>How do I create a survey QR code?</AccordionTrigger>
					<AccordionContent>
						<p>To generate a survey QR code, follow these steps:</p>
						<ol className='list-decimal'>
							<li>Ensure that the survey is in an <strong>Open</strong> status. Only published surveys with unique distribution links support QR code generation.</li>
							<li>Click on the open survey you want to generate a QR code for.</li>
							<li>Navigate to the <strong>Additional Options</strong> pane.</li>
							<li>Select the desired <strong>QR code type</strong>.</li>
							<li>Choose <strong>colors</strong> for your QR code.</li>
							<li>Optionally, upload a <strong>logo</strong> to be embedded in the QR code for branding.</li>
							<li>Click the <strong>Generate New QR Code</strong> button.</li>
							<li>Click on the <strong>Download QR code</strong> button for distribution.</li>
						</ol>
						<p>Make sure your survey is published and in the <strong>Open</strong> status before generating QR codes or distributing surveys via links.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-2">
					<AccordionTrigger>How can I create a survey in multiple languages?</AccordionTrigger>
					<AccordionContent>
						<p>To make a survey available in multiple languages, follow these steps:</p>
						<ol className='list-decimal'>
							<li>Navigate to the <strong>Edit Survey</strong> or <strong>New Survey</strong> pane.</li>
							<li>Click on the <strong>Manage Languages</strong> button.</li>
							<li>Choose the desired languages for your survey. The <strong>default language</strong> determines how question names will be presented in the analytics pane.</li>
							<li>Click on the <strong>Save</strong> button to save your language choices.</li>
							<li>Ensure that all question and choice labels, hints, descriptions, and survey titles are defined in all selected languages. You can use the automatic translation feature to translate these elements from one language to another.</li>
							<li>To automatically translate all labels, click on the <strong>Translate</strong> button. Choose the <strong>Translate from</strong> and <strong>Translate to</strong> languages, and then click on the <strong>Translate</strong> button.</li>
							<li>Once all labels, hints, descriptions, and titles are defined in all selected languages, click on the <strong>Save Survey</strong> button to save your multilingual survey.</li>
						</ol>
						<p>Make sure to define all necessary elements in each language and leverage the automatic translation feature for efficiency.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-3">
					<AccordionTrigger>I received a login link that doesn't work anymore. How can I log into the application?</AccordionTrigger>
					<AccordionContent>
						<p>If the link you received is no longer working, it's likely that it was the registration link for your initial login.</p>
						<p>After selecting your username and password during registration, you were logged in for the first time.</p>
						<p>For subsequent logins, please note that you cannot use the registration link. Instead, access the application through the following URL: <a href="https://app.hospitalityinsights.eu/" target='_blank' className='underline text-blue-400 hover:text-blue-800'>link</a>. Use this URL for all your future logins.</p>
						<p>If you encounter any further issues or have additional questions, feel free to reach out for assistance.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-4">
					<AccordionTrigger>How do I add other users to the application?</AccordionTrigger>
					<AccordionContent>
						<p>To add new users to the application, follow these steps:</p>
						<ol className='list-decimal'>
							<li>Ensure that you have <strong>Admin</strong> rights. Only Admin users have the capability to add new users.</li>
							<li>Navigate to the <strong>Settings</strong> pane.</li>
							<li>Click on the <strong>Users</strong> tab.</li>
							<li>Select the <strong>+ New User</strong> button.</li>
							<li>Fill in the required information such as <strong>First Name</strong>, <strong>Last Name</strong>, and <strong>Email</strong>.</li>
							<li>Choose whether the new user will have <strong>admin rights</strong>.</li>
							<li>Click on the <strong>Create User</strong> button.</li>
						</ol>
						<p>The new user will receive a registration email. Once they complete the registration process, make sure to share the app's URL with them: <a href="https://app.hospitalityinsights.eu/" target='_blank' className='underline text-blue-400 hover:text-blue-800'>link</a>. After registration, they won't be able to access the app via the registration link.</p>
						<p>If you have any further questions or encounter issues, feel free to reach out for assistance.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-5">
					<AccordionTrigger>What is a survey status?</AccordionTrigger>
					<AccordionContent>
						<p>Survey statuses indicate the phase of the survey and define the available actions for that survey.</p>
						<p><strong>Draft Status:</strong></p>
						<ol className='list-decimal'>
							<li><strong>Draft</strong> status is assigned when the survey is initially created.</li>
							<li>Surveys in <strong>draft</strong> status are meant for further refinement.</li>
							<li>You can freely edit all survey components, but distribution is restricted.</li>
							<li>Keep surveys in draft status until they are ready for distribution, as editing options become limited once the survey is published.</li>
						</ol>
						<p><strong>Open Status:</strong></p>
						<ol className='list-decimal'>
							<li>After publishing, the survey transitions to the <strong>Open</strong> status.</li>
							<li>An <strong>Open</strong> survey is prepared for distribution via links, QR codes, or emails.</li>
							<li>Editing options are limited in an <strong>Open</strong> survey. For instance, you can't change question types or delete questions to maintain survey consistency.</li>
							<li>Keep the survey in the <strong>Open</strong> status as long as you want to receive submissions.</li>
						</ol>
						<p><strong>Closed Status:</strong></p>
						<ol className='list-decimal'>
							<li>When you want to retire a survey, change its status to <strong>Closed</strong>.</li>
							<li>To do this, select the desired survey and click the <strong>Deactivate</strong> button.</li>
							<li>Once closed, end-users can no longer access the survey, and no new submissions will be accepted.</li>
							<li>All data from closed surveys remains available in the application's <strong>Analytics pane</strong>.</li>
						</ol>
						<p>Understanding and managing survey statuses ensures effective survey creation, distribution, and retirement. If you have further questions, feel free to seek assistance.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-6">
					<AccordionTrigger>What is a Survey Analytics Type?</AccordionTrigger>
					<AccordionContent>
						<p>A <strong>survey analytics type</strong> is a categorization that specifies where you analyze data derived from a survey. The purpose is to organize similar survey data into distinct groups for easier analysis.</p>
						<p>For instance, data from post-stay surveys is grouped together, while gastronomy survey data forms another cohesive set, and so on. In cases where no predefined survey type aligns with your survey's purpose, you can use the "Other" survey type. This serves as a designated space where miscellaneous survey data will be consolidated for analysis.</p>
						<p>It's crucial to emphasize that selecting an appropriate survey type is essential for the survey data to be visible and accessible on the analytics pane. This categorization simplifies the analysis process, allowing you to draw insights more effectively from surveys with shared characteristics.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-7">
					<AccordionTrigger>How can I download all survey submission data?</AccordionTrigger>
					<AccordionContent>
						<p>Navigate to the Dashboard pane of the survey with at least one submission.</p>
						<p>Then, click on the "Download CSV" button.</p>
						<p>The newly downloaded .csv file will contain data from all survey submissions.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-8">
					<AccordionTrigger>How do I view individual responses for a survey?</AccordionTrigger>
					<AccordionContent>
						<p>Navigate to the Dashboard section of your survey, and click on the public link of the desired submission. The new pane will display data from the selected submission.</p>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-9">
					<AccordionTrigger>How do i distribute a survey via email?</AccordionTrigger>
					<AccordionContent>
						<ol className='list list-decimal'>
							<li>Navigate to the <strong>Emails</strong> pane of the app.</li>
							<li>Make sure that you have at least one <strong>email template</strong>. <strong>Email template</strong> is a pre-designed and formatted email structure that serves as a starting point for creating and sending standardized emails.</li>
							<li>Click on the <strong>New Email</strong> button.</li>
							<li>Choose between <strong>One user</strong> or <strong>Multiple users</strong> options.</li>
							<li>Fill in the necessary information like names and email.</li>
							<li>Select from one of the created <strong>email templates</strong>.</li>
							<li>Select the <strong>survey</strong> to be sent.</li>
							<li>If you want to limit the recipient to be able to fill out the survey only once, check the <strong>User can fill out the survey only once</strong> toggle.</li>
							<li>Click the <strong>Send</strong> button.</li>
						</ol>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-10">
					<AccordionTrigger>What is a property?</AccordionTrigger>
					<AccordionContent>
						<p>Property refers to the categorization of all units that serve as a basis for analysis. If you wish to segment and analyze your data based on a specific unit of your business, such as a hotel or restaurant, you must first define it as a property.</p>
						<h3>To create a new property:</h3>
						<ol className='list-decimal'>
							<li>Navigate to the <strong>Settings</strong> pane.</li>
							<li>Navigate to the <strong>Properties</strong> tab.</li>
							<li>Click the <strong>+ New property</strong> button.</li>
							<li>Fill out the needed information, including the <strong>property type</strong>.</li>
							<li>Click on the <strong>Create property</strong> button.</li>
						</ol>
						<p>You can associate a property with a survey in two ways:</p>
						<ol className='list-decimal'>
							<li>During the survey creation process, you can designate a property by selecting one from the created properties. This is done by choosing a <strong>Survey property</strong> from the list of created properties.</li>
							<li>Alternatively, you can allow respondents to choose the property for which they are submitting answers. This is achieved by including a specialized <strong>Property question</strong>, which is a prepopulated select-one question. The answer choices are loaded from all created properties, and you can customize the choices by adding or removing properties as needed.</li>
						</ol>
					</AccordionContent>
				</AccordionItem>
				<AccordionItem value="item-11">
					<AccordionTrigger>What is a conditional redirect question?</AccordionTrigger>
					<AccordionContent>
						<p>A conditional redirect question enables you to direct respondents to a specific page based on their answer. This feature is compatible with rating questions, allowing you to specify the conditions under which respondents will be redirected to a desired web address (e.g., TripAdvisor, Google Reviews, etc.). </p>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</Layout>
	);
};

export default Help;
