import React from 'react';
import { useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';

import { Layout } from 'components/shared';
import { Popover, PopoverOption, Loader } from 'components/common';

import { usePrompt, useToast } from 'hooks';
import {
	alertShowAction,
	setAvailableLanguages,
	setCurrentLanguage,
	setDefaultLanguage
} from 'reducers';
import {
	useAlertDispatchContext,
	useLanguageContext,
	useLanguageDispatchContext
} from 'context';
import {
	updateSurvey,
	deleteSurvey,
	useGetSurveyByIdQuery
} from 'services/surveys';
import { ISurveyUI } from 'services/surveys/interfaces';

import {
	fillPropertyTypeChoices,
	postProcessSurvey,
	preProcessSurvey,
	templateValidationSchema
} from 'components/survey/helpers';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { Template } from 'components/template/Template';
import { SurveyInfo } from 'components/survey';
import { useGetProperties } from 'services/properties';

function SingleTemplate() {
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const show = useToast();
	const params = useParams();
	const alertDispatch = useAlertDispatchContext();
	const { availableLanguages, defaultLanguage } = useLanguageContext();
	const languageDispatch = useLanguageDispatchContext();
	const { data: properties } = useGetProperties();
	const { data: template, isLoading: isLoadingTemplate } =
		useGetSurveyByIdQuery(params.surveyId);

	const [isUpdating, setIsUpdating] = React.useState(false);

	React.useEffect(() => {
		if (template) {
			languageDispatch(setDefaultLanguage(template.survey_default_lang));
			languageDispatch(setCurrentLanguage(template.survey_default_lang));
			languageDispatch(setAvailableLanguages(template.survey_languages));
		}
	}, [template]);

	const handleSubmit = (values: ISurveyUI) => {
		setIsUpdating(true);
		fillPropertyTypeChoices(values.general_questions, availableLanguages);
		updateSurvey(
			String(params.surveyId),
			postProcessSurvey(values, availableLanguages, defaultLanguage, true)
		)
			.then(() => {
				show({
					type: 'success',
					headerText: 'Updated!',
					message: 'Successfully updated library'
				});
				queryClient.invalidateQueries(['survey', params.surveyId]);
				navigate('/templates');
				setIsUpdating(false);
			})
			.catch(() => {
				show({
					type: 'error',
					headerText: 'Error!',
					message: `Your session may be expired, try saving the survey again.`
				});
				setIsUpdating(false);
			});
	};

	const deleteHandler = () => {
		alertDispatch(
			alertShowAction({
				header: `Delete library?`,
				message: `Are you sure you want to delete this library?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Confirm`,
						handler: () =>
							deleteSurvey(String(template?.survey_id)).then(() =>
								navigate('/templates')
							)
					}
				]
			})
		);
	};

	if (isLoadingTemplate) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!isLoadingTemplate && template == undefined) {
		return (
			<Layout pageTitle={`Invalid library`}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">Library non existent</p>
				</div>
			</Layout>
		);
	}

	return (
		<>
			{template && <SurveyInfo survey={template} />}
			<Layout
				pageTitle={`${template?.survey_name}`}
				headerComponent={
					<Popover>
						<PopoverOption
							label="Preview"
							href={
								template.survey_status === 'draft_success'
									? template.survey_draft_link
									: template?.survey_link
							}
							target="_blank"
							rel="noopener noreferrer"
						/>
						<PopoverOption label="Delete library" onClick={deleteHandler} />
					</Popover>
				}
			>
				{template && (
					<Formik
						initialValues={preProcessSurvey(template)}
						validationSchema={templateValidationSchema(availableLanguages)}
						onSubmit={handleSubmit}
					>
						{props => {
							const {
								dirty,
								values,
								errors,
								getFieldHelpers,
								submitCount,
								isSubmitting
							} = props;

							usePrompt(
								'Changes made will be lost, are you sure you want to leave?',
								dirty && !isUpdating
							);

							const errorElement = Array.from(
								document.querySelectorAll('.errorElement')
							)?.[0];

							isSubmitting &&
								errors &&
								errorElement?.scrollIntoView({
									behavior: 'smooth',
									block: 'center'
								});

							return (
								<Form>
									<Template
										survey={values}
										getFieldHelpers={getFieldHelpers}
										errors={submitCount > 0 && errors ? errors : undefined}
										isSubmitting={isSubmitting}
										properties={properties}
									/>
								</Form>
							);
						}}
					</Formik>
				)}

				{isUpdating && (
					<div className="fixed z-50 top-0 left-0 flex items-center justify-center w-full h-screen bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
						<Loader />
					</div>
				)}
			</Layout>
		</>
	);
}

export default SingleTemplate;
