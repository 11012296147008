import classNames from 'classnames';
import React from 'react';

import { ReactComponent as SvgCheckmark } from './icon-checkmark.svg';
import { useLocasticCheckboxGroupContext } from '.';
import { InputFeedback } from 'components/form-elements';

type ILocasticCheckboxVariants = 'checkbox' | 'toggler';

export interface ILocasticCheckboxProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	id?: string;
	label?: string | JSX.Element;
	error?: string;
	boxSize?: 'sm' | 'lg';
	variant?: ILocasticCheckboxVariants;
	className?: string;

	name: string;
}

export const LocasticCheckbox: React.FC<ILocasticCheckboxProps> = ({
	id,
	label,
	error,
	boxSize = 'sm',
	variant = 'checkbox',
	className,
	value,

	name,
	onChange,
	checked = false,
	disabled = false,
	required = false,
	tabIndex = disabled ? -1 : 0,
	...rest
}) => {
	const checkboxRef =
		React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const [isSingleChecked, setIsSingleChecked] =
		React.useState<boolean>(checked);

	const [state, onGroupChange] = useLocasticCheckboxGroupContext();

	const isChecked =
		state?.length > 0
			? state?.some((item: string) => item === name)
			: isSingleChecked;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsSingleChecked(e.target.checked);
		onChange && onChange(e);
		onGroupChange(name);
	};

	const handleOnKeyDown = (e: React.KeyboardEvent<HTMLSpanElement>) => {
		if (e.key === ' ' || e.key === 'Enter') {
			e.preventDefault();
			checkboxRef.current.click();
		}
	};

	React.useEffect(() => {
		setIsSingleChecked(checked);
	}, [checked]);

	const wrapperClasses = classNames(
		'flex items-center cursor-pointer group relative',
		{
			'opacity-50 pointer-events-none': disabled
		}
	);

	const checkboxClasses = classNames(
		'flex items-center justify-center transition-colors duration-150 border relative',
		{
			'w-5 h-5 rounded': boxSize === 'sm',
			'w-8 h-8 rounded-md': boxSize === 'lg',
			'border-blue-700': error,
			'bg-blue-700 border-blue-700 group-hover:bg-blue-700 group-hover:border-blue-700':
				isChecked,
			'border-gray-300 group-hover:border-blue-700 focus:border-blue-700':
				!isChecked && !error
		}
	);

	const togglerClasses = classNames(
		'rounded-full transition-all duration-150 border-4 relative',
		{
			'w-10 h-6': boxSize === 'sm',
			'w-14 h-8': boxSize === 'lg',
			'bg-blue-700 border-blue-700': isChecked,
			'bg-gray-300 border-gray-300': !isChecked
		}
	);

	const togglerInnerClasses = classNames(
		'block rounded-full bg-white transition-all duration-150',
		{
			'w-4 h-4': boxSize === 'sm',
			'w-6 h-6': boxSize === 'lg',
			'translate-x-full': isChecked,
			'translate-x-0': !isChecked
		}
	);

	return (
		<div
			role="checkbox"
			aria-checked={isChecked}
			className={`flex flex-col relative max-w-max ${className || ''}`}
		>
			<div
				className={wrapperClasses}
				onClick={() => checkboxRef.current.click()}
			>
				<input
					ref={checkboxRef}
					className="w-0 h-0 border-0 invisible absolute"
					data-testid={`${name}-checkbox`}
					id={id}
					name={name}
					type="checkbox"
					value={isChecked as any}
					checked={isChecked}
					onChange={handleOnChange}
					{...rest}
				/>
				<span
					id={id}
					role="checkbox"
					tabIndex={tabIndex}
					aria-checked={isChecked}
					onKeyDown={handleOnKeyDown}
					className={variant === 'checkbox' ? checkboxClasses : togglerClasses}
				>
					{variant === 'checkbox' ? (
						isChecked && (
							<SvgCheckmark
								className={`text-white ${
									boxSize === 'sm' ? 'w-4/5 h-4/5' : 'w-2/3 h-2/3'
								}`}
							/>
						)
					) : (
						<span className={togglerInnerClasses} />
					)}
				</span>

				{label && (
					<label
						className={`text-sm ml-2 cursor-pointer ${
							error ? 'text-blue-700' : 'text-gray-700'
						}`}
					>
						{label}
						{required && <span>*</span>}
					</label>
				)}
			</div>

			{error && <InputFeedback name={name} error={error} />}
		</div>
	);
};
