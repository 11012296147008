import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button, Loader } from 'components/common';
import { NotificationRulesTable } from 'components/notifications';
import { Layout } from 'components/shared';
import { deleteRule, IRule } from 'services/notifications';
import { useGetQuotaQuery } from 'services/subscriptions';

import { useAlertDispatchContext } from 'context';
import { useToast } from 'hooks';
import { alertShowAction } from 'reducers';

import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgLock } from 'assets/icons/lock.svg';
import HelpInfo from 'components/common/helpInfo/HelpInfo';

const Notifications = () => {
	const show = useToast();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const alertDispatch = useAlertDispatchContext();
	const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();

	const deleteHandler = (rule: IRule) => {
		alertDispatch(
			alertShowAction({
				header: `Delete rule?`,
				message: `Are you sure you want to delete ${rule.rule_name}?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Delete`,
						handler: () =>
							deleteRule(String(rule.rule_id))
								.then(() => {
									queryClient.invalidateQueries('notification_rules');
									show({
										type: 'success',
										headerText: 'Deleted!',
										message: 'Successfully deleted rule'
									});
								})
								.catch(() => {
									show({
										type: 'error',
										headerText: 'Error!',
										message: 'Something went wrong'
									});
								})
					}
				]
			})
		);
	};

	const editHandler = (rule: IRule) => {
		navigate(`/notifications/${rule.rule_id}`);
	};

	if (isLoadingQuota) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!isLoadingQuota && quota?.alerts_notifications !== 'included') {
		return (
			<Layout pageTitle="Notifications">
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgLock className="w-64 h-64 text-gray-200" />
					<p className="text-xl mt-2">Notifications not included in plan</p>
				</div>
			</Layout>
		);
	}

	return (
		<Layout
			pageTitle="Notifications"
			headerComponent={
				<Button
					text="New rule"
					size="small"
					iconLeft={<SvgPlus />}
					href="/notifications/new-rule"
				/>
			}
		>
			<HelpInfo 
				message='Create notifications for receiving information from your submissions'
				icon={true}
				hoverMessageFirstLine='Hospitality Insights’ notification system enables <strong>real-time alerts</strong> for new submissions, allowing businesses to promptly <strong>respond to feedback</strong>, <strong>address concerns</strong>, and <strong>maintain proactive engagement</strong> with guests.'
				hoverMessageSecondLine='Customize notification rules for <strong>any question</strong> in your surveys to ensure you stay informed about specific feedback from your guests.'
			/>
			<NotificationRulesTable onDelete={deleteHandler} onEdit={editHandler} />
		</Layout>
	);
};

export default Notifications;
