import classnames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Column, HeaderGroup, usePagination, useTable } from 'react-table';
import { useSearchParamsWithHelpers } from 'hooks';

import { useGetSurveysQuery } from 'services/surveys';
import { ISurvey, ISurveyStatus } from 'services/surveys/interfaces';
import { ISurveyTag } from 'services/tags';

import { Loader } from 'components/common';
import { getLanguageFlag } from 'components/shared/helpers';
import { CustomSelect } from 'components/form-elements';

import { ReactComponent as SvgChevron } from 'assets/icons/chevron.svg';
import { ReactComponent as SvgMinus } from 'assets/icons/minus.svg';
import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { ReactComponent as SvgPdf } from 'assets/icons/pdf.svg';
import { ReactComponent as SvgEye } from 'assets/icons/eye-on.svg';
import { ReactComponent as SvgCopy } from 'assets/icons/duplicate.svg';
import { ReactComponent as SvgSend } from 'assets/icons/send.svg';

export interface ISurveyTableV2Props {
	name?: string;
	tags?: string;
	disablePublish: boolean;
	onPrint: (survey: ISurvey) => void;
	onDelete: (survey: ISurvey) => void;
	onEdit: (survey: ISurvey) => void;
	onCopy: (survey: ISurvey) => void;
	onPublish: (survey: ISurvey) => void;
}

interface ITableData {
	href?: string;
	survey_name?: string;
	created_on?: Date;
	submissions_count?: string | number;
	tags: ISurveyTag[];
	languages: JSX.Element[];
	survey_status?: ISurveyStatus;
	actions?: JSX.Element;
}

export const SurveyTableV2: React.FC<ISurveyTableV2Props> = ({
	name,
	tags,
	disablePublish,
	onPrint,
	onDelete,
	onEdit,
	onCopy,
	onPublish
}) => {
	const { searchParams, updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const initialPage = 1;
	const initialPerPage = 10;
	const initialOrdering = '-created_on';

	const page = getSearchParamWithInitial('page', initialPage);
	const page_size = getSearchParamWithInitial('page_size', initialPerPage);
	const ordering = getSearchParamWithInitial('ordering', initialOrdering);
	const survey_status = searchParams.get('survey_status') as ISurveyStatus;

	const { data: surveys, isLoading } = useGetSurveysQuery(
		{
			params: {
				...(page !== initialPage ? { page } : {}),
				...(page_size !== initialPerPage ? { page_size } : {}),
				...(ordering !== initialOrdering
					? { ordering }
					: { ordering: initialOrdering }),

				...(name ? { name } : {}),
				...(tags ? { tags } : {}),
				...(survey_status ? { survey_status } : {})
			}
		},
		{
			refetchOnWindowFocus: false
		}
	);

	const totalItems = surveys?.count;
	const pageCount =
		totalItems === 0 ? 1 : Math.ceil(totalItems / (page_size || 10));

	const columns: Column<ITableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'Href',
				accessor: 'href',
				sortDescFirst: true
			},
			{
				Header: 'Name',
				accessor: 'survey_name',
				sortDescFirst: true
			},
			{
				Header: 'Created',
				accessor: 'created_on',
				sortDescFirst: true
			},
			{
				Header: 'Subs',
				accessor: 'submissions_count',
				sortDescFirst: true
			},
			{
				Header: 'Tags',
				accessor: 'tags',
				sortDescFirst: true
			},
			{
				Header: 'Languages',
				accessor: 'languages',
				sortDescFirst: true
			},
			{
				Header: 'Status',
				accessor: 'survey_status',
				sortDescFirst: true
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				sortDescFirst: true
			}
		];
	}, []);

	const data: ITableData[] = React.useMemo(() => {
		if (!surveys) {
			return [];
		}
		return surveys?.results?.map((survey: ISurvey) => {
			const date =
				survey.created_on && new Date(survey.created_on).toLocaleDateString();

				let displayStatus: string;

				if (survey.survey_status?.includes('failed')) {
				  displayStatus = 'Failed';
				} else if (survey.survey_status?.includes('processing')) {
				  displayStatus = 'Processing';
				} else {
				  displayStatus = survey.survey_status ? survey.survey_status?.replace("_success", ""):'';
				}
			return {
				href: `/surveys/${survey.survey_id}`,
				survey_name: survey.survey_name,
				created_on: date,
				submissions_count: survey.submissions_count,
				tags: (
					<span className="flex space-x-2 items-center">
						{survey.tags &&
							survey.tags?.slice(0, 1).map((tag: ISurveyTag) => {
								return (
									<span
										key={tag.tag_id}
										className="py-1 px-2 rounded-lg bg-gray-100 text-gray-600 text-xs max-w-[80px] truncate"
										title={tag.name}
									>
										{tag.name}
									</span>
								);
							})}
						{survey.tags && survey.tags?.length > 1 && (
							<span
								title={`${survey.tags.slice(1).map(tag => tag.name)}`}
								className="text-xs"
							>
								+{survey.tags?.length - 1}
							</span>
						)}
					</span>
				),
				languages: (
					<span className="flex space-x-2 items-center">
						{survey.survey_languages
							.slice(0, survey.survey_languages.length > 5 ? 4 : 5)
							.map(lang => {
								return (
									<span key={lang} title={lang}>
										{getLanguageFlag(lang, 'w-4 shrink-0')}
									</span>
								);
							})}
						{survey.survey_languages.length > 4 &&
							survey.survey_languages.length !== 5 && (
								<span
									className="text-xs"
									title={`${survey.survey_languages.slice(4).map(lang => {
										return lang;
									})}`}
								>
									+{survey.survey_languages.length - 4}
								</span>
							)}
					</span>
				),
				survey_status: displayStatus,
				actions: (
					<span className="flex">
						<button
							title="Preview survey"
							role="button"
							aria-label="preview survey"
							disabled={survey.survey_status?.includes('processing')}
							onClick={()=>window.open(					
								survey.survey_status === 'draft_success'
									? survey.survey_draft_link
									: survey.survey_link
							)
							}
							className={`flex items-center justify-center w-8 h-8 mr-1 ${
								survey.survey_status === 'closed_success' ||
								(survey.survey_status === 'open_success' &&
									survey.survey_link === null) ||
								(survey.survey_status === 'draft_success' &&
									survey.survey_draft_link === null) ||
									survey.survey_status?.includes('processing')
									? 'cursor-auto text-gray-300'
									: 'text-gray-500 hover:text-blue-700'
							}`}
						>
							<SvgEye className="w-5 h-5" />
						</button>

						<button
							title="Download Pdf"
							role="button"
							aria-label="download pdf"
							onClick={() => onPrint(survey)}
							className={`flex items-center justify-center w-8 h-8 mr-1 ${
								survey.survey_status !== 'open_success' ||
								(survey.survey_status === 'open_success' && survey.survey_link === null)||
								survey.survey_status?.includes('processing')
									? 'cursor-auto text-gray-300'
									: 'text-gray-500 hover:text-blue-700'
							}`}
							disabled={
								survey.survey_status !== 'open_success' ||
								(survey.survey_status === 'open_success' && survey.survey_link === null) ||
								survey.survey_status?.includes('processing')
							}
						>
							<SvgPdf className="w-5 h-5" />
						</button>

						<button
							title="Edit survey"
							role="button"
							aria-label="edit survey"
							onClick={() => onEdit(survey)}
							className={`flex items-center justify-center w-8 h-8 mr-1 ${
								survey.survey_status?.includes('close') ||
								survey.survey_status?.includes('processing')
									? 'cursor-auto text-gray-300'
									: 'text-gray-500 hover:text-blue-700'
							}`}
							disabled={survey.survey_status?.includes('close') ||
							survey.survey_status?.includes('processing')}
						>
							<SvgEdit className="w-5 h-5" />
						</button>

						<button
							title="Duplicate survey"
							role="button"
							aria-label="copy survey"
							onClick={() => onCopy(survey)}
							disabled={survey.survey_status?.includes('processing')}
							className={`flex items-center justify-center w-8 h-8 mr-1 
							${survey.survey_status?.includes('processing') ? 
								'cursor-auto text-gray-300'
								:'text-gray-500 hover:text-blue-700'}`}
						>
							<SvgCopy className="w-5 h-5" />
						</button>

						<button
							title="Delete survey"
							role="button"
							aria-label="delete survey"
							onClick={() => onDelete(survey)}
							className={`flex items-center justify-center w-8 h-8 ${
								survey.survey_status === 'open_success' ||
								survey.survey_status?.includes('processing') ||
								(survey.survey_status?.includes('close') && survey.submissions_count)
									? 'cursor-auto text-gray-300'
									: 'text-gray-500 hover:text-blue-700'
							}`}
							disabled={survey.survey_status === 'open_success' ||
							survey.survey_status?.includes('processing') || 
							(survey.survey_status === 'closed_success' && survey.submissions_count!=null && survey.submissions_count!=undefined && survey.submissions_count>0)}
						>
							<SvgTrash className="w-5 h-5" />
						</button>

						{(survey.survey_status === 'draft_success' && (
							<button
								title="Publish survey"
								role="button"
								aria-label="publish survey"
								onClick={() => onPublish(survey)}
								className={`flex items-center justify-center w-8 h-8 ml-1 ${
									disablePublish
										? 'cursor-auto text-gray-300'
										: 'text-gray-500 hover:text-blue-700'
								}`}
								disabled={disablePublish}
							>
								<SvgSend className="w-5 h-5" />
							</button>
						)) || <span className="w-8 h-8 mr-1"></span>}
					</span>
				)
			};
		});
	}, [surveys]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				initialState: {
					pageSize: page_size,
					hiddenColumns: ['href']
				},
				manualPagination: true,
				pageCount: pageCount
			},
			usePagination
		);

	const setOrderSearchParam = (column: HeaderGroup<ITableData>) => {
		const alreadySet = column.id === ordering;
		const newOrdering = alreadySet ? `-${column.id}` : column.id;
		const shouldRemove = newOrdering === initialOrdering;

		updateSearchParams({
			ordering: !shouldRemove ? newOrdering : undefined
		});
	};

	if (!data || !surveys || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<>
			<div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
				<table
					{...getTableProps()}
					className="min-w-full divide-y divide-gray-200"
				>
					<thead className="bg-gray-50">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										<React.Fragment key={column.id}>
											{((column.id === 'actions' ||
												column.id === 'tags' ||
												column.id === 'languages') && (
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													<span className="h-4">{column.render('Header')}</span>
												</th>
											)) || (
												<th
													onClick={() => setOrderSearchParam(column)}
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-blue-700 cursor-pointer"
												>
													<span className="flex items-center">
														<span className="h-4">
															{column.render('Header')}
														</span>

														{((ordering === column.id ||
															ordering === `-${column.id}`) && (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgChevron
																	className={`w-3 h-3 ${
																		ordering === column.id
																			? 'rotate-90'
																			: '-rotate-90'
																	}`}
																/>
															</span>
														)) || (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgMinus className="w-3 h-3" />
															</span>
														)}
													</span>
												</th>
											)}
										</React.Fragment>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={classnames('cursor-pointer hover:bg-blue-50', {
										'bg-gray-50': i % 2 === 0,
										'bg-transparent': i % 2 !== 0
									})}
								>
									{row.cells.map(cell => {
										return (
											<td
												{...cell.getCellProps()}
												className="whitespace-nowrap text-sm p-0"
											>
												{(cell.column.id === 'actions' && (
													<span className="px-6 py-4 block">
														{cell.render('Cell')}
													</span>
												)) || (
													<Link
														aria-label={row.values.survey_name}
														className="px-6 py-4 block whitespace-normal line-clamp-2"
														to={row.values.survey_status.includes('processing')?'':row.values.href}
														title={
															cell.column.id === 'survey_name' ? cell.value : ''
														}
													>
														<span
															className={classnames('', {
																'text-xs capitalize px-2.5 py-1 rounded-full':
																	cell.column.id === 'survey_status',
																'bg-red-100 text-red-900':
																	cell.value === 'closed',
																'bg-green-100 text-green-900':
																	cell.value === 'open',
																'bg-yellow-100 text-yellow-900':
																	cell.value === 'draft'
															})}
														>
															{cell.column.id === 'submissions_count' &&
															row.values.survey_status === 'draft' &&
															row.values.submissions_count === 0
																? '-'
																: cell.render('Cell')}
														</span>
													</Link>
												)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{/* PAGINATION */}
			<div className="flex items-center justify-center relative mt-10">
				<div className="flex items-center justify-center gap-4">
					<button
						aria-label="previous page"
						disabled={page === 1}
						onClick={() => {
							updateSearchParams({
								page: page - 1 !== 1 ? String(page - 1) : undefined
							});
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === 1
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4 rotate-180" />
					</button>

					<p
						className={`h-10 w-20 px-4 flex items-center justify-center bg-white rounded-lg text-sm text-gray-900 border border-gray-300 shadow-sm text-right`}
					>
						<span>
							{page} / {pageCount}
						</span>
					</p>

					<button
						aria-label="next page"
						disabled={page === pageCount}
						onClick={() => {
							updateSearchParams({ page: String(page + 1) });
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === pageCount
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4" />
					</button>
				</div>

				<CustomSelect
					name="page_size"
					options={[
						{
							value: 10,
							display: '10'
						},
						{
							value: 25,
							display: '25'
						},
						{
							value: 40,
							display: '40'
						}
					]}
					initialValue={{
						value: page_size,
						display: String(page_size)
					}}
					onChange={e => {
						updateSearchParams({
							page_size:
								e.value === initialPerPage ? undefined : String(e.value),
							page: undefined
						});
					}}
					className="absolute right-0 top-1/2 -translate-y-1/2"
				/>
			</div>
		</>
	);
};
