import React from 'react';
import { useLanguageContext } from 'context';

import { Button, Modal } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { getLanguageFlag } from 'components/shared/helpers';

export interface IEditAllTranslationsModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
	item: Record<string, string>;
  itemType: string;	

}

export const EditAllTranslationsModal: React.FC<IEditAllTranslationsModalProps> = ({
	isOpen,
	onRequestClose,
	item,
    itemType
}) => {
	const { availableLanguages } = useLanguageContext();

	const getEditableItem = (lang: string) => {

			return (
				<FieldInput
					className="flex-1"
					name={`${itemType}.${lang}`}
					label={
						<span className="flex items-center">
							<span className="mr-2">{getLanguageFlag(lang)}</span>
						</span>
					}
					value={item[lang]}
					type="text"
				/>
			);
		
	};

	return (
		<Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			headerText={`Edit ${itemType}`}
		>
			<div className="flex flex-col pb-6 space-y-6">
				{availableLanguages.map((lang, index) => {
					return (
						<React.Fragment key={index}>{getEditableItem(lang)}</React.Fragment>
					);
				})}

				<Button
					text="Save"
					type="button"
					size="small"
					className="self-start"
					onClick={onRequestClose}
				/>
			</div>
		</Modal>
	);
};
