import React from 'react';
import { Listbox } from '@headlessui/react';
import { InputFeedback } from 'components/form-elements';
import { ReactComponent as SvgDropdown } from 'assets/icons/chevron.svg';
import classNames from 'classnames';

export type IOption = {
	value: string | number | null | undefined;
	display?: string | JSX.Element;
	type?: string;
};

export interface ICustomSelectProps
	extends Omit<
		React.DetailedHTMLProps<
			React.SelectHTMLAttributes<HTMLSelectElement>,
			HTMLSelectElement
		>,
		'onChange' | 'onBlur' | 'onFocus' | 'value'
	> {
	name: string;
	label?: string;
	options: IOption[];
	value?: IOption;
	error?: string;
	className?: string;
	disabled?: boolean;
	onChange?: (value: IOption) => void;
	onBlur?: (e: React.FocusEvent<HTMLButtonElement>) => void;
	onFocus?: (e: React.FocusEvent<HTMLButtonElement>) => void;
	initialValue?: IOption;
	featuredChoices?: string[];
}

export const CustomSelect: React.FC<ICustomSelectProps> = ({
	name,
	label,
	options,
	value,
	error,
	className,
	disabled,
	onChange,
	onBlur,
	onFocus,
	initialValue = options[0],
	featuredChoices
}) => {
	const [selected, setSelected] = React.useState<IOption>(initialValue);

	const handleOnChange = (value: IOption) => {
		setSelected(value);
		onChange && onChange(value);
	};

	const handleOnFocus = (e: React.FocusEvent<HTMLButtonElement>) => {
		onFocus && onFocus(e);
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLButtonElement>) => {
		onBlur && onBlur(e);
	};

	React.useEffect(() => {
		setSelected(value || initialValue);
	}, [options]);

	return (
		<div
			className={`${className || ''} ${
				disabled ? 'text-gray-400 pointer-events-none opacity-50' : ''
			}`}
		>
			<Listbox value={value} onChange={handleOnChange}>
				{label && (
					<Listbox.Label className="block text-sm font-medium text-gray-700 mb-1">
						{label}
					</Listbox.Label>
				)}

				<div className="relative">
					<Listbox.Button
						onFocus={handleOnFocus}
						onBlur={handleOnBlur}
						className={`flex items-center ${
							error
								? 'border-red-500 focus:border-red-500 focus:ring-red-500'
								: 'border-gray-300 focus:ring-blue-700 focus:border-blue-700'
						} relative w-full bg-white border rounded-md shadow-sm pl-3 pr-8 h-10 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm`}
						tabIndex={disabled ? -1 : 0}
					>
						<span className="w-full inline-flex truncate">
							<span className="truncate">{selected?.display}</span>
						</span>

						<SvgDropdown
							className="absolute h-5 w-5 top-1/2 transform -translate-y-1/2 right-2 text-gray-400 rotate-90"
							aria-hidden="true"
						/>
					</Listbox.Button>
					<Listbox.Options className="absolute z-10 mt-1 bg-white shadow-lg max-h-60 min-w-full rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
						{options.filter(option =>
							featuredChoices?.includes(String(option.value))
						).length > 0 &&
							featuredChoices && (
								<span className="block text-gray-500 uppercase text-xs ml-3 my-2">
									Analytic questions
								</span>
							)}
						{options
							.filter(option => featuredChoices?.includes(String(option.value)))
							.map(option => (
								<Listbox.Option
									key={option.value}
									value={option}
									title={'This question type can be included in analytics'}
									className={classNames(
										'truncate flex items-center cursor-default select-none relative py-2 pl-4 pr-9 hover:bg-blue-50',
										{
											'font-semibold': option.value == selected.value
										}
									)}
								>
									{option.display}
								</Listbox.Option>
							))}

						{options.filter(
							option => !featuredChoices?.includes(String(option.value))
						).length > 0 &&
							featuredChoices && (
								<span className="block text-gray-500 uppercase text-xs ml-3 my-2">
									Non-analytic questions
								</span>
							)}

						{options
							.filter(
								option => !featuredChoices?.includes(String(option.value))
							)
							.map(option => (
								<Listbox.Option
									key={option.value}
									value={option}
									className={classNames(
										'truncate flex items-center cursor-default select-none relative py-2 pl-4 pr-9 hover:bg-blue-50',
										{
											'font-semibold': option.value == selected.value
										}
									)}
								>
									{option.display}
								</Listbox.Option>
							))}
					</Listbox.Options>
				</div>
			</Listbox>
			{error && <InputFeedback name={name} error={error} />}
		</div>
	);
};
