import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { useAlertDispatchContext } from 'context';
import { useSearchParamsWithHelpers, useToast } from 'hooks';
import { alertShowAction } from 'reducers';
import {
	deleteEmailTemplate,
	IEmailTemplate,
	useGetEmailTemplatesQuery
} from 'services/email-templates';
import { useGetEmailByIdQuery } from 'services/emails';
import { useGetAvailableSurveysQuery } from 'services/surveys';
import { useGetQuotaQuery } from 'services/subscriptions';

import { Modal, Button, Loader } from 'components/common';
import { CustomSelect, IOption } from 'components/form-elements';
import {
	MailCreateNewForm,
	MailCreateMultipleForm,
	MailTable,
	MailBatchTable,
	EmailTemplateTable,
	EmailTemplateModal
} from 'components/mail';
import { Layout } from 'components/shared';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import HelpInfo from 'components/common/helpInfo/HelpInfo';

/* const emailSortingChoices = [
		created_by,
		created_by_id,
		created_date,
		created_on,
		email,
		emails_numb,
		id,
		name,
		public_link,
		public_link_id,
		role,
		surname,
		survey_id,
		survey_id_id,
		survey_name,
		unique,
		updated_by,
		updated_by_id,
		updated_on
	] */

const filterOptions = [
	{
		value: 'ALL',
		display: 'All'
	},
	{
		value: 'DELIVERED',
		display: 'Delivered'
	},
	{
		value: 'NOT_DELIVERED',
		display: 'Not delivered'
	},
	{
		value: 'PROCESSED',
		display: 'Processed'
	},
	{
		value: 'UNSUBSCRIBED',
		display: 'Unsubscribed'
	},
	{
		value: 'PENDING',
		display: 'Pending'
	}
];

const Emails = () => {
	const show = useToast();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const alertDispatch = useAlertDispatchContext();

	const { updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const initialTab = 0;
	const tab = getSearchParamWithInitial('tab', initialTab);

	const setTab = (index: number) =>
		updateSearchParams({
			tab: initialTab === index ? undefined : String(index)
		});

	const [mailFilter, setMailFilter] = React.useState<IOption>(filterOptions[0]);
	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	const [templateModal, setTemplateModal] = React.useState({
		isOpen: false,
		template: null
	});
	const [mailBatchModal, setMailBatchModal] = React.useState({
		isOpen: false,
		mail: 0
	});

	const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();
	const { data: email } = useGetEmailByIdQuery(mailBatchModal.mail);

	const { data: surveys, isLoading: isLoadingSurveys } =
		useGetAvailableSurveysQuery({
			refetchOnWindowFocus: false
		});

	const { data: emailTemplates, isLoading: isLoadingEmailTemplates } =
		useGetEmailTemplatesQuery();

	const previewHandler = (template: any) => {
		setTemplateModal({ isOpen: true, template: template });
	};
	const editHandler = (template: IEmailTemplate) => {
		navigate(`/emails/${template.id}`);
	};
	const deleteHandler = (template: IEmailTemplate) => {
		alertDispatch(
			alertShowAction({
				header: `Delete email template?`,
				message: `Are you sure you want to delete ${template.template_name}?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Delete`,
						handler: () =>
							deleteEmailTemplate(String(template.id))
								.then(() => {
									queryClient.invalidateQueries('email_templates');
									show({
										type: 'success',
										headerText: 'Deleted!',
										message: 'Successfully deleted email template'
									});
								})
								.catch(() => {
									show({
										type: 'error',
										headerText: 'Error!',
										message: 'Something went wrong'
									});
								})
					}
				]
			})
		);
	};

	if (isLoadingSurveys || isLoadingEmailTemplates || isLoadingQuota) {
		return (
			<Layout pageTitle={'Emails'}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!surveys || !emailTemplates || !quota) {
		return (
			<Layout pageTitle="Emails">
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">Something went wrong</p>
				</div>
			</Layout>
		);
	}

	return (
		<Layout
			pageTitle={tab === 0 ? 'Emails sent' : 'Templates'}
			headerComponent={
				(tab === 0 && (
					<div className="flex items-center">
						{(surveys.length === 0 || emailTemplates.length === 0) && (
							<p className="flex items-center text-xs text-orange-500 mr-8">
								<SvgWarning className="w-4 h-4 mr-2" />
								{(surveys.length === 0 && (
									<span>
										You need to have at least 1 open survey to send a new email
									</span>
								)) ||
									(emailTemplates.length === 0 && (
										<span>
											To send a new email first create an email template
										</span>
									))}
							</p>
						)}

						<Button
							text="New email"
							size="small"
							iconLeft={<SvgPlus />}
							onClick={() => setModalIsOpen(true)}
							disabled={emailTemplates?.length === 0 || surveys.length === 0}
						/>
					</div>
				)) || (
					<div className="flex items-center">
						{emailTemplates &&
							emailTemplates?.length >= quota?.n_email_templates && (
								<p className="flex items-center text-xs text-orange-500 mr-8">
									<SvgWarning className="w-4 h-4 mr-2" />
									<span>
										You have reached the maximum number of email templates
									</span>
								</p>
							)}

						<Button
							text="New email template"
							size="small"
							iconLeft={<SvgPlus />}
							href="/emails/new-email-template"
							disabled={emailTemplates.length >= quota?.n_email_templates}
						/>
					</div>
				)
			}
		>
			<Tabs selectedIndex={tab} onSelect={setTab}>
				<TabList>
					<Tab>Emails</Tab>
					<Tab>Templates</Tab>
				</TabList>

				<TabPanel>
					<HelpInfo 
						message='Use emails for sending your surveys to users'
						icon={true}
						hoverMessageFirstLine='Emails are one of the existing methods for <strong>survey distribution</strong>. Utilize this emails section to send surveys directly through the app to one or multiple recipients.'
						hoverMessageSecondLine="Surveys sent via Email can be flagged to allow users to either fill it out once or multiple times, providing flexibility in data collection."
					/>
					<MailTable
						onRowClick={id => setMailBatchModal({ isOpen: true, mail: id })}
					/>
				</TabPanel>
				<TabPanel>
					<HelpInfo 
						message='Create new templates for sending your emails'
						icon={true}
						hoverMessageFirstLine='An <strong>email template</strong> is a pre-designed format for composing emails, featuring customizable elements such as text and images, making it easier to create polished and uniform messages.'
						hoverMessageSecondLine="To send emails through the HI app, it's <strong>necessary to have at least one email template set up</strong>."
					/>
					<EmailTemplateTable
						onDelete={template => {
							deleteHandler(template);
						}}
						onEdit={template => {
							editHandler(template);
						}}
						onPreview={template => {
							previewHandler(template);
						}}
					/>
				</TabPanel>
			</Tabs>

			<Modal
				headerText="Create new email"
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				fullScreen
			>
				<Tabs>
					<TabList>
						<Tab>Single user</Tab>
						<Tab>Multiple users</Tab>
					</TabList>

					<TabPanel>
						<MailCreateNewForm
							onClose={() => setModalIsOpen(false)}
							surveys={surveys}
							emailTemplates={emailTemplates}
						/>
					</TabPanel>
					<TabPanel>
						<MailCreateMultipleForm
							onClose={() => setModalIsOpen(false)}
							surveys={surveys}
							emailTemplates={emailTemplates}
						/>
					</TabPanel>
				</Tabs>
			</Modal>

			<Modal
				headerText="Email delivery status"
				isOpen={email !== undefined && mailBatchModal.isOpen}
				onRequestClose={() => {
					setMailBatchModal({ isOpen: false, mail: 0 });
					setMailFilter({ value: 'ALL', display: 'All' });
				}}
				className="min-h-[500px]"
			>
				<CustomSelect
					id="status_filter"
					name="status_filter"
					options={filterOptions}
					initialValue={mailFilter}
					onChange={e => {
						setMailFilter(e);
					}}
					className="mt-1 w-1/3"
				/>

				<MailBatchTable mailBatch={mailBatchModal.mail} filter={mailFilter} />
			</Modal>

			{templateModal.template !== null && (
				<EmailTemplateModal
					template={templateModal.template}
					isOpen={templateModal.isOpen}
					onClose={() =>
						setTemplateModal({
							isOpen: false,
							template: null
						})
					}
				/>
			)}
		</Layout>
	);
};

export default Emails;
