import React from 'react';
import { IModalProps, Modal } from 'components/common';

export interface IModalAlertProps extends IModalProps {
	title: string;
	message?: string;
}

export const ModalAlert: React.FC<IModalAlertProps> = ({
	title,
	isOpen,
	onRequestClose,
	message,
	buttons = []
}) => {
	return (
		<Modal
			headerText={title}
			buttons={buttons}
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			hideClose
		>
			{message && (
				<div data-testid="modal_alert" className="mb-7 text-center">
					{message}
				</div>
			)}
		</Modal>
	);
};
