import { Modal } from 'components/common';
import React from 'react';
import { IEmailTemplate } from 'services/email-templates';

export interface IEmailTemplateModalProps {
	template: IEmailTemplate;
	isOpen: boolean;
	onClose: () => void;
}

export const EmailTemplateModal: React.FC<IEmailTemplateModalProps> = ({
	template,
	isOpen,
	onClose
}) => {
	const iframeRef = React.useRef() as React.MutableRefObject<any>;

	const [height, setHeight] = React.useState<number>(0);

	const onLoad = () => {
		setHeight(iframeRef.current.contentWindow.document.body.scrollHeight + 60);
	};

	if (!template) {
		return null;
	}
	return (
		<Modal
			isOpen={isOpen}
			headerText={template.template_name}
			onRequestClose={() => onClose()}
		>
			<iframe
				ref={iframeRef}
				srcDoc={template.html_content}
				width="100%"
				height={`${height}px`}
				onLoad={onLoad}
			/>
		</Modal>
	);
};
