import { QueryClient } from 'react-query';

export const configReactQuery = (): QueryClient => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				// query options
				retry: 0
			},
			mutations: {
				// mutation options
				retry: 0
			}
		}
	});
	return queryClient;
};
