import { LoginForm } from 'components/user';
import { ReactComponent as SvgLogo } from 'assets/icons/logo-full.svg';

const Login = () => {
	return (
		<div className="w-full min-h-screen flex flex-col items-center justify-between">
			<div className="w-full flex flex-col max-w-sm mt-20">
				<SvgLogo className="svg-no-prefill w-4/5 mb-10" />

				<LoginForm />
			</div>
			<span className="text-gray-500 text-sm text-center my-8">
				2022. Megatrend poslovna rješenja d.o.o.
			</span>
		</div>
	);
};

export default Login;
