import React from 'react';
import { useReducerWithLogger } from 'hooks';
import { languageReducer } from 'reducers';

export interface ILanguageState {
	currentLanguage: string;
	availableLanguages: string[];
	defaultLanguage: string;
}

export const initialLanguageState: ILanguageState = {
	currentLanguage: 'hr',
	availableLanguages: ['hr'],
	defaultLanguage: 'hr'
};

export const LanguageContext =
	React.createContext<ILanguageState>(initialLanguageState);
export const LanguageDispatchContext = React.createContext<any>({});

export const LanguageProvider: React.FC<any> = ({ children }) => {
	const [state, dispatch] = useReducerWithLogger(
		languageReducer,
		initialLanguageState,
		true
	);
	return (
		<LanguageContext.Provider value={{ ...state }}>
			<LanguageDispatchContext.Provider value={dispatch}>
				{children}
			</LanguageDispatchContext.Provider>
		</LanguageContext.Provider>
	);
};

export const useLanguageContext = () => {
	const context = React.useContext(LanguageContext);
	if (context === undefined) {
		throw new Error('useLanguageContext must be used within a LanguageContext');
	}
	return context;
};

export const useLanguageDispatchContext = () => {
	const context = React.useContext(LanguageDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useLanguageDispatchContext must be used within a LanguageDispatchContext'
		);
	}
	return context;
};
