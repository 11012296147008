import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useQueryClient } from 'react-query';
import { userLogout, userRefreshToken, getUserState } from 'services/auth';
import {
	useUserDispatchContext,
	useUserStateContext
} from 'context/UserContext';

let requestID: any;
let responseID: any;
let isRefreshing = false;

export const useAxiosInterceptors = () => {
	const userDispatch = useUserDispatchContext();
	const queryClient = useQueryClient();
	const { token } = useUserStateContext();

	axios.defaults.params = {};
	clearInterceptors();

	const onRequestFulfilled = async (
		config: AxiosRequestConfig
	): Promise<AxiosRequestConfig> => {
		const authUrls = /token/i;
		config.params = { ...config.params };
		config.headers = {
			...config.headers
		};
		if (token && !authUrls.test(config.url || '')) {
			config.headers['Authorization'] = `Bearer ${token}`;
		}
		return Promise.resolve(config);
	};

	const onRequestRejected = (e: any) => {
		return Promise.reject(e);
	};

	requestID = axios.interceptors.request.use(
		onRequestFulfilled,
		onRequestRejected
	);

	const onResponseFulfilled = (response: AxiosResponse<any, any>) => {
		return Promise.resolve(response);
	};

	const onResponseRejected = async (error: any) => {
		const { config, response } = error;
		const status = response?.status;
		const { refresh_token } = getUserState();

		const authUrls = /token/i;

		if (status === 401 && !authUrls.test(config.url || '')) {
			if (!refresh_token) {
				userLogout(userDispatch);
			} else if (!isRefreshing) {
				isRefreshing = true;
				userRefreshToken({ refresh: refresh_token }, userDispatch)
					.then(() => {
						queryClient.invalidateQueries();
						setTimeout(() => {
							isRefreshing = false;
						}, 10000);
					})
					.catch(() => {
						userLogout(userDispatch);
						isRefreshing = false;
					});
			}
		}
		return Promise.reject(error);
	};

	responseID = axios.interceptors.response.use(
		onResponseFulfilled,
		onResponseRejected
	);
};

const clearInterceptors = () => {
	if (requestID >= -1) {
		axios.interceptors.request.eject(requestID);
	}

	if (responseID >= -1) {
		axios.interceptors.response.eject(responseID);
	}
};
