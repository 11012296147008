import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { capitalize } from 'utils';
import {
	deleteUser,
	resendRegistrationEmail,
	useGetUserInfoQuery,
	useGetUsersQuery
} from 'services/users';

import { Button, Loader, Modal } from 'components/common';
import { Layout, ManageTagsForm } from 'components/shared';
import {
	CreateNewUserForm,
	EditClientForm,
	EditPasswordForm,
	EditProfileForm,
	EditUserForm,
	UsersTable
} from 'components/user';

import { useSearchParamsWithHelpers, useToast } from 'hooks';
import { useQueryClient } from 'react-query';
import { alertShowAction } from 'reducers';
import { useAlertDispatchContext } from 'context';
import { useGetQuotaQuery } from 'services/subscriptions';

import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { deleteProperty, useGetPropertyTypes } from 'services/properties';
import { PropertiesTable } from 'components/property/PropertiesTable';
import { CreateNewPropertyForm } from 'components/property/CreateNewPropertyForm';
import { EditPropertyForm } from 'components/property/EditPropertyForm';
import HelpInfo from 'components/common/helpInfo/HelpInfo';

const Profile = () => {
	const show = useToast();
	const queryClient = useQueryClient();
	const alertDispatch = useAlertDispatchContext();

	const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();

	const { data: users } = useGetUsersQuery(
		{},
		{
			refetchOnWindowFocus: false
		}
	);

	const [editUserModal, setEditUserModal] = React.useState<Record<string, any>>(
		{
			isOpen: false,
			user: undefined
		}
	);
		

	const [editPropertyModal, setEditPropertyModal] = React.useState<Record<string, any>>(
		{
			isOpen: false,
			property: undefined
		}
	);

	const {data: propertyTypes, isLoading: isLoadingPropertyTypes} = useGetPropertyTypes();

	const [modalIsOpen, setModalIsOpen] = React.useState(false);

	const [newPropertyModalIsOpen, setNewPropertyModalIsOpen] = React.useState(false);

	const { updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const initialTab = 0;
	const tab = getSearchParamWithInitial('tab', initialTab);

	const setTab = (index: number) =>
		updateSearchParams({
			tab: initialTab === index ? undefined : String(index)
		});

	const {
		data: user,
		isLoading: isLoadingUser,
		isFetching: isFetchingUser,
		refetch: refetchUserInfo
	} = useGetUserInfoQuery();

	if (isLoadingUser || isLoadingQuota || isLoadingPropertyTypes) {
		return (
			<Layout pageTitle="">
				<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
			</Layout>
		);
	}

	if ((!user && !isLoadingUser) || !quota) {
		return <Layout pageTitle=""></Layout>;
	}

	return (
		<Layout
			pageTitle={
				<div className="flex items-center min-h-10">
					{(user.username && !isFetchingUser && capitalize(user.username)) ||
						'Settings'}
					{user.is_admin && !isFetchingUser && (
						<span className="bg-green-100 text-green-900 text-sm capitalize px-3 py-1 rounded-full ml-3">
							admin
						</span>
					)}
				</div>
			}
			headerComponent={
				(tab === 3 && user.is_admin && users && quota && (
					<div className="flex items-center">
						{users.count + 1 >= quota?.n_users && (
							<p className="flex items-center text-xs text-orange-500 mr-8">
								<SvgWarning className="w-4 h-4 mr-2" />

								<span>You have reached the maximum number of users</span>
							</p>
						)}

						<Button
							text="New user"
							size="small"
							iconLeft={<SvgPlus />}
							onClick={() => setModalIsOpen(true)}
							disabled={users.count + 1 >= quota?.n_users}
						/>
					</div>
				)) || 
				(tab === 4 && user.is_admin && quota && ( //&& properties
					<div className="flex items-center">
						

						<Button
							text="New property"
							size="small"
							iconLeft={<SvgPlus />}
							onClick={() => setNewPropertyModalIsOpen(true)}
						/>
					</div>
				))
				|| <></>
			}
		>
			<Tabs selectedIndex={tab} onSelect={setTab}>
				<TabList>
					<Tab>Profile</Tab>
					<Tab>Security</Tab>
					{user.is_admin && (
						<>
							<Tab>General</Tab>
							<Tab>Users</Tab>
							<Tab>Properties</Tab>
						</>
					)}
					<Tab>Tags</Tab>
					
				</TabList>

				<TabPanel>
					<HelpInfo 
						message='Use this page to update your profile information'
						icon={false}
					/>
					{(isLoadingUser || isFetchingUser) && <Loader className="mt-20" />}
					{user && !isFetchingUser && (
						<EditProfileForm
							onSubmit={() => {
								setTimeout(() => {
									refetchUserInfo();
								}, 1000);
							}}
							user={user}
							className="max-w-md"
						/>
					)}
				</TabPanel>

				<TabPanel>
					<HelpInfo 
						message='Use this form to reset your password'
						icon={false}
					/>
					{isLoadingUser && <Loader className="mt-20" />}
					{user && <EditPasswordForm className="max-w-md" />}
				</TabPanel>

				{user.is_admin && (
					<>
						<TabPanel>
							<EditClientForm />
						</TabPanel>

						<TabPanel>
							<HelpInfo 
								message='Users are you colleagues who can access this application'
								icon={true}
								hoverMessageFirstLine='Admin users have the ability to <strong>add other users</strong> to the app. Remember to share the access link with them, as their registration link is valid for the first login only.'
							/>
							<UsersTable
								onDelete={user => {
									alertDispatch(
										alertShowAction({
											header: `Delete`,
											message: `Are you sure you want to delete user ${user.first_name}?`,

											buttons: [
												{
													text: `Delete`,
													handler: () => {
														if (user.id) {
															deleteUser(user.id).then(() => {
																queryClient.invalidateQueries('users');
																show({
																	type: 'success',
																	headerText: 'Deleted!',
																	message: 'Successfully deleted user.'
																});
															});
														}
													},
													type: 'light'
												},
												{
													text: `Cancel`,
													handler: () => null
												}
											]
										})
									);
								}}
								onEdit={user => {
									if (user.id) {
										setEditUserModal({ isOpen: true, user: user });
									}
								}}
								onResendEmail={user => {
									if (user.id) {
										resendRegistrationEmail({ id: user.id }).then(() => {
											show({
												type: 'success',
												headerText: 'Sent!',
												message: 'Successfully sent another registration email.'
											});
										});
									}
								}}
							/>
						</TabPanel>
					</>
				)}
				<TabPanel>
					<HelpInfo 
						message='Properties are mapped with your business entities'
						icon={true}
						hoverMessageFirstLine="Properties symbolize your <strong>business entities</strong> like hotels, restaurants, or bars. By defining all your existing properties, you can conveniently <strong>analyze submission data based on the chosen property</strong> within the app's analytics panel."

					/>
					<PropertiesTable
								onDelete={property => {
									alertDispatch(
										alertShowAction({
											header: `Delete`,
											message: `Are you sure you want to delete property ${property.name}?`,

											buttons: [
												{
													text: `Delete`,
													handler: () => {
														if (property.property_id) {
															deleteProperty(property.property_id).then(() => {
																queryClient.invalidateQueries('properties');
																show({
																	type: 'success',
																	headerText: 'Deleted!',
																	message: 'Successfully deleted property.'
																});
															})
															.catch(error => {														
																show({
																	type: 'error',
																	headerText: 'Error deleting property!',
																	message: error.response.data
																});
															});
														}
													},
													type: 'light'
												},
												{
													text: `Cancel`,
													handler: () => null
												}
											]
										})
									);
								}}
								onEdit={property => {
									if (property.property_id) {
										setEditPropertyModal({ isOpen: true, property: property });
									}
								}}
							/>
				</TabPanel>
				<TabPanel>
					<HelpInfo 
						message='Tags help you filter created surveys'
						icon={true}
						hoverMessageFirstLine="<strong>Survey tags</strong> allow you to easily <strong>organize and categorize</strong> your surveys, streamlining the process of filtering and accessing relevant surveys within the app."
						hoverMessageSecondLine='You can <strong>create new tags effortlessly</strong> at any moment, including during the survey creation process.'
					/>
					<ManageTagsForm className="max-w-lg" />
				</TabPanel>
			</Tabs>

			<Modal
				headerText="Create new user"
				isOpen={modalIsOpen}
				onRequestClose={() => setModalIsOpen(false)}
				fullScreen
			>
				<CreateNewUserForm
					onSuccess={() => {
						setModalIsOpen(false);
						queryClient.invalidateQueries('users');
					}}
				/>
			</Modal>

			{editUserModal.user && (
				<Modal
					headerText="Edit user"
					isOpen={editUserModal.isOpen}
					onRequestClose={() =>
						setEditUserModal({ isOpen: false, user: undefined })
					}
					fullScreen
				>
					<EditUserForm
						user={editUserModal.user}
						onSuccess={() => {
							setEditUserModal({ isOpen: false, user: undefined });
							queryClient.invalidateQueries('users');
						}}
					/>
				</Modal>
			)}

				<Modal
				headerText="Create new property"
				isOpen={newPropertyModalIsOpen}
				onRequestClose={() => setNewPropertyModalIsOpen(false)}
				fullScreen
			>
				<CreateNewPropertyForm
					propertyTypes={propertyTypes.results}
					onSuccess={() => {
						setNewPropertyModalIsOpen(false);
						queryClient.invalidateQueries('properties');
					}}
				/>
			</Modal>

			{editPropertyModal.property && (
				<Modal
					headerText="Edit property"
					isOpen={editPropertyModal.isOpen}
					onRequestClose={() =>
						setEditPropertyModal({ isOpen: false, property: undefined })
					}
					fullScreen
				>
					<EditPropertyForm
						property={editPropertyModal.property}
						propertyTypes={propertyTypes.results}
						onSuccess={() => {
							setEditPropertyModal({ isOpen: false, property: undefined });
							queryClient.invalidateQueries('properties');
						}}
					/>
				</Modal>
			)}
		</Layout>
	);
};

export default Profile;
