import axios from 'axios';
import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

export type IUseGetPropertyTypesOptions = UseQueryOptions<any, any, any>;

export const getPropertyTypes = async () => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/property_type/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting property types:`, error);
			return Promise.reject(error);
		});
};


export const useGetPropertyTypes = (
	queryOptions?: IUseGetPropertyTypesOptions
) => {
	return useQuery(
		['property_types',] as QueryKey,
		() => getPropertyTypes(),
		queryOptions
	);
};