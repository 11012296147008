import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useSearchParamsWithHelpers, useToast } from 'hooks';
import { IRegisterUser, registerUser } from 'services/users';

import { Button } from 'components/common';
import { FieldInput } from 'components/formik-elements';

import { ReactComponent as SvgLogo } from 'assets/icons/logo-full.svg';
import { useNavigate } from 'react-router-dom';

const Register = () => {
	const show = useToast();
	const navigate = useNavigate();
	const [isSending, setIsSending] = React.useState(false);

	const { searchParams } = useSearchParamsWithHelpers();
	const token = searchParams.get('token');
	const uidb64 = searchParams.get('uidb64');
	const tokenValid = searchParams.get('token_valid');
	const usernameRegex = /^[a-zA-Z0-9.@]+$/;
	const [backendError, setBackendError] = React.useState<Record<string, any>>(
		{}
	);

	const handleSubmit = (values: IRegisterUser) => {
		setIsSending(true);

		registerUser(values)
			.then(() => {
				setIsSending(false);
				navigate('/login');
				setTimeout(() => {
					show({
						type: 'success',
						headerText: 'Success!',
						message: 'Registration successful'
					});
				}, 1);
			})
			.catch(error => {
				if (error.response.data) {
					setBackendError(error.response.data);
				}
				setIsSending(false);
			});
	};

	return (
		<div className="w-full h-full min-h-screen flex items-center justify-center">
			<div className="flex flex-col w-full max-w-sm">
				<SvgLogo className="svg-no-prefill w-4/5 mb-10" />

				<Formik
					initialValues={{
						username: '',
						password: '',
						password2: '',
						token: token || '',
						uidb64: uidb64 || ''
					}}
					validationSchema={Yup.object().shape({
						username: Yup.string().required('This field is required').matches(usernameRegex,'Enter a valid username. This value may contain only letters, numbers, and "@","." characters.'),
						password: Yup.string()
							.min(8, 'Minimum 8 characters')
							.required('This field is required'),
						password2: Yup.string()
							.oneOf([Yup.ref('password'), null], 'Passwords do not match')
							.required('This field is required'),
						token: Yup.string()
							.matches(/^(\d|[a-zA-Z]){6}-(\d|[a-zA-Z]){32}$/, 'Invalid token')
							.required('Token non existent'),
						uidb64: Yup.string().required('Missing uidb64 key')
					})}
					onSubmit={handleSubmit}
				>
					{props => {
						const { errors, submitCount, isSubmitting } = props;

						if (errors.token && isSubmitting) {
							show({
								type: 'error',
								headerText: 'Token error!',
								message: errors.token
							});
						}

						if (errors.uidb64 && isSubmitting) {
							show({
								type: 'error',
								headerText: 'Error!',
								message: errors.uidb64
							});
						}

						if (tokenValid === 'False' && isSubmitting) {
							show({
								type: 'error',
								headerText: 'Invalid token!',
								message:
									'Token has probably expired, please contact your admin for a new registration email'
							});
						}

						return (
							<Form id="user-register-form" className="w-full login-form">
								<FieldInput
									name="username"
									id="username"
									label="Username"
									className="mb-6"
									error={
										submitCount > 0 &&
										((errors.username && errors.username) ||
											(backendError.username && backendError.username[0]) ||
											'')
									}
								/>
								<FieldInput
									name="password"
									id="password"
									label="Password"
									type="password"
									className="mb-6"
									error={
										submitCount > 0 &&
										((errors.password && errors.password) ||
											(backendError.password && backendError.password[0]) ||
											'')
									}
								/>
								<FieldInput
									name="password2"
									id="password2"
									label="Repeat password"
									type="password"
									className="mb-10"
									error={
										submitCount > 0 &&
										((errors.password2 && errors.password2) ||
											(backendError.password2 && backendError.password2[0]) ||
											'')
									}
								/>

								<Button
									text="Submit"
									type="submit"
									disabled={isSending}
									loading={isSending}
									className="w-full"
								/>
							</Form>
						);
					}}
				</Formik>
			</div>
		</div>
	);
};

export default Register;
