import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Button } from 'components/common/Button';
import {
	CustomInput,
	CustomMultiSelect,
	CustomSelect
} from 'components/form-elements';
import { Layout } from 'components/shared';
import { SurveyTableV2, SurveyCloneModal } from 'components/survey';

import { useSearchParamsWithHelpers, useToast } from 'hooks';
import { alertShowAction } from 'reducers';
import { useAlertDispatchContext } from 'context';
import { useGetQuotaQuery } from 'services/subscriptions';
import {
	deleteSurvey,
	publishSurvey,
	useGetAvailableSurveysQuery,
	postSurvey
} from 'services/surveys';
import { ISurvey, ISurveyStatus } from 'services/surveys/interfaces';
import { useGetTagsQuery } from 'services/tags';
import { capitalize } from 'utils';

import { ReactComponent as SvgPlus } from 'assets/icons/plus.svg';
import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';

import {
	useGetUserInfoQuery
} from 'services/users';
import { FileUploadComponent } from 'components/form-elements/CustomUploadInput';

const Surveys = () => {
	const show = useToast();
	const navigate = useNavigate();
	const alertDispatch = useAlertDispatchContext();
	const queryClient = useQueryClient();
	const { searchParams, updateSearchParams } = useSearchParamsWithHelpers();

	const { data: tags } = useGetTagsQuery();

	const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();
	const { data: totalOpen, isLoading: isLoadingTotalOpen } =
		useGetAvailableSurveysQuery();

	const disablePublish =
		!isLoadingQuota &&
		!isLoadingTotalOpen &&
		quota &&
		totalOpen &&
		totalOpen.length >= quota?.n_open_surveys;

	const [nameFilter, setNameFilter] = React.useState<string>();
	const [selectedTags, setSelectedTags] = React.useState<string>();

	const [copyModalShown, setCopyModalShown] = React.useState(false);
	const [surveyToCopy, setSurveyToCopy] = React.useState<ISurvey>();

	const publishHandler = (survey: ISurvey) => {
		alertDispatch(
			alertShowAction({
				header: `Publish`,
				message: `Are you sure you want to publish this survey?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Publish`,
						handler: () =>
							publishSurvey(String(survey?.survey_id))
								.then(() => {
									queryClient.invalidateQueries('surveys');
									show({
										type: 'success',
										headerText: 'Published!',
										message: 'Successfully published survey'
									});
									navigate(`/surveys`);
								})
								.catch(() => {
									show({
										type: 'error',
										headerText: 'Error!',
										message: 'Something went wrong'
									});
								})
					}
				]
			})
		);
	};

	const deleteHandler = (survey: ISurvey) => {
		alertDispatch(
			alertShowAction({
				header: `Delete survey?`,
				message: `Are you sure you want to delete ${survey.survey_name}?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Delete`,
						handler: () =>
							deleteSurvey(String(survey.survey_id))
								.then(() => {
									queryClient.invalidateQueries('surveys');
									show({
										type: 'success',
										headerText: 'Deleted!',
										message: 'Successfully deleted survey'
									});
								})
								.catch(() => {
									show({
										type: 'error',
										headerText: 'Error!',
										message: 'Something went wrong'
									});
								})
					}
				]
			})
		);
	};

	const editHandler = (survey: ISurvey) => {
		if (survey.survey_status === 'draft') {
			navigate(`/surveys/${survey.survey_id}?tab=0`);
		} else {
			navigate(`/surveys/${survey.survey_id}?tab=1`);
		}
	};

	const copyHandler = (survey: ISurvey) => {
		setCopyModalShown(true);
		setSurveyToCopy(survey);
	};

	const pdfHandler = (survey: ISurvey) => {
		navigate(`/surveys/${survey.survey_id}?tab=2`);
	};

	const {
		data: user,
	} = useGetUserInfoQuery();

	const handleFileUpload = async (content: string) => {
		try {
            const parsedContent: ISurvey = JSON.parse(content);
            await postSurvey(parsedContent);
			location.reload();
        } catch (error) {
            console.error('Error parsing JSON:', error);
        }
	};

	return (
		<Layout
			pageTitle="Surveys"
			headerComponent={
				<Button
					text="New survey"
					size="small"
					iconLeft={<SvgPlus />}
					href="/surveys/new-survey"
				/>
			}
			wider
		>
			{user?.is_superadmin && (
				<FileUploadComponent onFileUpload={handleFileUpload} />
			)}
			{tags && (
				<div className="flex items-center">
					<CustomInput
						name="name_filter"
						id="name_filter"
						label="Filter by name"
						onChange={e => {setNameFilter(e.target.value);updateSearchParams({ page:"1"});}}
						className="w-full max-w-xs mr-6"
					/>

					<CustomMultiSelect
						name="tags_filter"
						id="tags_filter"
						label="Filter by tags"
						options={tags.map(tag => {
							return {
								value: tag.tag_id,
								label: tag.name
							};
						})}
						onChange={e =>
							setSelectedTags(e.map(item => item.value).toString())
						}
						className="w-full max-w-xs mr-6"
						isMulti
					/>

					<CustomSelect
						name="state_filter"
						id="state_filter"
						label="Filter by status"
						initialValue={{
							value: searchParams.get('survey_status'),
							display: capitalize(searchParams.get('survey_status')?.replace("_success","") || 'All')
						}}
						options={[
							{
								value: undefined,
								display: 'All'
							},
							{
								value: 'open_success',
								display: 'Open'
							},
							{
								value: 'draft_success',
								display: 'Draft'
							},
							{
								value: 'closed_success',
								display: 'Closed'
							},
							{
								value: 'failed',
								display: 'Failed'
							},
							{
								value: 'processing',
								display: 'Processing'
							}
						]}
						onChange={e => {
							updateSearchParams({
								page: undefined,
								page_size: undefined,
								survey_status: e.value as ISurveyStatus
							});
						}}
						className="w-full max-w-xs"
					/>
				</div>
			)}

			{disablePublish && (
				<p className="flex justify-center items-center text-xs bg-yellow-300 mt-6 rounded-lg p-3 ">
					<SvgWarning className="w-4 h-4 mr-2" />

					<span>
						You have reached the maximum number of open surveys, you may create
						a new draft but you may not publish any more surveys.
					</span>
				</p>
			)}

			<SurveyTableV2
				name={nameFilter}
				tags={selectedTags}
				disablePublish={disablePublish}
				onPrint={pdfHandler}
				onDelete={deleteHandler}
				onEdit={editHandler}
				onCopy={copyHandler}
				onPublish={publishHandler}
			/>

			<SurveyCloneModal
				modalTitle="New survey name"
				survey={surveyToCopy as ISurvey}
				isOpen={copyModalShown}
				onClose={() => setCopyModalShown(false)}
			/>
		</Layout>
	);
};

export default Surveys;
