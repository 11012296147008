import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const getAnalyticsUrl = async () => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/get_embedding_URL/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting analytics url:`, error);
			return Promise.reject(error);
		});
};

export const useGetAnalyticsUrlQuery = (user: string) => {
	return useQuery(
		['analytics-url', user] as QueryKey,
		() => getAnalyticsUrl(),
		{
			refetchOnWindowFocus: true,
			refetchOnMount: 'always',
			refetchOnReconnect: 'always'
		}
	);
};
