import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { INotificationRule } from './interfaces';

export const getRuleById = async (id: string) => {
	return await axios
		.get<INotificationRule>(`${process.env.REACT_APP_API_URL}/rule/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting notification rule:`, error);
			return Promise.reject(error);
		});
};

export const useGetRuleByIdQuery = (id?: string) => {
	return useQuery(
		['notification_rule', id] as QueryKey,
		() =>
			id
				? getRuleById(id)
				: Promise.reject('Error in useGetRuleByIdQuery: No id provided.'),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};
