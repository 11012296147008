import React from 'react';
import {
	FieldArray,
	FieldArrayRenderProps,
	FormikErrors,
	FormikProps
} from 'formik';

import { Button } from 'components/common';
import { FieldSelect } from 'components/formik-elements';
import { useGetSurveyByIdQuery } from 'services/surveys';
import { IQuestion, ISurvey } from 'services/surveys/interfaces';
import {
	addNewQuestion,
	getAvailableSurveys,
	QuestionRuleQuestion,
	showAddNewQuestionButton
} from '..';
import { ReactComponent as SvgClose } from 'assets/icons/close.svg';
import { INotificationRule, IQuestionRule } from 'services/notifications';
import { useGetQuotaQuery } from 'services/subscriptions';

export interface IQuestionRuleItemProps {
	ruleIndex: number;
	surveys: ISurvey[];
	question_rule: IQuestionRule;
	questionRulesHelper: FieldArrayRenderProps;
	formProps: FormikProps<INotificationRule>;
}

export const QuestionRuleItem: React.FC<IQuestionRuleItemProps> = ({
	ruleIndex,
	surveys,
	question_rule,
	questionRulesHelper,
	formProps
}) => {
	const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();
	const { data: surveyData, isLoading: isLoadingSurveyData } =
		useGetSurveyByIdQuery(String(question_rule.survey_id));

	const allowedSurveyQuestions: IQuestion[] = React.useMemo(() => {
		return surveyData?.questions.filter((question: IQuestion) => {
			if (quota && quota?.sentiments === 'included') {
				return ['text', 'nps', 'rating', 'emoji_rating', 'select_one', 'select_multiple', 'integer', 'decimal', 'range', 'geopoint', 'date', 'image', 'email', 'country', 'age', 'sex', 'nps', 'rating'].includes(
					question.type
				);
			} else {
				return ['text', 'rating', 'emoji_rating', 'select_one', 'select_multiple', 'integer', 'decimal', 'range', 'geopoint', 'date', 'image', 'email', 'country', 'age', 'sex', 'nps', 'rating'].includes(question.type);
			}
		});
	}, [surveyData, quota]);

	if (isLoadingSurveyData || isLoadingQuota) {
		return (
			<div className="w-full aspect-[4/1] rounded-lg bg-gray-300 animate-pulse mb-4" />
		);
	}

	if (!surveyData || !quota) {
		return (
			<div className="flex items-center justify-center w-full aspect-[4/1] border rounded-lg border-gray-300 bg-gray-50 text-sm text-gray-500">
				<p>Something is not right</p>
			</div>
		);
	}

	return (
		<div
			className={`flex flex-col border rounded-lg p-4 space-y-4 ${
				formProps.errors?.question_rules?.[ruleIndex] &&
				formProps.errors?.question_rules?.[ruleIndex] !== undefined &&
				formProps.submitCount
					? 'border-red-300 bg-red-50'
					: 'border-gray-300 bg-gray-50'
			}`}
		>
			<div className="flex justify-between space-x-2">
				<FieldSelect
					name={`question_rules.${ruleIndex}.survey_id`}
					id={`question_rules.${ruleIndex}.survey_id`}
					label="Select survey"
					options={getAvailableSurveys(
						surveys,
						formProps.values.question_rules,
						question_rule
					)}
					onChange={() => {
						formProps.setFieldValue(`question_rules.${ruleIndex}.questions`, [
							{
								question_id: undefined,
								comparator: '',
								value: ''
							}
						]);
					}}
					error={
						(
							formProps.errors?.question_rules?.[
								ruleIndex
							] as FormikErrors<IQuestionRule>
						)?.survey_id && formProps.submitCount
							? (
									formProps.errors?.question_rules?.[
										ruleIndex
									] as FormikErrors<IQuestionRule>
							  )?.survey_id
							: ''
					}
				/>
				{formProps.values.question_rules.length > 1 && (
					<button
						type="button"
						onClick={() => questionRulesHelper.remove(ruleIndex)}
						className="flex-none w-8 h-8 p-2 mt-7 text-white text-lg flex justify-center items-center bg-blue-700 hover:bg-blue-600 rounded-full"
					>
						<SvgClose />
					</button>
				)}
			</div>

			<FieldArray
				key={`question_rules.${ruleIndex}.questions`}
				name={`question_rules.${ruleIndex}.questions`}
				render={questionsHelper => {
					return (
						<>
							{question_rule.questions?.map((question, questionIndex) => {
								return (
									<QuestionRuleQuestion
										key={questionIndex}
										questionOptions={allowedSurveyQuestions}
										questionRule={question_rule}
										ruleIndex={ruleIndex}
										question={question}
										questionIndex={questionIndex}
										formProps={formProps}
										questionsHelper={questionsHelper}
										surveyDefaultLanguage={surveyData.survey_default_lang}
									/>
								);
							})}

							{showAddNewQuestionButton(
								allowedSurveyQuestions,
								question_rule.questions
							) && (
								<Button
									type="button"
									text="Add question"
									size="small"
									variant="light"
									onClick={() => {
										addNewQuestion(
											allowedSurveyQuestions,
											question_rule.questions,
											questionsHelper
										);
									}}
									className="self-end"
								/>
							)}
						</>
					);
				}}
			/>
		</div>
	);
};
