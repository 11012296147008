import React from 'react';

import { InputFeedback } from '../InputFeedback';

import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';

export interface ICustomFileUpload
	extends React.InputHTMLAttributes<HTMLInputElement> {
	name: string;
	onUpload: (e: any) => void;
	onClear: (e: any) => void;
	hasValue?: any;
	noDelete?: boolean;
}
export const CustomFileUpload: React.FC<ICustomFileUpload> = ({
	id,
	name,
	className,
	accept = '.pdf,.doc,.docx,.zip,.rar,.tar,.jpg,.png,.bmp',
	onUpload,
	onClear,
	hasValue,
	noDelete
}) => {
	const uploadInputRef =
		React.useRef() as React.MutableRefObject<HTMLInputElement>;

	const [fileSizeError, setFileSizeError] = React.useState('');

	const validateFileInput = (file: any) => {
		setFileSizeError('');

		if (file.size > 1000000) {
			setFileSizeError('File is too big. Maximum size 1 MB.');
		}
	};

	const handleFileUpload = async (e: any) => {
		Array.from(e.target.files).map(file => {
			validateFileInput(file);
		});

		if (fileSizeError === '') {
			onUpload(e);
		}
	};

	const handleFileClear = (e: any) => {
		setFileSizeError('');
		onClear(e);
	};

	return (
		<div className={className || ''}>
			<input
				ref={uploadInputRef}
				id={id}
				name={name}
				type="file"
				hidden
				accept={accept}
				multiple={false}
				onInput={handleFileUpload}
				onClick={(e: any) => (e.target.value = null)}
			/>

			{(!hasValue && (
				<button
					className="w-56 h-24 border-2 border-dashed border-gray-300 rounded-xl flex items-center justify-center text-gray-300"
					type="button"
					onClick={() => uploadInputRef.current.click()}
				>
					Upload +
				</button>
			)) || (
				<div className="flex items-center">
					<button
						className="flex items-center justify-center w-10 h-10 hover:text-indigo-600 transition-all duration-75"
						type="button"
						onClick={() => uploadInputRef.current.click()}
					>
						<SvgEdit />
					</button>
					{!noDelete && (
						<button
							className="flex items-center justify-center w-10 h-10 hover:text-indigo-600 transition-all duration-75"
							type="button"
							onClick={handleFileClear}
						>
							<SvgTrash />
						</button>
					)}
				</div>
			)}

			{fileSizeError && <InputFeedback name={name} error={fileSizeError} />}
		</div>
	);
};
