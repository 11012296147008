import React from 'react';

export interface IPopoverOptionProps
	extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	label: string;
	className?: string;
	disabled?: boolean;
	onClick?: () => void;
}

export const PopoverOption: React.FC<IPopoverOptionProps> = ({
	label,
	className,
	disabled,
	...rest
}) => {
	return (
		<a
			tabIndex={disabled ? -1 : 0}
			className={`${
				className || ''
			} leading-loose py-0.5 px-4 block w-full cursor-pointer hover:bg-blue-50 focus:bg-blue-100 focus:outline-none ${
				disabled ? 'pointer-events-none text-gray-400' : ''
			}`}
			{...rest}
		>
			{label}
		</a>
	);
};
