import axios from "axios";
import { ISurveyQRCode } from "./interfaces";
import { QueryKey, useQuery } from "react-query";

export const getSurveyQRCode = async (id : string) => {
	return await axios
		.get<ISurveyQRCode>(`${process.env.REACT_APP_API_URL}/qr_code/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting qr code:`, error);
			return Promise.reject(error);
		});
};

export const useGetSurveyQRCodeQuery = (id?: string) => {
	return useQuery(
		['survey', id] as QueryKey,
		() =>
			id
				? getSurveyQRCode(id)
				: Promise.reject('Error in useGetSurveyQRCodeQuery: No id provided.'),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};