import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const getNotificationTypes = async () => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/notification_types/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting notification types:`, error);
			return Promise.reject(error);
		});
};

export const useGetNotificationTypesQuery = () => {
	return useQuery(
		['notification_types'] as QueryKey,
		() => getNotificationTypes(),
		{
			refetchOnWindowFocus: false
		}
	);
};
