import axios from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

export type IUseGetPropertiesOptions = UseQueryOptions<any, any, any>;



export const getProperties = async () => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/property_api/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting properties:`, error);
			return Promise.reject(error);
		});
};


export const useGetProperties = (
	queryOptions?: IUseGetPropertiesOptions
) => {
	return useQuery(
		['properties',] as QueryKey,
		() => getProperties(),
		queryOptions
	);
};