import React from 'react';

import { useCustomCheckboxGroupContext } from '.';
import { InputFeedback } from 'components/form-elements';

import { ReactComponent as SvgCheckmark } from 'assets/icons/check.svg';

export interface ICustomCheckboxProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	className?: string;
	name: string;
	label?: string;
	labelComponent?: JSX.Element;
	type?: 'checkbox';
	value?: any;
	error?: string;
	variant?: 'checkbox' | 'toggler';
	tabIndex?: number;
	boxSize?: 'sm' | 'lg';
}

export const CustomCheckbox: React.FC<ICustomCheckboxProps> = ({
	className,
	name,
	label,
	labelComponent,
	value,
	error,
	variant = 'checkbox',
	boxSize = 'sm',
	tabIndex = 0,
	onChange,
	...checkboxProps
}) => {
	const [singleChecked, setSingleChecked] = React.useState<boolean>(
		value || false
	);
	const [state, onGroupChange] = useCustomCheckboxGroupContext();

	const isChecked = state
		? state.some((item: any) => item === value)
		: singleChecked;

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSingleChecked(e.target.checked);
		onChange && onChange(e);
		onGroupChange(value);
	};
	const checkboxRef = React.createRef<HTMLInputElement>();

	/* const handleOnKeyDown = (e: any) => {
		if (e.key === 'Enter' || e.code === 'Enter') {
			setSingleChecked(!singleChecked);
		}
	}; */

	return (
		<>
			<label
				className={`
				flex items-center cursor-pointer group relative transition-all duration-150
				${className || ''} 
				${!checkboxProps.disabled ? 'cursor-pointer' : 'opacity-40'}`}
			>
				<span className="relative flex items-start">
					<input
						ref={checkboxRef}
						className="invisible w-0 h-0 border-0"
						id={name}
						type="checkbox"
						name={name}
						//defaultValue={value}
						value={isChecked as any}
						checked={value}
						onChange={e => handleOnChange(e)}
						{...checkboxProps}
					/>

					{(variant == 'checkbox' && (
						<span
							id={name}
							role="checkbox"
							aria-checked={value}
							onKeyDown={e => {
								if (checkboxRef.current && (e as any).code === 'Space') {
									e.preventDefault();
									checkboxRef.current.click();
								}
							}}
							tabIndex={tabIndex}
							className={`${
								boxSize === 'sm' ? 'w-4 h-4' : 'w-8 h-8'
							} overflow-hidden rounded-md transition duration-150 border relative ${
								isChecked
									? 'bg-blue-700 border-blue-700 group-hover:bg-blue-700 group-hover:border-blue-700'
									: 'border-gray-300 group-hover:border-blue-700 focus:border-blue-700'
							}`}
						>
							{isChecked && (
								<SvgCheckmark
									className={`absolute text-white transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 ${
										boxSize === 'sm' ? 'w-full h-full' : 'w-2/3 h-2/3'
									}`}
								/>
							)}
						</span>
					)) ||
						(variant == 'toggler' && (
							<span
								role="checkbox"
								aria-checked={value}
								onKeyDown={e => {
									if (checkboxRef.current && (e as any).code === 'Space') {
										e.preventDefault();
										checkboxRef.current.click();
									}
								}}
								tabIndex={tabIndex}
								className={`w-10 h-6 rounded-full transition-all duration-150 border-4 relative flex items-center ${
									isChecked
										? `bg-blue-700 border-blue-700`
										: 'border-gray-300 bg-gray-300'
								}`}
							>
								<span
									className={`block w-4 h-4 rounded-full bg-white transform transition-all duration-150 ${
										isChecked ? 'translate-x-full' : 'translate-x-0'
									}`}
								></span>
							</span>
						))}
				</span>

				{(label || labelComponent) && (
					<span className={`ml-2 block text-sm text-gray-900`}>
						{(label && label) || (labelComponent && labelComponent)}
					</span>
				)}
			</label>

			{error && <InputFeedback name={name} error={error} />}
		</>
	);
};
