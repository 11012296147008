import React from 'react';
import { ReactComponent as SvgLoader } from 'assets/icons/loader.svg';

export interface ILoaderProps {
	className?: string;
	size?: 'small' | 'default';
	color?: 'blue' | 'white';
}

export const Loader: React.FC<ILoaderProps> = ({
	className,
	size = 'default',
	color = 'blue'
}) => {
	return (
		<div className={`flex items-center justify-center ${className || ''}`}>
			<SvgLoader
				className={`${size === 'small' ? 'w-6' : 'w-12'} ${
					color === 'blue' ? 'text-blue-700' : 'text-white'
				} `}
			/>
		</div>
	);
};
