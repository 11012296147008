import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Loader } from 'components/common';
import {
	FieldCheckbox,
	FieldInput,
	FieldSelect
} from 'components/formik-elements';

import { postEmail } from 'services/emails';
import { IEmail } from 'services/emails/interfaces';
import { ISurvey } from 'services/surveys/interfaces';
import { useToast } from 'hooks';
import { useQueryClient } from 'react-query';
import { IEmailTemplate } from 'services/email-templates';
export interface IMailCreateNewFormProps {
	surveys: ISurvey[];
	emailTemplates: IEmailTemplate[];
	onClose: () => void;
}

export const MailCreateNewForm: React.FC<IMailCreateNewFormProps> = ({
	surveys,
	emailTemplates,
	onClose
}) => {
	const queryClient = useQueryClient();
	const show = useToast();
	const [isSending, setIsSending] = React.useState(false);

	const initialFormState: IEmail = {
		unique_link: true,
		template_id: 0,
		survey_id: 0,
		name: '',
		lastname: '',
		email: '',
		email_list: []
	};

	const surveyOptions = React.useMemo(
		() =>
			surveys.map(survey => {
				return {
					display: survey.survey_name,
					value: Number(survey.survey_id)
				};
			}),
		[]
	);
	const emailTemplateOptions = React.useMemo(
		() =>
			emailTemplates.map(emailTemplate => {
				return {
					display: emailTemplate.template_name,
					value: emailTemplate.id
				};
			}),
		[]
	);

	const singleMailValidationSchema = Yup.object().shape({
		survey_id: Yup.number()
			.min(1, 'This field is required')
			.required('This field is required'),
		template_id: Yup.number()
			.min(1, 'This field is required')
			.required('This field is required'),
		name: Yup.string(),
		lastname: Yup.string(),
		email: Yup.string()
			.email('Please enter a valid email')
			.required('This field is required'),
		email_list: Yup.array().of(
			Yup.object().shape({
				name: Yup.string(),
				lastname: Yup.string(),
				email: Yup.string()
					.email(`Please enter a valid email`)
					.required('Email is required')
			})
		)
	});

	const postProcessedEmail = (values: IEmail) => {
		return {
			unique_link: values.unique_link,
			survey_id: values.survey_id,
			template_id: values.template_id,
			email_list: [
				{
					name: values.name,
					lastname: values.lastname,
					email: values.email
				}
			]
		};
	};

	const handleSubmit = (values: IEmail) => {
		setIsSending(true);
		postEmail(postProcessedEmail(values)).then(() => {
			queryClient.invalidateQueries('emails');
			show({
				type: 'success',
				headerText: 'Sent!',
				message: 'Successfully sent email'
			});
			setIsSending(false);
		});
		onClose();
	};

	return (
		<>
			{isSending ? (
				<Loader className="pt-20" />
			) : (
				<Formik
					validationSchema={singleMailValidationSchema}
					initialValues={initialFormState}
					onSubmit={handleSubmit}
				>
					{props => {
						const { submitCount, errors } = props;

						return (
							<Form className="space-y-6" id="single-mail-form">
								<FieldInput
									className="md:w-2/3"
									name="name"
									label="First name"
									id="name"
									type="text"
								/>
								<FieldInput
									className="md:w-2/3"
									name="lastname"
									label="Last name"
									id="lastname"
									type="text"
								/>
								<FieldInput
									className="md:w-2/3"
									name="email"
									label="Email address*"
									id="email"
									type="email"
									autoComplete="email"
									error={submitCount && errors.email ? errors.email : ''}
								/>
								<FieldSelect
									className="md:w-2/3"
									name="template_id"
									label="Select email template*"
									id="template_id"
									options={emailTemplateOptions}
									error={
										submitCount && errors.template_id ? errors.template_id : ''
									}
								/>
								<FieldSelect
									className="md:w-2/3"
									name="survey_id"
									label="Select survey*"
									id="survey_id"
									options={surveyOptions}
									error={
										submitCount && errors.survey_id ? errors.survey_id : ''
									}
								/>
								<FieldCheckbox
									className="md:w-2/3"
									name="unique_link"
									label="User can fill out the survey only once."
									id="unique_link"
									type="checkbox"
								/>
								<Button
									type="submit"
									text="Send"
									size="small"
									rounded="medium"
								/>
							</Form>
						);
					}}
				</Formik>
			)}
		</>
	);
};
