import { useSearchParams } from 'react-router-dom';
import { filterUndefinedValues } from 'utils';

export const useSearchParamsWithHelpers = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const updateSearchParams = (params: Record<string, string | undefined>) => {
		setSearchParams(
			filterUndefinedValues({
				...Object.fromEntries(searchParams.entries()),
				...params
			})
		);
	};

	const getSearchParamWithInitial = <T extends string | number>(
		key: string,
		initial: T
	) => {
		const typeCastFn = typeof initial === 'string' ? String : Number;
		return typeCastFn(searchParams.get(key) || initial) as T extends string
			? string
			: number;
	};

	return {
		searchParams,
		setSearchParams,
		updateSearchParams,
		getSearchParamWithInitial
	};
};
