import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { useGetLanguagesQuery } from 'services/languages';
import { useLanguageContext, useLanguageDispatchContext } from 'context';
import { getLanguageFlag } from '../helpers';
import {setCurrentLanguage} from 'reducers';

import { Button, Modal } from 'components/common';
import {FieldSelect} from 'components/formik-elements';
import { translateSurvey } from 'services/surveys';
import {ITranslateSurvey, ISurveyUI, ITranslateSection, ITranslateQuestion, IQuestion } from 'services/surveys/interfaces';


import { SurveyContext } from 'context/SurveyContext';
import { useToast } from 'hooks';
import { useGetQuotaQuery } from 'services/subscriptions';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';

export interface ITranslateModalProps {
	isOpen: boolean;
	onRequestClose: () => void; 
}



const show = useToast();

const handleSubmit = (translateFrom : string, translateTo : string, survey: ISurveyUI, languageDispatch : any) => {
	
	const questionList:ITranslateQuestion[] = survey.general_questions.map((q,index) => { 		
		return {		
				label: q.label?q.label[translateFrom]:'',
				hint: q.hint?q.hint[translateFrom]:'',
				choices: q.choices?q.choices.map((c,index) => {return {
					label: c.label[translateFrom],
					choice_id: c.choice_id? c.choice_id:index.toString()
				}}): [],
				question_id : q.question_id?q.question_id:index //ako je pitanje novo i nema ID, dodjeli mu ID
	}});

	const sectionList:ITranslateSection[] = survey.sections.map((q,index) => { return {		
			label: q.label?q.label[translateFrom]:'',
			hint: q.hint?q.hint[translateFrom]:'',
			choices: [],
			question_id : q.question_id?q.question_id:index,
			questions: q.questions.map((q2,index)=> {
				return{
					label: q2.label?q2.label[translateFrom]:'',
					hint: q2.hint?q2.hint[translateFrom]:'',
					choices: q2.choices?q2.choices.map((c2,index) => {return {
						label: c2.label[translateFrom],
						choice_id : c2.choice_id?c2.choice_id:index.toString()
					}}): [],
					question_id : q2.question_id?q2.question_id:index
				}
			})
	}});

	const translateSurveyData: ITranslateSurvey ={
		survey_description: survey.survey_description?.[translateFrom] ? survey.survey_description?.[translateFrom] : '',
		survey_title: survey.survey_title?.[translateFrom] ? survey.survey_title?.[translateFrom] : '',
		consent_text: survey.consent_text?.[translateFrom] ? survey.consent_text?.[translateFrom] : '',
		general_questions: questionList,
		sections: sectionList
	}
	
	translateSurvey(translateFrom,translateTo,translateSurveyData).then((value) => {
		const translatedData: ITranslateSurvey = value;
		if(survey.survey_description) survey.survey_description[translateTo] = translatedData.survey_description?translatedData.survey_description:'';
		if(survey.survey_title) survey.survey_title[translateTo] = translatedData.survey_title?translatedData.survey_title:'';
		if(survey.consent_text) survey.consent_text[translateTo] = translatedData.consent_text?translatedData.consent_text:'';

		//if survey contains sections
		if(translatedData.sections){
			translatedData.sections.forEach(function(translatedSection){
				let section = survey.sections.find(x=>x.question_id == translatedSection.question_id);
				if(section == null && typeof translatedSection.question_id ==='string' ) section= survey.sections.at(parseInt(translatedSection.question_id))		
				if(section?.label){
					section.label[translateTo]=translatedSection.label;
				}
				if(translatedSection.questions){
					translatedSection.questions.forEach(function(translatedQuestion){
						let question = section?.questions.find( x=> x.question_id==translatedQuestion.question_id);
						if(question==null && typeof translatedQuestion.question_id ==='string') question = section?.questions.at(parseInt(translatedQuestion.question_id))
						if(question) translateQuestions(translatedQuestion,translateTo,question);
					})
				}
			})
		}

		//if survey has only general questions
		if(translatedData.general_questions){
			translatedData.general_questions.forEach(function(translatedQuestion){
				let general_question = survey.general_questions.find(x=>x.question_id == translatedQuestion.question_id);			
				if(general_question?.label){
					general_question.label[translateTo]=translatedQuestion.label;
				}else{
					//ako pitanje nije još spremljeno i nema ID
					if(typeof translatedQuestion.question_id === 'string'){
						general_question = survey.general_questions.at(parseInt(translatedQuestion.question_id));
					}
						
				}
				
				if(general_question){
					translateQuestions(translatedQuestion,translateTo,general_question);
				}
				
			})
		}
		show({
			type: 'success',
			headerText: 'Translated!',
			message: 'Survey successfully translated '
		});
		languageDispatch(setCurrentLanguage(translateTo));
	})
}

const translateQuestions = (translatedQuestion:ITranslateQuestion, translateTo:string, question:IQuestion) =>{
	if(question?.label) question.label[translateTo] = translatedQuestion.label;
	if(question?.hint) question.hint[translateTo] = translatedQuestion.hint;

	if(translatedQuestion.choices){
		translatedQuestion.choices.forEach(function(translatedChoice){
			let choice = question?.choices.find(x=> x.choice_id==translatedChoice.choice_id);
			if(choice == null) choice = question.choices.at(parseInt(translatedChoice.choice_id));
			if(choice?.label){
				if(question.type !=='property'){
					choice.label[translateTo] = translatedChoice.label;	
				}
				
			}
		})
	}
}



export const LanguageTranslateModal: React.FC<ITranslateModalProps> = ({
	isOpen,
	onRequestClose,
}) => {


const {currentLanguage, defaultLanguage, availableLanguages } = useLanguageContext();
const { data: languages } = useGetLanguagesQuery();
const languageDispatch = useLanguageDispatchContext();
const survey = React.useContext(SurveyContext);
const { data: quota } = useGetQuotaQuery();
	return (
		<Modal
			headerText="Translate survey"
			isOpen={isOpen}
			onRequestClose={onRequestClose}
		>
			<Formik
				
				initialValues={{
					translateFrom: defaultLanguage,
					translateTo: (currentLanguage==defaultLanguage)?availableLanguages.find(
						lang => lang !== defaultLanguage
					):currentLanguage
				}}
				validationSchema={Yup.object().shape({
					translateTo:Yup.string().notOneOf([Yup.ref('translateFrom')],"Languages should be different")
				})}
				onSubmit={values => {
					handleSubmit(values.translateFrom,values.translateTo?values.translateTo:'', survey, languageDispatch);
				}}
			>
			{props => {
						const {
							errors
						} = props;
				return(
						<Form>
							{languages && (
								<div className="flex flex-col pb-12">
									<p className="font-bold text-gray-500 mb-3">
										Translate from
									</p>
									<FieldSelect
										name="translateFrom"
										
										
										options={languages.filter(lang=>availableLanguages.includes(lang.short_name)).map(language => {
											return {
												value: language.short_name,
												display: (
													<span className="flex items-center">
														{getLanguageFlag(language.short_name)}
														<span className="ml-4">{language.name}</span>
													</span>
												)
											};
										})}
										className="mb-7 max-w-xs"
									/>
									<p className="font-bold text-gray-500 mb-3">
										Translate to
									</p>
									<FieldSelect
										name="translateTo"
										options={languages.filter(lang=>availableLanguages.includes(lang.short_name)).map(language => {
											return {
												value: language.short_name,
												display: (
													<span className="flex items-center">
														{getLanguageFlag(language.short_name)}
														<span className="ml-4">{language.name}</span>
													</span>
												)
											};
										})}
										className="mb-7 max-w-xs"
										error={errors.translateTo}
									/>
									<div className="flex">
										<Button
											type="submit"
											text="Translate"
											size="small"
											onClick={onRequestClose}
											className="self-start mt-3"
											disabled={quota?.survey_translation==='not_included'}
										/>
										<Button
											type="button"
											text="Cancel"
											size="small"
											variant="light"
											onClick={onRequestClose}
											className="ml-5 mt-3"
										/>
									</div>
									{quota?.survey_translation==='not_included' && (
											<div className="flex mt-5">
												<SvgWarning className="w-5 h-5 text-red-500" />
												<span className="text-red-500">Translations are not included in your subscription.</span></div>
										)}
								</div>
							)}
						</Form>
				);
			}}</Formik>
			
		</Modal>
	);
};
