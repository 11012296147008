import { ISurveyType } from 'services/surveys/interfaces';

export const getSurveyTypeOptions = (surveyTypes: ISurveyType[]) => {
	return surveyTypes
		.map(surveyType => {
			return {
				value: surveyType.survey_type_id,
				display: surveyType.name
			};
		})
		.concat({ value: null, display: 'No type set' })
		.reverse();
};

export const analyticsTypesOptions = [
	{
		value: null,
		display: 'No type set'
	},
	{
		value: 'age',
		display: 'Age'
	},
	{
		value: 'sex',
		display: 'Sex'
	},
	{
		value: 'location',
		display: 'Location'
	},
	{
		value: 'nps',
		display: 'Net promoter score (NPS)'
	},
	{
		value: 'rating',
		display: 'Rating'
	},
	{
		value: 'comment',
		display: 'Comment'
	},
	{
		value: 'select_one',
		display: 'Select one'
	}
];
