import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';

import { RequireAuth } from 'components/common';

import Surveys from './surveys';
import NewSurvey from './surveys/new-survey';
import SingleSurvey from './surveys/[id]';
import SingleSubmission from './submissions/[id]';

import Templates from './templates';
import NewTemplate from './templates/new-template';
import SingleTemplate from './templates/[id]';

import Emails from './emails';
import NewEmailTemplate from './emails/new-email-template';
import EmailTemplate from './emails/[id]';

import Notifications from './notifications';
import NewRule from './notifications/new-rule';
import Notification from './notifications/[id]';

import Analytics from './analytics';
import Profile from './profile';
import Login from './login';
import NotFound from './404';
import Unsubscribe from './unsubscribe';
import Register from './register';
import ForgotPassword from './forgot-password';
import Reset from './reset';

import { AlertProvider, LanguageProvider, UserProvider } from 'context';
import { useAxiosInterceptors } from 'utils/interceptors';
import { WebChatContainer } from '@ibm-watson/assistant-web-chat-react';

import { configs } from 'config/configs';

import 'styles/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import Help from './help';

const { client: queryClient } = configs();

function App() {
	const location = useLocation();

	const webChatOptions = {
		integrationID: 'e7a8aa21-47f2-493c-b682-2161f29e175a',
		region: 'us-south',
		serviceInstanceID: 'ff622084-8cfd-44e3-8292-49278e9b4251'
	};

	return (
		<QueryClientProvider client={queryClient}>
			<AlertProvider>
				<UserProvider>
					<WithAxios>
						<>
							<Routes>
								<Route
									path="/"
									element={
										<RequireAuth>
											<Navigate to="/surveys" />
										</RequireAuth>
									}
								/>
								<Route
									path="/surveys"
									element={
										<RequireAuth>
											<Surveys />
										</RequireAuth>
									}
								/>
								<Route
									path="/surveys/new-survey"
									element={
										<RequireAuth>
											<LanguageProvider>
												<NewSurvey />
											</LanguageProvider>
										</RequireAuth>
									}
								/>
								<Route
									path="/surveys/:surveyId"
									element={
										<RequireAuth>
											<LanguageProvider>
												<SingleSurvey />
											</LanguageProvider>
										</RequireAuth>
									}
								/>
								<Route
									path="/submissions/:submissionId"
									element={
										<RequireAuth>
											<LanguageProvider>
												<SingleSubmission />
											</LanguageProvider>
										</RequireAuth>
									}
								/>

								<Route
									path="/templates"
									element={
										<RequireAuth>
											<Templates />
										</RequireAuth>
									}
								/>
								<Route
									path="/templates/new-template"
									element={
										<RequireAuth>
											<LanguageProvider>
												<NewTemplate />
											</LanguageProvider>
										</RequireAuth>
									}
								/>
								<Route
									path="/templates/:surveyId"
									element={
										<RequireAuth>
											<LanguageProvider>
												<SingleTemplate />
											</LanguageProvider>
										</RequireAuth>
									}
								/>

								<Route
									path="/emails"
									element={
										<RequireAuth>
											<Emails />
										</RequireAuth>
									}
								/>

								<Route
									path="/emails/new-email-template"
									element={
										<RequireAuth>
											<LanguageProvider>
												<NewEmailTemplate />
											</LanguageProvider>
										</RequireAuth>
									}
								/>
								<Route
									path="/emails/:emailTemplateId"
									element={
										<RequireAuth>
											<LanguageProvider>
												<EmailTemplate />
											</LanguageProvider>
										</RequireAuth>
									}
								/>

								<Route
									path="/notifications"
									element={
										<RequireAuth>
											<LanguageProvider>
												<Notifications />
											</LanguageProvider>
										</RequireAuth>
									}
								/>
								<Route
									path="/notifications/new-rule"
									element={
										<RequireAuth>
											<LanguageProvider>
												<NewRule />
											</LanguageProvider>
										</RequireAuth>
									}
								/>
								<Route
									path="/notifications/:notificationId"
									element={
										<RequireAuth>
											<LanguageProvider>
												<Notification />
											</LanguageProvider>
										</RequireAuth>
									}
								/>

								<Route
									path="/profile"
									element={
										<RequireAuth>
											<Profile />
										</RequireAuth>
									}
								/>

								<Route
									path="/help"
									element={
										<RequireAuth>
											<Help />
										</RequireAuth>
									}
								/>

								<Route
									path="/analytics"
									element={
										<RequireAuth>
											<Analytics />
										</RequireAuth>
									}
								/>

								<Route
									path="/unsubscribe"
									element={
										<RequireAuth>
											<Unsubscribe />
										</RequireAuth>
									}
								/>

								<Route path="/register" element={<Register />} />

								<Route path="/login" element={<Login />} />
								<Route path="/forgot-password" element={<ForgotPassword />} />
								<Route path="/reset" element={<Reset />} />
								<Route path="*" element={<NotFound />} />
							</Routes>

							<ReactQueryDevtools initialIsOpen={false} />

							<ToastContainer
								containerId="toast-container"
								newestOnTop
								limit={1}
							/>

							{location.pathname !== '/login' &&
								location.pathname !== '/register' &&
								location.pathname !== '/forgot-password' &&
								location.pathname !== '/reset' &&
								process.env.NODE_ENV !== 'production' &&
								//remove next condition to bring back watson chat assistant
								window.location.hostname !== 'localhost' && (
									<WebChatContainer config={webChatOptions as any} />
								)}
						</>
					</WithAxios>
				</UserProvider>
			</AlertProvider>
		</QueryClientProvider>
	);
}

const WithAxios: React.FC = ({ children }) => {
	useAxiosInterceptors();
	return <>{children}</>;
};

export default App;
