import { Button, FormikCheckbox } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { Form, Formik } from 'formik';
import { useToast } from 'hooks';
import React from 'react';
import { IUserInfo, postUser } from 'services/users';
import * as Yup from 'yup';

export interface ICreateNewUserFormProps {
	className?: string;
	onSuccess?: () => void;
}

export const CreateNewUserForm: React.FC<ICreateNewUserFormProps> = ({
	className,
	onSuccess
}) => {
	const show = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const [backendError, setBackendError] = React.useState<Record<string, any>>(
		{}
	);

	const handleSubmit = (values: IUserInfo) => {
		setIsSubmitting(true);

		postUser(values)
			.then(() => {
				show({
					type: 'success',
					headerText: 'Created!',
					message:
						'Successfully created user, email has been sent to them for confirmation'
				});
				setIsSubmitting(false);
				onSuccess && onSuccess();
			})
			.catch(error => {
				if (error.response.data) {
					setBackendError(error.response.data);
				}
				setIsSubmitting(false);
			});

		setIsSubmitting(false);
	};

	return (
		<Formik
			initialValues={{
				first_name: '',
				last_name: '',
				email: '',
				is_admin: false
			}}
			validationSchema={Yup.object().shape({
				first_name: Yup.string().required('This field is required'),
				last_name: Yup.string().required('This field is required'),
				email: Yup.string()
					.email('Invalid email format')
					.required('This field is required')
			})}
			onSubmit={handleSubmit}
		>
			{props => {
				const { errors, submitCount } = props;

				return (
					<Form id="user-info-update-form" className={className || ''}>
						<FieldInput
							name="first_name"
							id="first_name"
							label="First name"
							placeholder="Add name"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.first_name && errors.first_name) ||
									(backendError.first_name && backendError.first_name[0]) ||
									'')
							}
						/>
						<FieldInput
							name="last_name"
							id="last_name"
							label="Last name"
							placeholder="Add last name"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.last_name && errors.last_name) ||
									(backendError.last_name && backendError.last_name[0]) ||
									'')
							}
						/>
						<FieldInput
							name="email"
							id="email"
							label="Email"
							placeholder="Add email"
							type="email"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.email && errors.email) ||
									(backendError.email && backendError.email.email) ||
									'')
							}
						/>

						<FormikCheckbox
							name="is_admin"
							id="is_admin"
							label="Admin profile"
							className="mb-10"
							variant="toggler"
						/>

						<Button
							text="Create user"
							type="submit"
							disabled={isSubmitting}
							loading={isSubmitting}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
