import axios from 'axios';
import { ITranslateSurvey} from './interfaces';



export const translateSurvey = async (language_from:string, language_to: string, translateData: ITranslateSurvey) => {
	return await axios
		.post<any>(`${process.env.REACT_APP_API_URL}/translate_survey/`, translateData,{ params: {
            language_from,
            language_to
          }})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating survey:`, error);
			return Promise.reject(error);
		});
};

