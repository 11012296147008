export const savePdf = (pdfData: any, name: string) => {
	const pdfBlob = new Blob([pdfData], { type: 'application/pdf' });

	const pdfUrl = URL.createObjectURL(pdfBlob);

	const downloadLink = document.createElement('a');

	downloadLink.href = pdfUrl;
	downloadLink.download = name;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};
