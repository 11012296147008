import { useFormikContext } from 'formik';
import React from 'react';

export interface IAutoSubmit {
	debounce?: number;
}

export const AutoSubmit: React.FC<IAutoSubmit> = ({ debounce = 500 }) => {
	const { values, submitForm, dirty } = useFormikContext();

	React.useEffect(() => {
		if (dirty) {
			setTimeout(() => {
				submitForm();
			}, debounce);
		}
	}, [values]);

	return null;
};
