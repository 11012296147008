import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const getSurveyTypes = async () => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/get_survey_types/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting survey analytics types:`, error);
			return Promise.reject(error);
		});
};

export const useGetSurveyTypes = () => {
	return useQuery(
		['survey_type'] as QueryKey,
		() => getSurveyTypes(),

		{
			refetchOnWindowFocus: false
		}
	);
};
