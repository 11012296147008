import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { IUserInfo } from '.';

export const getUserInfo = async () => {
	return await axios
		.get<IUserInfo>(`${process.env.REACT_APP_API_URL}/userinfo_api/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting user info:`, error);
			return Promise.reject(error);
		});
};

export const useGetUserInfoQuery = () => {
	return useQuery('user_info' as QueryKey, () => getUserInfo(), {
		refetchOnWindowFocus: false
	});
};
