import React from 'react';
import { InputFeedback } from 'components/form-elements';

export interface ICustomTextAreaProps
	extends React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	> {
	className?: string;
	name: string;
	label?: string;
	placeholder?: string;
	value?: string;
	error?: string;
	rows?: number;
}

export const CustomTextArea: React.FC<ICustomTextAreaProps> = ({
	className,
	name,
	label,
	placeholder,
	value,
	error,
	id,
	onChange,
	rows = 5,
	...rest
}) => {
	const [innerValue, setInnerValue] =
		React.useState<HTMLTextAreaElement['value']>('');

	React.useEffect(() => {
		setInnerValue(value || '');
	}, [value]);

	const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		setInnerValue(e.target.value);
		onChange && onChange(e);
	};

	const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

	React.useEffect(() => {
		const el = textAreaRef.current;
		if (el) {
			el.style.height = 'auto';
			el.style.height = el.scrollHeight + 'px';
		}
	}, [textAreaRef.current?.scrollHeight]);

	return (
		<div
			className={`${className || ''}
				flex flex-col relative w-full rounded-none
    			`}
		>
			{label && (
				<label
					className={`block text-sm font-medium text-gray-700 truncate mb-1`}
					htmlFor={name}
				>
					{label}
				</label>
			)}

			<textarea
				data-testid={`${name}-input-textarea`}
				className={`
					resize-none px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none 
					sm:text-sm flex items-center w-full h-full outline-none bg-white overflow-hidden
					${
						error
							? 'border-red-500 focus:border-red-500 focus:ring-red-500'
							: 'border-gray-300 focus:ring-blue-700 focus:border-blue-700 '
					}
                	`}
				id={id}
				name={name}
				value={innerValue}
				onChange={handleOnChange}
				ref={textAreaRef}
				rows={rows}
				placeholder={placeholder}
				{...rest}
			/>

			{error && <InputFeedback name={name} error={error} />}
		</div>
	);
};
