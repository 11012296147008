import { IAlertState, initialAlertState } from 'context/AlertContext';
import { createAction } from './actions/actions';

export enum AlertActions {
	AlertShow = '[AlertContext] Alert Show',
	AlertHide = '[AlertContext] Alert Hide'
}

export type AlertActionTypes = IAlertShowAction | IAlertHideAction;

interface IAlertShowAction {
	type: typeof AlertActions.AlertShow;
	payload: IAlertState;
}
export const alertShowAction = createAction<IAlertShowAction['payload']>(
	AlertActions.AlertShow
);

interface IAlertHideAction {
	type: typeof AlertActions.AlertHide;
}
export const alertHideAction = createAction<IAlertHideAction>(
	AlertActions.AlertHide
);

export const alertReducer = (state: IAlertState, action: AlertActionTypes) => {
	switch (action.type) {
		case AlertActions.AlertShow: {
			return { ...state, ...action.payload, isOpen: true };
		}
		case AlertActions.AlertHide: {
			return { ...initialAlertState };
		}
		default: {
			throw new Error('AlertReducer: This reducer action does not exist');
		}
	}
};
