export const capitalize = (str: string) =>
	str.charAt(0).toUpperCase() + str.slice(1);

export const stripLineBreaks = (str: string) => str.replace(/\n/g, ' ');

export const stripSpecialCharacters = (str: string) =>
	str.replace(/[^\w\s]/gi, '');

export const extractNumberFromString = (str: string): string =>
	String(str.match(/\d+/g)?.[0]);
