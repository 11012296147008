import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import {
	deleteTag,
	editTag,
	ISurveyTag,
	postTag,
	useGetTagsQuery
} from 'services/tags';
import { Button, Modal } from 'components/common';
import { FieldInput } from 'components/formik-elements';

import { useGetQuotaQuery } from 'services/subscriptions';

import { ReactComponent as SvgClose } from 'assets/icons/close.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';

export interface IManageTagsFormProps {
	className?: string;
	withoutActions?: boolean;
}

interface IEditTagModal {
	isOpen: boolean;
	tag?: ISurveyTag;
}

export const ManageTagsForm: React.FC<IManageTagsFormProps> = ({
	className,
	withoutActions
}) => {
	const {
		data: tags,
		isLoading: isLoadingTags,
		refetch: refetchTags
	} = useGetTagsQuery();
	const { data: quota } = useGetQuotaQuery();

	const handleDeleteTag = (id?: number) => {
		deleteTag(id).then(() => {
			refetchTags();
		});
	};

	const [tag, setTag] = React.useState<IEditTagModal>({
		isOpen: false,
		tag: undefined
	});

	if (!quota || !tags) {
		return <></>;
	}

	return (
		<div className={className || ''}>
			<Formik
				initialValues={{
					name: ''
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string()
						.required('This field is required')
						.lowercase('Use only lowercase')
						.strict()
				})}
				onSubmit={(values, { resetForm }) => {
					postTag(values).then(() => {
						resetForm();
						refetchTags();
					});
				}}
			>
				{({ errors, submitCount, values }) => {
					return (
						<Form className="flex w-full items-start">
							<FieldInput
								name="name"
								id="name"
								label="Tag name"
								className="mr-4 w-full"
								error={submitCount > 0 && errors.name ? errors.name : ''}
							/>

							<Button
								text="Add tag"
								type="submit"
								size="small"
								className="mt-[25px]"
								disabled={values.name === '' || quota?.n_tags >= tags?.length}
							/>
						</Form>
					);
				}}
			</Formik>

			{quota?.n_tags >= tags?.length && (
				<p className="flex items-center text-xs text-orange-500 mt-2">
					<SvgWarning className="w-4 h-4 mr-2" />
					You have reached the maximum number of tags.
				</p>
			)}

			{tags && !isLoadingTags && (
				<div className="mt-6">
					<h2 className="block text-sm font-medium text-gray-700 truncate mb-1">
						Added tags
					</h2>

					<div className="w-full flex items-start flex-wrap -mx-2">
						{tags.map((tag: ISurveyTag) => {
							return (
								<div
									key={tag.tag_id}
									className={`flex items-center ${
										withoutActions ? 'px-4' : 'pl-4 pr-1.5'
									} py-1.5 border border-gray-300 text-gray-500 rounded-lg mx-2 mb-4 text-base shadow-sm`}
								>
									<span className={withoutActions ? '' : 'pr-4'}>
										{tag.name}
									</span>
									{!withoutActions && (
										<>
											<button
												title="Edit tag"
												onClick={() => setTag({ isOpen: true, tag: tag })}
												className="flex items-center justify-center w-8 h-8 hover:text-gray-700 transition-colors duration-150 bg-gray-200 hover:bg-gray-300 rounded-md"
											>
												<SvgEdit className="w-5 h-5" />
											</button>
											<button
												title="Delete tag"
												onClick={() => handleDeleteTag(tag.tag_id)}
												className="flex items-center justify-center w-8 h-8 hover:text-gray-700 transition-colors duration-150 bg-gray-200 hover:bg-gray-300 rounded-md ml-1.5"
											>
												<SvgClose className="w-5 h-5" />
											</button>
										</>
									)}
								</div>
							);
						})}
					</div>
				</div>
			)}

			<Modal
				headerText="Edit tag"
				isOpen={tag.isOpen}
				onRequestClose={() => setTag({ isOpen: false, tag: undefined })}
				className="pb-8 mx-0 ml-12"
			>
				{tag.tag && (
					<Formik
						initialValues={{
							name: tag.tag.name
						}}
						validationSchema={Yup.object().shape({
							name: Yup.string()
								.required('This field is required')
								.lowercase('Use only lowercase')
								.strict()
						})}
						onSubmit={values => {
							editTag(tag.tag?.tag_id, values).then(() => {
								refetchTags();
								setTag({ isOpen: false, tag: undefined });
							});
						}}
					>
						{({ errors, submitCount, values }) => {
							return (
								<Form className="flex w-full items-start">
									<FieldInput
										name="name"
										id="name"
										label="Tag name"
										className="mr-4 w-full"
										error={submitCount > 0 && errors.name ? errors.name : ''}
									/>

									<Button
										text="Edit"
										type="submit"
										size="small"
										className="mt-[25px]"
										disabled={values.name === ''}
									/>
								</Form>
							);
						}}
					</Formik>
				)}
			</Modal>
		</div>
	);
};
