import React from 'react';
import { FieldArray } from 'formik';
import { useLanguageContext } from 'context';

import { Button, FormikCheckbox } from 'components/common';
import { ISurveyUI } from 'services/surveys/interfaces';
import { getAllQuestions, getQuestionIndex } from '../helpers';
import { capitalize } from 'utils';

interface ISurveyAnalyticsProps {
	survey: ISurveyUI;
}

export const SurveyAnalyticEdit: React.FC<ISurveyAnalyticsProps> = ({
	survey
}) => {
	const { currentLanguage } = useLanguageContext();

	return (
		<div>
			{survey.general_questions.length > 0 &&
				survey.general_questions.map(question => {
					const disableAnalytics =
						getAllQuestions(survey)
							.filter(item => item.name !== question.name)
							.filter(item =>
								['age', 'country', 'sex', 'select_one'].includes(item.type)
							)
							.filter(
								item =>
									item.analytics_type === true && item.type === question.type
							).length === 1;

					return (
						<div
							key={question.name}
							className="flex flex-row justify-between items-center px-4 py-6 border rounded-lg border-gray-300 bg-gray-50 mb-5"
						>
							<p className="text-gray-900 grow text-sm leading-normal mr-8">
								{question.label?.[currentLanguage]}
							</p>

							{disableAnalytics && (
								<p className="mr-4 text-xs text-gray-400">
									You can only include one
									<strong className="mx-1">
										"{capitalize(question.type).replace('_', ' ')}"
									</strong>
									type in analytics.
								</p>
							)}

							{[
								'text',
								'age',
								'sex',
								'nps',
								'country',
								'emoji_rating',
								'rating',
								'select_one',
								'matrix_rating'
							].includes(question.type) && (
								<FormikCheckbox
									name={`general_questions.${getQuestionIndex(
										question,
										survey
									)}.analytics_type`}
									id={`general_questions.${getQuestionIndex(
										question,
										survey
									)}.analytics_type`}
									label="Include in analytics"
									type="checkbox"
									variant="toggler"
									tabIndex={0}
									disabled={disableAnalytics}
								/>
							)}
						</div>
					);
				})}

			<FieldArray
				key={'sections'}
				name="sections"
				render={() => {
					return survey.sections.map((section, index) => (
						<FieldArray
							key={`sections.${index}.questions`}
							name={`sections.${index}.questions`}
							render={() => {
								return (
									<div
										key={`sections.${index}.questions`}
										className="px-4 py-6 border rounded-lg border-gray-300 bg-gray-50 mt-8"
									>
										<h4 className="text-gray-900 text-xl font-bold leading-8 mb-5">
											{section.label?.[currentLanguage]}
										</h4>

										<div className="bg-white border border-gray-300 rounded-lg p-5 space-y-5">
											{section.questions.map((question, q_index) => {
												const disableAnalytics =
													getAllQuestions(survey)
														.filter(item => item.name !== question.name)
														.filter(item =>
															['age', 'country', 'sex', 'select_one'].includes(
																item.type
															)
														)
														.filter(
															item =>
																item.analytics_type === true &&
																item.type === question.type
														).length === 1;

												return (
													<div
														key={question.name}
														className="flex justify-between items-center p-5 border border-gray-300 rounded-lg bg-gray-50"
													>
														<p className="text-gray-900 grow text-sm leading-normal mr-8">
															{question.label?.[currentLanguage]}
														</p>

														{disableAnalytics && (
															<p className="mr-4 text-xs text-gray-400">
																You can only include one
																<strong className="mx-1">
																	"{capitalize(question.type).replace('_', ' ')}
																	"
																</strong>
																type in analytics.
															</p>
														)}

														{[
															'text',
															'age',
															'sex',
															'nps',
															'country',
															'emoji_rating',
															'rating',
															'select_one',
															'matrix_rating'
														].includes(question.type) && (
															<FormikCheckbox
																name={`sections.${index}.questions.${q_index}.analytics_type`}
																id={`sections.${index}.questions.${q_index}.analytics_type`}
																label="Include in analytics"
																type="checkbox"
																variant="toggler"
																tabIndex={0}
																disabled={disableAnalytics}
															/>
														)}
													</div>
												);
											})}
										</div>
									</div>
								);
							}}
						/>
					));
				}}
			/>
			<hr className="my-6" />
			<div className="flex">
				<Button
					type="submit"
					text="Save changes"
					size="small"
					className="mr-3"
				/>
			</div>
		</div>
	);
};
