import React from 'react';
import { InputFeedback } from '../InputFeedback';

export interface ICustomDatePickerProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	className?: string;
	name: string;
	label?: string | JSX.Element;
	value?: string;
	error?: string;
	required?: boolean;
}

export const CustomDatePicker: React.FC<ICustomDatePickerProps> = ({
	className,
	id,
	name,
	label,
	value,
	error,
	onChange,
	onBlur,
	onFocus,
	required = false,
	disabled,
	...rest
}) => {
	const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

	const [innerValue, setInnerValue] =
		React.useState<HTMLInputElement['value']>('');

	React.useEffect(() => {
		setInnerValue(value || '');
	}, [value]);

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInnerValue(e.target.value);
		onChange && onChange(e);
	};

	const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		onFocus && onFocus(e);
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		onBlur && onBlur(e);
	};

	return (
		<div className={` ${className || ''} flex flex-col relative`}>
			{label && (
				<label
					className={`block text-sm font-medium text-gray-700 truncate mb-1`}
					htmlFor={name}
				>
					{label}
				</label>
			)}

			<input
				ref={inputRef}
				data-testid={`${name}-date-input`}
				className={`
                    leading-6 appearance-none border rounded-md shadow-sm focus:outline-none sm:text-sm flex items-center w-full h-10
                    ${
											error
												? 'border-red-500 focus:border-red-500 focus:ring-red-500'
												: 'border-gray-300 focus:ring-blue-700 focus:border-blue-700'
										} ${disabled ? 'opacity-50' : ''}`}
				id={id}
				type="date"
				name={name}
				value={innerValue}
				onChange={handleOnChange}
				onFocus={handleOnFocus}
				onBlur={handleOnBlur}
				{...rest}
				required={required}
				disabled={disabled}
			/>

			{error && <InputFeedback name={name} error={error} />}
		</div>
	);
};
