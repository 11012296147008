import React from 'react';
import { useReducerWithLogger } from 'hooks';
import { surveyCollapseReducer } from 'reducers';

export interface ISurveyCollapseState {
	questions: string[];
	sections: string[];
}

export const initialSurveyCollapse: ISurveyCollapseState = {
	questions: [],
	sections: []
};

export const SurveyCollapseContext = React.createContext<ISurveyCollapseState>(
	initialSurveyCollapse
);
export const SurveyCollapseDispatchContext = React.createContext<any>({});

export const SurveyCollapseProvider: React.FC<{
	idsToCollapse?: Partial<ISurveyCollapseState>;
}> = ({ children, idsToCollapse }) => {
	const [state, dispatch] = useReducerWithLogger(
		surveyCollapseReducer,
		{...initialSurveyCollapse, ...idsToCollapse},
		true
	);
	return (
		<SurveyCollapseContext.Provider value={{ ...state }}>
			<SurveyCollapseDispatchContext.Provider value={dispatch}>
				{children}
			</SurveyCollapseDispatchContext.Provider>
		</SurveyCollapseContext.Provider>
	);
};

export const useSurveyCollapseContext = () => {
	const context = React.useContext(SurveyCollapseContext);
	if (context === undefined) {
		throw new Error(
			'useSurveyCollapseContext must be used within a SurveyCollapseContext'
		);
	}
	return context;
};

export const useSurveyCollapseDispatchContext = () => {
	const context = React.useContext(SurveyCollapseDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useSurveyCollapseDispatchContext must be used within a SurveyCollapseDispatchContext'
		);
	}
	return context;
};
