import axios from 'axios';
import { IUserInfo } from './interfaces';

export const updateUserInfo = async (data: IUserInfo) => {
	return await axios
		.put<IUserInfo>(`${process.env.REACT_APP_API_URL}/userinfo_api/`, data)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating user info:`, error);
			return Promise.reject(error);
		});
};
