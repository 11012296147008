import React from 'react';

import { ReactComponent as SvgLogo } from 'assets/icons/logo.svg';
import { ReactComponent as SvgClose } from 'assets/icons/close.svg';

interface IModalHeaderProps {
	className?: string;
	headerText?: string;
	branded?: boolean;
	hideClose?: boolean;
	onRequestClose?: ReactModal.Props['onRequestClose'];
}

export const ModalHeader: React.FC<IModalHeaderProps> = ({
	className,
	headerText,
	branded,
	hideClose,
	onRequestClose
}) => {
	const handleClose = (e: any) => {
		onRequestClose && onRequestClose(e);
	};
	return (
		<header
			className={`${className || ''} z-99 flex items-center ${
				hideClose ? 'justify-center' : 'justify-between'
			} pt-6`}
		>
			{branded ? (
				<SvgLogo className="h-5 md:h-10" />
			) : (
				headerText && (
					<h3 className="ml-0 text-xl font-bold text-gray-900 sm:text-2xl">
						{headerText}
					</h3>
				)
			)}

			{!hideClose && (
				<button
					className="flex rounded-full cursor-pointer modal-header__x flex-start focus:outline-none bg:white"
					onClick={handleClose}
				>
					<SvgClose className="block w-10 h-10 p-2" />
				</button>
			)}
		</header>
	);
};

export default ModalHeader;
