import React from 'react';
import { Column, useTable } from 'react-table';

import { Loader } from 'components/common';

import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { ReactComponent as SvgEye } from 'assets/icons/eye-on.svg';
import {
	IEmailTemplate,
	useGetEmailTemplatesQuery
} from 'services/email-templates';

export interface IEmailTemplateTableProps {
	onDelete: (template: IEmailTemplate) => void;
	onEdit: (template: IEmailTemplate) => void;
	onPreview: (template: IEmailTemplate) => void;
}

interface IEmailTableData {
	template_name?: string;
	actions?: JSX.Element;
}

export const EmailTemplateTable: React.FC<IEmailTemplateTableProps> = ({
	onDelete,
	onEdit,
	onPreview
}) => {
	const { data: emailTemplates, isLoading } = useGetEmailTemplatesQuery();

	const columns: Column<IEmailTableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'Template name',
				accessor: 'template_name',
				sortDescFirst: true
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				sortDescFirst: true
			}
		];
	}, []);

	const data: IEmailTableData[] = React.useMemo(() => {
		if (!emailTemplates) {
			return [];
		}
		return emailTemplates?.map((template: IEmailTemplate) => {
			return {
				template_name: template.template_name,
				actions: (
					<span className="flex">
						<button
							title="Preview template"
							type="button"
							aria-label="preview template"
							onClick={() => onPreview(template)}
							className="flex items-center justify-center text-gray-500 hover:text-blue-700 mr-1 w-8 h-8"
						>
							<SvgEye className="w-5 h-5" />
						</button>
						<button
							title="Edit template"
							role="button"
							aria-label="edit template"
							onClick={() => onEdit(template)}
							className="flex items-center justify-center text-gray-500 hover:text-blue-700 mr-1 w-8 h-8"
						>
							<SvgEdit className="w-5 h-5" />
						</button>
						<button
							title="Delete template"
							role="button"
							aria-label="delete template"
							onClick={() => onDelete(template)}
							className="flex items-center justify-center text-gray-500 hover:text-blue-700 w-8 h-8"
						>
							<SvgTrash className="w-5 h-5" />
						</button>
					</span>
				)
			};
		});
	}, [emailTemplates]);

	const {
		//table
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data,
		initialState: {}
	});

	if (!data || !emailTemplates || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
			<table
				{...getTableProps()}
				className="min-w-full divide-y divide-gray-200"
			>
				<thead className="bg-gray-50">
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => {
								return (
									<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
										<span className="flex items-center">
											<span className="h-4">{column.render('Header')}</span>
										</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);
						return (
							<tr
								{...row.getRowProps()}
								className={`${i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'}`}
							>
								{row.cells.map(cell => {
									return (
										<td
											{...cell.getCellProps()}
											className="whitespace-nowrap text-sm p-0"
										>
											{(cell.column.id === 'actions' && (
												<span className="px-6 py-4 block">
													{cell.render('Cell')}
												</span>
											)) || (
												<span className="px-6 py-4 block whitespace-normal line-clamp-2">
													<span>{cell.render('Cell')}</span>
												</span>
											)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};
