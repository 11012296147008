import React from 'react';
import { Field } from 'formik';
import { CustomInput, ICustomInputProps } from 'components/form-elements';

export const FieldInput: React.FC<ICustomInputProps> = ({
	className,
	name,
	ref,
	type,
	...rest
}) => {
	return (
		<Field name={name}>
			{(props: any) => {
				const {
					field: { name, value, onChange, onBlur },
					meta: { error }
				} = props;
				return (
					<div className={className}>
						<CustomInput
							className="w-full"
							ref={ref as any}
							name={name}
							value={value}
							onChange={(e: any) => onChange(e)}
							onBlur={(e: any) => onBlur(e)}
							type={type}
							error={error}
							{...rest}
						/>
					</div>
				);
			}}
		</Field>
	);
};
