import React from 'react';
import { useQueryClient } from 'react-query';
import { Formik, Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { Layout } from 'components/shared';
import { Loader, Button } from 'components/common';
import {
	SurveyOptions,
	Survey,
	SurveyInfo,
	SurveyAnalyticEdit
} from 'components/survey';
import { SubmissionsChart } from 'components/submissions';

import { useSearchParamsWithHelpers, useToast, usePrompt } from 'hooks';
import {
	alertShowAction,
	setAvailableLanguages,
	setCurrentLanguage,
	setDefaultLanguage
} from 'reducers';
import {
	useAlertDispatchContext,
	useLanguageContext,
	useLanguageDispatchContext
} from 'context';
import {
	updateSurvey,
	deleteSurvey,
	useGetSurveyByIdQuery,
	deactivateSurvey,
	publishSurvey,
	useGetAvailableSurveysQuery
} from 'services/surveys';
import { ISurveyUI } from 'services/surveys/interfaces';
import { useGetSubmissionBySurveyIdQuery } from 'services/submissions';
import {
	surveyValidationSchema,
	postProcessSurvey,
	preProcessSurvey,
	fillPropertyTypeChoices
} from 'components/survey/helpers';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { ReactComponent as SvgSend } from 'assets/icons/send.svg';

import { SubmissionsTable } from 'components/submissions/SubmissionsTable';
import { isEmpty } from 'utils';
import { useGetSurveyTypes } from 'services/surveys/getSurveyTypes';
import classNames from 'classnames';
import { useGetQuotaQuery } from 'services/subscriptions';
import { useGetProperties } from 'services/properties';
import {
	useGetUserInfoQuery
} from 'services/users';

function SingleSurvey() {
	const show = useToast();
	const params = useParams();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const alertDispatch = useAlertDispatchContext();
	const languageDispatch = useLanguageDispatchContext();
	const { availableLanguages, defaultLanguage } = useLanguageContext();

	const { data: quota, isLoading: isLoadingQuota } = useGetQuotaQuery();
	const { data: totalOpen, isLoading: isLoadingTotalOpen } =
		useGetAvailableSurveysQuery();

	const disablePublish =
		!isLoadingQuota &&
		!isLoadingTotalOpen &&
		quota &&
		totalOpen &&
		totalOpen.length >= quota?.n_open_surveys;

	const { data: survey, isLoading: isLoadingSurvey } = useGetSurveyByIdQuery(
		params.surveyId
	);

	const { data: surveyTypes } = useGetSurveyTypes();

	const { data: properties } = useGetProperties();

	const { data: submissions } = useGetSubmissionBySurveyIdQuery(
		params.surveyId
	);
	const submissionsCount = submissions?.count;

	const [isUpdating, setIsUpdating] = React.useState(false);

	const { updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const initialTab = 0;
	const tabIndex = getSearchParamWithInitial('tab', initialTab);

	const handleTabSelect = (index: number) =>
		updateSearchParams({
			tab: initialTab === index ? undefined : String(index)
		});

	

	React.useEffect(() => {
		if (survey) {
			languageDispatch(setDefaultLanguage(survey.survey_default_lang));
			languageDispatch(setCurrentLanguage(survey.survey_default_lang));
			languageDispatch(setAvailableLanguages(survey.survey_languages));
		}
	}, [survey]);

	const {
		data: user,
	} = useGetUserInfoQuery();

	const handleSubmit = (values: ISurveyUI) => {
		setIsUpdating(true);
		fillPropertyTypeChoices(values.general_questions, availableLanguages);
		fillPropertyTypeChoices( values.sections?.flatMap(section => section.questions), availableLanguages);
		updateSurvey(
			String(params.surveyId),
			postProcessSurvey(values, availableLanguages, defaultLanguage)
		)
			.then(() => {
				show({
					type: 'success',
					headerText: 'Updated!',
					message: 'Successfully updated survey'
				});
				queryClient.invalidateQueries(['survey', params.surveyId]);
				navigate('/surveys');
				setIsUpdating(false);
			})
			.catch(() => {
				show({
					type: 'error',
					headerText: 'Error!',
					message: `Your session may be expired, try saving the survey again.`
				});
				setIsUpdating(false);
			});
	};

	const deleteHandler = () => {
		alertDispatch(
			alertShowAction({
				header: `Delete survey?`,
				message: `Are you sure you want to delete this survey?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Confirm`,
						handler: () =>
							deleteSurvey(String(survey?.survey_id)).then(() =>
								navigate('/surveys')
							)
					}
				]
			})
		);
	};
	const deactivateHandler = () => {
		alertDispatch(
			alertShowAction({
				header: `Deactivate`,
				message: `Are you sure you want to deactivate this survey? Once deactivated, survey can not be activated again!`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Deactivate`,
						handler: () =>
							deactivateSurvey(String(survey?.survey_id))
								.then(() => {
									show({
										type: 'success',
										headerText: 'Deactivated!',
										message: 'Successfully deactivated survey'
									});
									navigate(-1);
								})
								.catch(() => {
									show({
										type: 'error',
										headerText: 'Error!',
										message: 'Something went wrong'
									});
								})
					}
				]
			})
		);
	};
	const publishHandler = () => {
		alertDispatch(
			alertShowAction({
				header: `Publish`,
				message: `Are you sure you want to publish this survey?`,

				buttons: [
					{
						text: `Close`,
						type: 'light'
					},
					{
						text: `Publish`,
						handler: () =>
							publishSurvey(String(survey?.survey_id))
								.then(() => {
									show({
										type: 'success',
										headerText: 'Published!',
										message: 'Successfully published survey'
									});
									navigate(`/surveys`);
								})
								.catch(() => {
									show({
										type: 'error',
										headerText: 'Error!',
										message: 'Something went wrong'
									});
								})
					}
				]
			})
		);
	};

	const exportData = () => {
		const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
		  JSON.stringify(survey)
		)}`;
		const link = document.createElement("a");
		link.href = jsonString;
		link.download = `${survey.survey_id}_${survey.survey_name}.json`;
	
		link.click();
	};

	if (isLoadingSurvey) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!isLoadingSurvey && survey === undefined) {
		return (
			<Layout pageTitle={`Invalid survey`}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">Survey non existent</p>
				</div>
			</Layout>
		);
	}

	let displayStatus: string;

	if (survey.survey_status?.includes('failed')) {
		displayStatus = 'Failed';
	} else if (survey.survey_status?.includes('processing')) {
		displayStatus = 'Processing';
	} else {
		displayStatus = survey.survey_status ? survey.survey_status?.replace("_success", ""):'';
	}

	return (
		<>
	
			{survey && <SurveyInfo {...{ survey }} />}
			<Layout
				pageTitle={
					<div className="flex items-center min-h-10">
						{survey?.survey_name}
						<span
							className={classNames(
								'text-sm capitalize px-3 py-1 rounded-full ml-3',
								{
									'bg-green-100 text-green-900':
										survey.survey_status === 'open_success',
									'bg-yellow-100 text-yellow-900':
										survey.survey_status === 'draft_success',
									'bg-red-100 text-red-900': survey.survey_status === 'closed_success'
								}
							)}
						>
							{displayStatus}
						</span>
					</div>
				}
				headerComponent={
					<>
						{(survey.survey_status === 'closed_success'  && (
							
							<Button
								text="Delete survey"
								size="small"
								variant="dark"
								disabled={survey.submissions_count}
								onClick={deleteHandler}
							/>
						)) ||
							(survey.survey_status === 'open_success' && (
								<div className="flex space-x-2">
									<a
										href={survey.survey_link}
										target="_blank"
										rel="noopener noreferrer"
										className={`border-2 rounded-lg px-8 py-2 hover:bg-blue-700 hover:text-white transition-colors duration-300 flex items-center ${
											survey.survey_link === null
												? 'border-gray-300 text-gray-300 pointer-events-none'
												: 'border-blue-700 text-blue-700'
										}`}
									>
										Preview
									</a>
									<Button
										text="Deactivate"
										size="small"
										variant="dark"
										iconLeft={<SvgWarning />}
										onClick={deactivateHandler}
									/>
								</div>
							)) || (
								<div className="flex space-x-2">
									<a
										href={survey.survey_draft_link}
										className={`border-2 rounded-lg px-8 py-2 hover:bg-blue-700 hover:text-white transition-colors duration-300 flex items-center ${
											survey.survey_draft_link === null
												? 'border-gray-300 text-gray-300 pointer-events-none'
												: 'border-blue-700 text-blue-700'
										}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										Preview
									</a>
									<Button
										text="Publish"
										size="small"
										iconLeft={<SvgSend />}
										onClick={publishHandler}
										disabled={disablePublish || survey.survey_status.includes('processing') ||
										survey.survey_status.includes('close') || survey.survey_status.includes('failed')}
									/>
									<Button
										text="Delete"
										size="small"
										variant="dark"
										disabled={survey.survey_status.includes('processing')}
										onClick={deleteHandler}
									/>
								</div>
							)}
					</>
				}
				>
				{user?.is_superadmin && (
					<Button
						text="Export json"
						size="small"
						onClick={exportData}
						disabled={false}
					/>
				)}
				<Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
					<TabList>
						{survey.survey_status !== 'draft_success' && <Tab>Dashboard</Tab>}
						{survey.survey_status !== 'closed_success' && <Tab disabled={survey.survey_status.includes('processing')}>Edit survey</Tab>}
						{survey.survey_status === 'open_success' && (
							<Tab disabled={survey?.survey_link === null}>
								Additional options
							</Tab>
						)}

						{survey.survey_status !== 'closed_success' && (
							<Tab disabled={isEmpty(survey.questions)}>Analytics types</Tab>
						)}
					</TabList>

					{survey.survey_status !== 'draft_success' && (
						<TabPanel>
							{submissionsCount === 0 && (
								<div className="w-full h-[60vh] flex flex-col items-center justify-center">
									<SvgWarning className="w-64 h-64 text-gray-200" />
									<p className="text-lg text-gray-500">
										{survey.survey_status === 'open_success'
											? 'No submissions yet'
											: 'No submissions'}
									</p>
								</div>
							)}

							{submissionsCount > 0 && params.surveyId && (
								<SubmissionsChart surveyId={params.surveyId} />
							)}

							{submissionsCount > 0 && params.surveyId && (
								<SubmissionsTable surveyId={params.surveyId} />
							)}
						</TabPanel>
					)}

					{survey.survey_status !== 'closed_success' && (
						<TabPanel>
							{survey && (
								<Formik
									initialValues={preProcessSurvey(survey)}
									validationSchema={surveyValidationSchema(availableLanguages)}
									onSubmit={handleSubmit}
									enableReinitialize= {true}
								>
									{props => {
										const {
											dirty,
											values,
											errors,
											getFieldHelpers,
											submitCount,
											isSubmitting
										} = props;

										usePrompt(
											'Changes made will be lost, are you sure you want to leave?',
											dirty && !isUpdating
										);

										const errorElement = Array.from(
											document.querySelectorAll('.errorElement')
										)?.[0];

										isSubmitting &&
											errors &&
											errorElement?.scrollIntoView({
												behavior: 'smooth',
												block: 'center'
											});
										return (
											<Form>
												<Survey
													surveyTypes={surveyTypes}
													survey={values}
													getFieldHelpers={getFieldHelpers}
													errors={
														submitCount > 0 && errors ? errors : undefined
													}
													isSubmitting={isSubmitting}
													properties={properties}
												/>
											</Form>
										);
									}}
								</Formik>
							)}
						</TabPanel>
					)}

					{survey.survey_status === 'open_success' && (
						<TabPanel>
							{survey && (
								<Formik initialValues={survey} onSubmit={() => {}}>
									{props => <SurveyOptions 
									survey={props.values} 
									/>}
								</Formik>
							)}
						</TabPanel>
					)}

					{survey.survey_status !== 'closed_success' && (
						<TabPanel>
							{survey && (
								<Formik
									initialValues={preProcessSurvey(survey)}
									validationSchema={surveyValidationSchema(availableLanguages)}
									onSubmit={handleSubmit}
								>
									{props => {
										const { values } = props;

										return (
											<Form>
												<SurveyAnalyticEdit survey={values} />
											</Form>
										);
									}}
								</Formik>
							)}
						</TabPanel>
					)}
				</Tabs>

				{isUpdating && (
					<div className="fixed z-50 top-0 left-0 flex items-center justify-center w-full h-screen bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
						<Loader />
					</div>
				)}
			</Layout>
		</>
	);
}

export default SingleSurvey;
