import axios from 'axios';
import { IEditPassword } from '.';

export const editPassword = async (data: IEditPassword) => {
	return await axios
		.put<IEditPassword>(
			`${process.env.REACT_APP_API_URL}/password_change/`,
			data
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error editing password:`, error);
			return Promise.reject(error);
		});
};
