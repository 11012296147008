import React from 'react';
import { Link } from 'react-router-dom';
import { Loader } from 'components/common';
import { ReactComponent as SvgChevron } from 'assets/icons/chevron.svg';

export type IBtnVariants = 'default' | 'dark' | 'light';

export type IBtnSizes = 'small' | 'default';

export type IBtnStates = 'base' | 'default' | 'hover' | 'focus' | 'active';

export interface IButtonProps
	extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	text?: string;
	ref?: any;
	iconLeft?: JSX.Element;
	iconRight?: JSX.Element;
	withArrow?: boolean;
	variant?: IBtnVariants;
	size?: IBtnSizes;
	href?: string;
	target?: '_self' | '_blank';
	disabled?: boolean;
	loading?: boolean;
	rounded?: 'full' | 'medium';
	onClick?: any;
}

export const Button = React.forwardRef<any, IButtonProps>(
	(
		{
			text,
			children,
			iconLeft,
			iconRight,
			className,
			withArrow,
			size = 'default',
			type,
			variant = 'default',
			onClick,
			href,
			target = '_self',
			disabled = false,
			loading,
			rounded = 'medium',
			...btnProps
		},
		ref
	) => {
		const baseStyles = `inline-flex justify-center items-center text-center outline-none transition-all duration-100 group relative ${
			disabled ? 'opacity-40' : ''
		}`;

		const variants: { [key in IBtnVariants]: Record<IBtnStates, string> } = {
			default: {
				base: 'text-white bg-blue-700',
				default: '',
				hover: 'bg-blue-600',
				focus: 'bg-blue-600',
				active: ''
			},
			dark: {
				base: 'text-white bg-blue-900',
				default: '',
				hover: 'bg-blue-700',
				focus: 'bg-blue-700',
				active: ''
			},
			light: {
				base: 'text-blue-700 bg-blue-50',
				default: '',
				hover: 'bg-blue-200',
				focus: 'bg-blue-200',
				active: ''
			}
		};

		const sizes: { [key in IBtnSizes]: string } = {
			small: 'text-base py-2 px-8',
			default: 'text-md py-4 px-12'
		};

		const _iconLeft =
			iconLeft &&
			React.cloneElement(iconLeft, {
				className: iconLeft.props.className + ' w-full h-full'
			});

		const _iconRight =
			iconRight &&
			React.cloneElement(iconRight, {
				className: iconRight.props.className + ' w-full h-full'
			});

		const generateWithPsuedoClasses = (variant: IBtnVariants) =>
			Object.entries(variants[variant])
				.map(([key, value]: [any, any]) =>
					key == 'base' || key == 'default'
						? value
						: value
								.split(' ')
								.map((className: string) => `${key}:${className}`)
								.join(' ')
				)
				.join(' ');

		const classes = `
			${baseStyles}
        	${sizes[size] || ''}
        	${variants[variant] && generateWithPsuedoClasses(variant)}
			${disabled ? 'pointer-events-none' : ''}
			${className ? className : ''}
			${rounded == 'full' ? 'rounded-full' : 'rounded-lg'}`;

		const btnContent = (
			<>
				{_iconLeft && (
					<span
						className={`
							${size == 'small' ? 'w-4 h-4' : 'w-4 h-4'} 
							${text || children || iconRight ? 'mr-1.5' : ''}
						`}
					>
						{_iconLeft}
					</span>
				)}

				{text && (
					<span
						className="whitespace-nowrap"
						dangerouslySetInnerHTML={{ __html: text }}
					></span>
				)}

				{children && children}

				{!loading && withArrow && !_iconRight && (
					<span
						className={`ml-1.5 shrink-0 ${
							size == 'small' ? 'w-3 h-3' : 'w-4 h-4'
						}`}
					>
						<SvgChevron className="w-full h-full text-current" />
					</span>
				)}

				{_iconRight && (
					<span
						className={`
							${size == 'small' ? 'w-4 h-4' : 'w-4 h-4'} 
							${text || iconLeft ? 'ml-1.5' : ''}
						`}
					>
						{_iconRight}
					</span>
				)}
			</>
		);

		return href ? (
			<Link
				to={href}
				ref={ref}
				className={classes}
				target={target}
				rel={target === '_blank' ? 'noopener noreferrer' : ''}
			>
				{loading && (
					<Loader
						color="white"
						size="small"
						className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
					/>
				)}
				<span className={`flex items-center ${loading ? 'opacity-0' : ''}`}>
					{btnContent}
				</span>
			</Link>
		) : (
			<button
				ref={ref}
				{...(type ? { type: type } : {})}
				className={classes}
				onClick={onClick}
				{...btnProps}
				disabled={disabled}
			>
				{loading && (
					<Loader
						color="white"
						size="small"
						className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"
					/>
				)}
				<span className={`flex items-center ${loading ? 'opacity-0' : ''}`}>
					{btnContent}
				</span>
			</button>
		);
	}
);
