import { Navigate, useLocation } from 'react-router-dom';

import { useUserStateContext } from 'context';

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const { token } = useUserStateContext();
	const location = useLocation();

	if (!token) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
};
