import React from 'react';
import { ISurveyUI } from 'services/surveys/interfaces';

export const SurveyInfo: React.FC<{ survey: ISurveyUI }> = ({ survey }) => {
	return (
		<div className="ml-20 xxl:ml-56 print:hidden">
			<div className="px-8 pt-4 text-gray-400 text-sm flex space-x-4  w-full mx-auto max-w-5xl ">
				<p>
					{survey.created_by && (
						<>
							Created by <strong>{survey.created_by}</strong>{' '}
						</>
					)}
					{survey.created_on && (
						<>on {new Date(survey.created_on).toLocaleString()}</>
					)}
				</p>
				<p>
					{survey.updated_by && (
						<>
							Updated by <strong>{survey.updated_by}</strong>
						</>
					)}{' '}
					{survey.updated_on && (
						<>
							on <strong>{new Date(survey.updated_on).toLocaleString()}</strong>
						</>
					)}
				</p>
			</div>
		</div>
	);
};
