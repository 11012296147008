import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { IEmailTemplate } from './interfaces';

export const getEmailTemplates = async () => {
	return await axios
		.get<IEmailTemplate[]>(`${process.env.REACT_APP_API_URL}/email_templates/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting email templates:`, error);
			return Promise.reject(error);
		});
};

export const useGetEmailTemplatesQuery = () => {
	return useQuery(['email_templates'] as QueryKey, () => getEmailTemplates(), {
		refetchOnWindowFocus: false
	});
};
