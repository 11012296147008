import { ILanguageState } from 'context';
import { createAction } from './actions/actions';

export enum LanguageActions {
	SetCurrentLanguage = '[LanguageContext] Set current language',
	SetDefaultLanguage = '[LanguageContext] Set default language',
	SetAvailableLanguages = '[LanguageContext] Set available languages'
}

export type LanguageActionTypes =
	| ILanguageSetCurrentAction
	| ILanguageSetDefaultAction
	| ILanguageSetAvailableAction;

export const languageReducer = (
	state: ILanguageState,
	action: LanguageActionTypes
): ILanguageState => {
	switch (action.type) {
		case LanguageActions.SetCurrentLanguage: {
			return {
				...state,
				currentLanguage: action.payload
			};
		}
		case LanguageActions.SetDefaultLanguage: {
			return {
				...state,
				defaultLanguage: action.payload
			};
		}
		case LanguageActions.SetAvailableLanguages: {
			return {
				...state,
				availableLanguages: action.payload
			};
		}
		default: {
			throw new Error('LanguageReducer: This reducer action does not exist');
		}
	}
};

interface ILanguageSetCurrentAction {
	type: typeof LanguageActions.SetCurrentLanguage;
	payload: string;
}
export const setCurrentLanguage = createAction<
	ILanguageSetCurrentAction['payload']
>(LanguageActions.SetCurrentLanguage);
interface ILanguageSetDefaultAction {
	type: typeof LanguageActions.SetDefaultLanguage;
	payload: string;
}
export const setDefaultLanguage = createAction<
	ILanguageSetDefaultAction['payload']
>(LanguageActions.SetDefaultLanguage);

interface ILanguageSetAvailableAction {
	type: typeof LanguageActions.SetAvailableLanguages;
	payload: string[];
}
export const setAvailableLanguages = createAction<
	ILanguageSetAvailableAction['payload']
>(LanguageActions.SetAvailableLanguages);
