import React from 'react';
import { useField, useFormikContext } from 'formik';
import {
	CustomCheckboxGroup,
	ICustomCheckboxGroupProps
} from 'components/form-elements';

export const FieldCheckboxGroup: React.FC<
	ICustomCheckboxGroupProps
> = props => {
	const { className, name, children, ...rest } = props;
	const { setFieldValue } = useFormikContext();
	const [field] = useField(props.name);
	return (
		<div className={className}>
			<CustomCheckboxGroup
				{...field}
				{...props}
				onChange={(e: any) => {
					setFieldValue(name, e);
					//@todo: vidit šta je bija eror ovde??
					/* field.onChange(e); */
				}}
				{...rest}
			>
				{children}
			</CustomCheckboxGroup>
		</div>
	);
};

export default FieldCheckboxGroup;
