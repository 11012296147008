import * as Yup from 'yup';

export const notificationRuleValidationSchema = Yup.object().shape({
	rule_name: Yup.string()
		.min(2, 'Minimum 2 characters')
		.required('This field is required'),
	notifications: Yup.array()
		.of(
			Yup.object().shape({
				notification_type_id: Yup.number().required('This field is required'),
				contacts: Yup.array()
					.of(
						Yup.object().shape({
							contact_id: Yup.number(),
							contact: Yup.mixed().required('This field is required')
							.test('is-email','Invalid email', function (fieldValue, schema:any){
								const not_type_id= schema.from[1].value.notification_type_id;
								if(not_type_id==1 &&!isValidEmail(fieldValue)) {
									return false;
								}
								return true;
							})
						})
					)
					.min(1, 'At least 1 contact')
			})
		)
		.min(1, 'At least 1 notification'),
	question_rules: Yup.array()
		.of(
			Yup.object().shape({
				survey_id: Yup.number().required('This field is required'),
				questions: Yup.array()
					.of(
						Yup.object().shape({
							question_id: Yup.number().required('This field is required'),
							comparator: Yup.string().required('This field is required'),
							value: Yup.string().required('This field is required')
						})
					)
					.min(1, 'At least 1 question')
			})
		)
		.min(1, 'At least 1 question')
});

function isValidEmail(email:string |undefined) {

	if (email==undefined) return false;
	// Regular expression for validating email addresses
	const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
	
	return emailRegex.test(email);
  }