import classNames from 'classnames';
import React from 'react';
import { Column, useTable } from 'react-table';

import { useGetEmailByIdQuery } from 'services/emails';
import { IEmailByIdResults } from 'services/emails/interfaces';

import { Loader } from 'components/common';
import { IOption } from 'components/form-elements';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';

export interface IMailBatchTableProps {
	mailBatch: number;
	filter: IOption;
}

export const MailBatchTable: React.FC<IMailBatchTableProps> = ({
	mailBatch,
	filter
}) => {
	const { data: email, isLoading } = useGetEmailByIdQuery(mailBatch);

	const columns: Column<IEmailByIdResults>[] = React.useMemo(() => {
		return [
			{
				Header: 'Email to',
				accessor: 'to_email',
				sortDescFirst: true
			},
			{
				Header: 'Delivery status',
				accessor: 'delivered_status',
				sortDescFirst: true
			}
		];
	}, []);

	const data: IEmailByIdResults[] = React.useMemo(() => {
		if (!email) {
			return [];
		}
		return email
			?.filter(item => {
				if (filter.value === 'ALL') {
					return item;
				}
				return item.delivered_status === filter.value;
			})
			.map((item: IEmailByIdResults) => {
				return {
					to_email: item.to_email,
					delivered_status: item.delivered_status
				};
			});
	}, [email, filter]);

	const {
		//table
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	if (!data || !email || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<div className="mt-6 mb-10 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
			<table
				{...getTableProps()}
				className="min-w-full divide-y divide-gray-200"
			>
				<thead className="bg-gray-50">
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => {
								return (
									<th
										className={`px-6 py-3 ${
											i === 1 ? 'text-right' : 'text-left'
										} text-xs font-medium text-gray-500 uppercase tracking-wider`}
									>
										<span className="h-4">{column.render('Header')}</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);

						return (
							<tr
								{...row.getRowProps()}
								className={`${i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'}`}
							>
								{row.cells.map((cell, i) => {
									return (
										<td
											{...cell.getCellProps()}
											className="whitespace-nowrap text-sm p-0"
										>
											<span
												className={`px-6 py-4 block whitespace-normal line-clamp-2 ${
													i === 1 ? 'text-right' : 'text-left'
												}`}
											>
												{(cell.column.id === 'delivered_status' && (
													<span
														className={classNames(
															'font-bold text-xs border-2 rounded-lg py-1 px-3',
															{
																'bg-green-100 text-green-500 border-green-500':
																	cell.value === 'DELIVERED',
																'bg-red-100 text-red-500 border-red-500':
																	cell.value === 'NOT_DELIVERED',
																'bg-blue-100 text-blue-500 border-blue-500':
																	cell.value === 'PROCESSED',
																'bg-slate-100 text-slate-500 border-slate-500':
																	cell.value === 'UNSUBSCRIBED',
																'bg-yellow-100 text-yellow-500 border-yellow-500':
																	cell.value === 'PENDING'
															}
														)}
													>
														{cell.value.split('_').join(' ')}
													</span>
												)) || <span>{cell.render('Cell')}</span>}
											</span>
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			{data.length === 0 && (
				<div className="flex flex-col items-center justify-center p-8">
					<SvgWarning className="w-40 h-40 text-gray-200" />
					<p className="mt-2">Nothing to show</p>
				</div>
			)}
		</div>
	);
};
