import React, { ReactElement } from 'react';
import Popper from 'popper.js';

import { useOnClickOutside } from 'hooks';

import { ReactComponent as SvgDots } from 'assets/icons/dots.svg';

export interface IPopoverProps {
	className?: string;
	popoverIcon?: ReactElement;
	closeOnClick?: boolean;
}

export const Popover: React.FC<IPopoverProps> = ({
	className,
	popoverIcon,
	closeOnClick = true,
	children
}) => {
	const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
	const btnDropdownRef = React.useRef<HTMLButtonElement>(null);
	const popoverDropdownRef = React.useRef<HTMLDivElement>(null);

	const openDropdownPopover = () => {
		const btnCurrent = btnDropdownRef.current;
		const popoverCurrent = popoverDropdownRef.current;
		if (btnCurrent && popoverCurrent) {
			new Popper(btnCurrent, popoverCurrent, {
				placement: 'bottom-end'
			});
			setDropdownPopoverShow(true);
		}
	};
	const closeDropdownPopover = () => {
		setDropdownPopoverShow(false);
	};
	useOnClickOutside(btnDropdownRef, () => {
		if (closeOnClick) {
			setDropdownPopoverShow(false);
		}
	});

	return (
		<div
			data-testid="popover_btn"
			className="relative inline-flex align-middle"
		>
			<button
				type="button"
				aria-label="open dropdown menu"
				className={`${
					className || ''
				} p-2 flex rounded-full hover:bg-blue-50 outline-none focus:outline-none focus:bg-blue-100 transition-all duration-150`}
				ref={btnDropdownRef}
				onClick={() => {
					dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
				}}
			>
				{popoverIcon ? popoverIcon : <SvgDots />}
			</button>
			<div
				ref={popoverDropdownRef}
				className={`
				${dropdownPopoverShow ? 'block ' : 'hidden '}
					min-w-[160px] bg-white z-50 float-left py-2.5 overflow-hidden text-sm rounded-lg shadow-lg mt-1'
				`}
			>
				{children}
			</div>
		</div>
	);
};
