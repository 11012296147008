import axios from 'axios';
import { useToast } from 'hooks';
import { userLoginAction } from 'reducers';

export interface ILoginData {
	username: string;
	password: string;
}

export interface IAuthData {
	refresh: string;
	access: string;
}

export const userLogin = (data: ILoginData, userDispatch: any) => {
	const show = useToast();
	return axios
		.post<IAuthData>(`${process.env.REACT_APP_API_URL}/token/`, data)
		.then(response => {
			userDispatch(userLoginAction({auth: response.data, username: data.username}));
			return Promise.resolve(response.data);
		})
		.catch((err: any) => {
			show({
				headerText: `Login was not successful`,
				message: 'Please check your credentials and try again.',
				type: 'error'
			});
			return Promise.reject(err);
		});
};
