import React from 'react';
import { Column, usePagination, useTable } from 'react-table';

import { Loader } from 'components/common';


import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { IProperty } from 'services/properties/interfaces';
import { useGetProperties } from 'services/properties';

export interface IPropertiesTableProps {
	onDelete: (user: IProperty) => void;
	onEdit: (user: IProperty) => void;
}

interface ITableData {
	name?: string;
	address?: string;
	city?: string;
	actions?: JSX.Element;
}

export const PropertiesTable: React.FC<IPropertiesTableProps> = ({
	onDelete,
	onEdit,
}) => {
	const { data: properties, isLoading } = useGetProperties();
	const columns: Column<ITableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'Name',
				accessor: 'name',
				sortDescFirst: true
			},
			{
				Header: 'Address',
				accessor: 'address',
				sortDescFirst: true
			},
			{
				Header: 'City',
				accessor: 'city',
				sortDescFirst: true
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				sortDescFirst: true
			}
		];
	}, []);

	const data: ITableData[] = React.useMemo(() => {
		if (!properties) {
			return [];
		}
		return properties?.results?.map((property: IProperty) => {
			return {
				name: property.name,
				address: property.address,
				city: property.city,				
				actions: (
					<span className={`flex`}>
						<button
							title="Edit property"
							role="button"
							aria-label="edit property"
							onClick={() => onEdit(property)}
							className="flex items-center justify-center hover:text-blue-700 mr-1 w-8 h-8"
						>
							<SvgEdit className="w-5 h-5" />
						</button>

						<button
							title="Delete property"
							role="button"
							aria-label="delete property"
							onClick={() => onDelete(property)}
							className={`flex items-center justify-center hover:text-blue-700 mr-1 w-8 h-8`}
						>
							<SvgTrash className="w-5 h-5" />
						</button>

			
					</span>
				)
			};
		});
	}, [properties]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				initialState: {
					pageSize: 100
				},
				manualPagination: true,
				pageCount: properties?.length
			},
			usePagination
		);

	

	if (!data || !properties || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<>
			<div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
				<table
					{...getTableProps()}
					className="min-w-full divide-y divide-gray-200"
				>
					<thead className="bg-gray-50">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										<React.Fragment key={column.id}>
											{(column.id === 'actions' && (
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													<span className="h-4">{column.render('Header')}</span>
												</th>
											)) || (
												<th
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-blue-700 cursor-pointer"
												>
													<span className="flex items-center">
														<span className="h-4">
															{column.render('Header')}
														</span>
													</span>
												</th>
											)}
										</React.Fragment>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={`${i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'}`}
								>
									{row.cells.map(cell => {
										return (
											<td
												{...cell.getCellProps()}
												className="whitespace-nowrap text-sm p-0"
											>
												{(cell.column.id === 'actions' && (
													<span className="px-6 py-4 block">
														{cell.render('Cell')}
													</span>
												)) || (
														<span className="px-6 py-4 block whitespace-normal line-clamp-2">
															{cell.render('Cell')}
														</span>
													)}
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</>
	);
};
