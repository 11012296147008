import axios from 'axios';
import { IProperty } from './interfaces';

export const editProperty = async (id: number, data: IProperty) => {
	return await axios
		.put<any>(`${process.env.REACT_APP_API_URL}/property_api/${id}/`, data)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error editing property:`, error);
			return Promise.reject(error);
		});
};
