import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { ISurveyStatus } from './interfaces';

export type IUseGetSurveysOptions = UseQueryOptions<any, any, any>;

export const getSurveys = async (config?: IGetSurveysRequestConfig) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/survey_api/`, {
			...config
		})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting surveys:`, error);
			return Promise.reject(error);
		});
};

export const useGetSurveysQuery = (
	config?: IGetSurveysRequestConfig,
	queryOptions?: IUseGetSurveysOptions
) => {
	return useQuery(
		['surveys', config?.params] as QueryKey,
		() => getSurveys(config),
		queryOptions
	);
};

interface IGetSurveysRequestConfig extends AxiosRequestConfig {
	params?: IGetSurveysQueryParams;
}

type IGetSurveysQueryParams = {
	page?: number;
	page_size?: number;
	ordering?: string;
	survey_status?: ISurveyStatus;
};
