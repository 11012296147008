import React from 'react';
import { IAnswerType, IAppearanceType } from 'services/surveys/interfaces';

import TextSingleLine from 'assets/images/appearances/text-single_line.png';
import TextMultiLine from 'assets/images/appearances/text-multi_line.png';

import SelectOneDefault from 'assets/images/appearances/select_one-default.png';
import SelectOneAutocomplete from 'assets/images/appearances/select_one-autocomplete.png';
import SelectOneCompact from 'assets/images/appearances/select_one-compact.png';
import SelectOneHorizontal from 'assets/images/appearances/select_one-horizontal.png';
import SelectOneLikert from 'assets/images/appearances/select_one-likert_scale.png';
import SelectOneMinimal from 'assets/images/appearances/select_one-minimal.png';

import SelectMultipleDefault from 'assets/images/appearances/select_multiple-default.png';
import SelectMultipleCompact from 'assets/images/appearances/select_multiple-compact.png';
import SelectMultipleHorizontal from 'assets/images/appearances/select_multiple-horizontal.png';
import SelectMultipleMinimal from 'assets/images/appearances/select_multiple-minimal.png';

import ImageDefault from 'assets/images/appearances/image-default.png';
import ImageAnnotate from 'assets/images/appearances/image-annotate.png';
import ImageSignature from 'assets/images/appearances/image-signature.png';

import DateMonthYear from 'assets/images/appearances/date-month_year.png';
import DateYear from 'assets/images/appearances/date-year.png';
import DateNoCalendar from 'assets/images/appearances/date-no_calendar.png';

import Range from 'assets/images/appearances/range.png';
import Integer from 'assets/images/appearances/integer.png';
import Geopoint from 'assets/images/appearances/geopoint.png';
import Email from 'assets/images/appearances/email.png';
import Decimal from 'assets/images/appearances/decimal.png';
import Country from 'assets/images/appearances/country.png';
import EmojiRating from 'assets/images/appearances/emoji_rating.png';
import Age from 'assets/images/appearances/age.png';
import Sex from 'assets/images/appearances/sex.png';
import Nps from 'assets/images/appearances/nps.png';
import Rating from 'assets/images/appearances/rating.png';
import MatrixRating from 'assets/images/appearances/matrix_rating.png';

export interface IAppearancePreviewProps {
	type: IAnswerType;
	appearance?: IAppearanceType;
}

export const getAppearancePreview = (
	type: IAnswerType,
	appearance?: IAppearanceType
) => {
	switch (true) {
		//text
		case type === 'text' && appearance === '':
			return TextSingleLine;
		case type === 'text' && appearance === 'multiline':
			return TextMultiLine;

		//select_one
		case type === 'select_one' && appearance === 'default':
			return SelectOneDefault;
		case type === 'select_one' && appearance === 'compact':
			return SelectOneCompact;
		case type === 'select_one' && appearance === 'minimal':
			return SelectOneMinimal;
		case type === 'select_one' && appearance === 'likert':
			return SelectOneLikert;
		case type === 'select_one' && appearance === 'autocomplete':
			return SelectOneAutocomplete;
		case type === 'select_one' && appearance === 'horizontal':
			return SelectOneHorizontal;

		//select_multiple
		case type === 'select_multiple' && appearance === 'default':
			return SelectMultipleDefault;
		case type === 'select_multiple' && appearance === 'compact':
			return SelectMultipleCompact;
		case type === 'select_multiple' && appearance === 'minimal':
			return SelectMultipleMinimal;
		case type === 'select_multiple' && appearance === 'horizontal':
			return SelectMultipleHorizontal;

		//property
		case type === 'property' && appearance === 'default':
			return SelectMultipleDefault;
		case type === 'property' && appearance === 'compact':
			return SelectMultipleCompact;
		case type === 'property' && appearance === 'minimal':
			return SelectMultipleMinimal;
		case type === 'property' && appearance === 'horizontal':
			return SelectMultipleHorizontal;

		//image
		case type === 'image' && appearance === 'image_default':
			return ImageDefault;
		case type === 'image' && appearance === 'annotate':
			return ImageAnnotate;
		case type === 'image' && appearance === 'signature':
			return ImageSignature;

		//date
		case type === 'date' && appearance === 'month-year':
			return DateMonthYear;
		case type === 'date' && appearance === 'year':
			return DateYear;
		case type === 'date' && appearance === 'no-calendar':
			return DateNoCalendar;

		//others
		case type === 'emoji_rating':
			return EmojiRating;
		case type === 'range':
			return Range;
		case type === 'integer':
			return Integer;
		case type === 'decimal':
			return Decimal;
		case type === 'geopoint':
			return Geopoint;
		case type === 'email':
			return Email;
		case type === 'country':
			return Country;
		case type === 'age':
			return Age;
		case type === 'sex':
			return Sex;
		case type === 'nps':
			return Nps;
		case type === 'rating':
			return Rating;
		case type === 'matrix_rating':
			return MatrixRating;
		default:
			return '';
	}
};

export const AppearancePreview: React.FC<IAppearancePreviewProps> = ({
	type,
	appearance
}) => {
	return (
		<React.Fragment>
			<span className="block text-sm font-medium text-gray-700 mb-1">
				Appearance preview
			</span>
			<img
				src={getAppearancePreview(type, appearance)}
				alt="appearance preview"
				className="max-w-sm"
			/>
		</React.Fragment>
	);
};
