import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const getSubmissionById = async (id: string) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/submissions_api/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting submission:`, error);
			return Promise.reject(error);
		});
};

export const useGetSubmissionById = (id?: string) => {
	return useQuery(
		['single_submission', id] as QueryKey,
		() =>
			id
				? getSubmissionById(id)
				: Promise.reject('Error in useGetSubmissionById: No id provided.'),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};
