import { Button, FormikCheckbox } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { Form, Formik } from 'formik';
import { useToast } from 'hooks';
import React from 'react';
import { editUser, IUserInfo } from 'services/users';
import * as Yup from 'yup';

export interface IEditUserFormProps {
	user: IUserInfo;
	className?: string;
	onSuccess?: () => void;
}

export const EditUserForm: React.FC<IEditUserFormProps> = ({
	user,
	className,
	onSuccess
}) => {
	const show = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const handleSubmit = (values: IUserInfo) => {
		setIsSubmitting(true);

		if (user.id) {
			editUser(user.id, values)
				.then(() => {
					show({
						type: 'success',
						headerText: 'Edited!',
						message: 'Successfully edited user.'
					});
					setIsSubmitting(false);
					onSuccess && onSuccess();
				})
				.catch(e => {
					console.info(e);
					setIsSubmitting(false);
				});
		}

		setIsSubmitting(false);
	};

	return (
		<Formik
			initialValues={{
				first_name: user.first_name,
				last_name: user.last_name,
				email: user.email,
				is_admin: user.is_admin
			}}
			validationSchema={Yup.object().shape({
				first_name: Yup.string().required('This field is required'),
				last_name: Yup.string().required('This field is required'),
				email: Yup.string()
					.email('Invalid email format')
					.required('This field is required')
			})}
			onSubmit={handleSubmit}
		>
			{props => {
				const { errors, submitCount } = props;

				return (
					<Form id="user-edit-form" className={className || ''}>
						<FieldInput
							name="first_name"
							id="first_name"
							label="First name"
							placeholder="Add name"
							className="mb-6"
							error={
								(submitCount > 0 && errors.first_name && errors.first_name) ||
								''
							}
						/>
						<FieldInput
							name="last_name"
							id="last_name"
							label="Last name"
							placeholder="Add last name"
							className="mb-6"
							error={
								(submitCount > 0 && errors.last_name && errors.last_name) || ''
							}
						/>
						<FieldInput
							name="email"
							id="email"
							label="Email"
							placeholder="Add email"
							type="email"
							className="mb-6"
							error={(submitCount > 0 && errors.email && errors.email) || ''}
						/>

						<FormikCheckbox
							name="is_admin"
							id="is_admin"
							label="Admin profile"
							className="mb-10"
							variant="toggler"
						/>

						<Button
							text="Update"
							type="submit"
							disabled={isSubmitting}
							loading={isSubmitting}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
