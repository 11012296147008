import axios from 'axios';

export const deactivateSurvey = async (id: string) => {
	return await axios
		.post<any>(`${process.env.REACT_APP_API_URL}/close_survey/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error deactivating survey:`, error);
			return Promise.reject(error);
		});
};
