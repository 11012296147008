import { IOption } from 'components/form-elements';
import { IProperty, IPropertyType } from 'services/properties/interfaces';

export const getPropertyTypeOptions = (propertyTypes? : IPropertyType[]) : IOption[] =>{
    const propertyTypeOptions = propertyTypes ? propertyTypes.map((propertyType) => ({
        value: propertyType.property_type_id,
        display: propertyType.type_name,
      })): [];
    return propertyTypeOptions;
};

export const getPropertiesOptions = (defaultOption: string |null,properties? : IProperty[]) : IOption[] =>{
  let propertyOptions : IOption[] = [];
  if(defaultOption){
    propertyOptions=[{ value: null, display: defaultOption }];
  }
  if(properties){
    propertyOptions = propertyOptions.concat(properties.map((property) => ({
      value: property.property_id,
      display: `${property.name}` ,
    })))
  }
  
  return propertyOptions;
};