import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

export type IUseGetTemplatesOptions = UseQueryOptions<any, any, any>;

export const getTemplates = async (config?: IGetTemplatesRequestConfig) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/get_survey_template_list/`, {
			...config
		})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting templates:`, error);
			return Promise.reject(error);
		});
};

export const useGetTemplatesQuery = (
	config?: IGetTemplatesRequestConfig,
	queryOptions?: IUseGetTemplatesOptions
) => {
	return useQuery(
		['templates', config?.params] as QueryKey,
		() => getTemplates(config),
		queryOptions
	);
};

interface IGetTemplatesRequestConfig extends AxiosRequestConfig {
	params?: IGetTemplatesQueryParams;
}

type IGetTemplatesQueryParams = {
	page?: number;
	page_size?: number;
	ordering?: string;
};
