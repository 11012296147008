import React from 'react';
import { Column, HeaderGroup, usePagination, useTable } from 'react-table';

import { Loader } from 'components/common';

import { ReactComponent as SvgChevron } from '../../../assets/icons/chevron.svg';
import { ReactComponent as SvgMinus } from '../../../assets/icons/minus.svg';
import { useSearchParamsWithHelpers } from 'hooks';
import { useGetEmailsQuery } from 'services/emails';
import { IEmailResults } from 'services/emails/interfaces';
import { CustomSelect } from 'components/form-elements';
import classnames from 'classnames';

export interface IMailTableProps {
	onRowClick: (id: number) => void;
}

interface ITableData {
	id: number;
	survey_name?: string;
	emails_numb?: string | number;
	created_date?: string;
	time?: string;
	unique?: string;
	sending_status?: string;
}

export const MailTable: React.FC<IMailTableProps> = ({ onRowClick }) => {
	const initialPage = 1;
	const initialPerPage = 10;
	const initialOrdering = '-created_date';

	const { updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const page = getSearchParamWithInitial('page', initialPage);
	const page_size = getSearchParamWithInitial('page_size', initialPerPage);
	const ordering = getSearchParamWithInitial('ordering', initialOrdering);

	const { data: emails, isLoading } = useGetEmailsQuery(
		{
			params: {
				...(page !== initialPage ? { page } : {}),
				...(page_size !== initialPerPage ? { page_size } : {}),
				...(ordering !== initialOrdering
					? { ordering }
					: { ordering: initialOrdering })
			}
		},
		{
			refetchOnWindowFocus: false
		}
	);

	const totalItems = emails?.count;
	const pageCount = totalItems === 0 ? 1 : Math.ceil(totalItems / page_size);

	const columns: Column<ITableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'ID',
				accessor: 'id',
				sortDescFirst: true
			},
			{
				Header: 'Survey name',
				accessor: 'survey_name',
				sortDescFirst: true
			},
			{
				Header: 'Total emails',
				accessor: 'emails_numb',
				sortDescFirst: true
			},
			{
				Header: 'Created',
				accessor: 'created_date',
				sortDescFirst: true
			},
			{
				Header: 'Time',
				accessor: 'time',
				sortDescFirst: true
			},
			{
				Header: 'Unique',
				accessor: 'unique',
				sortDescFirst: true
			},
			{
				Header: 'Status',
				accessor: 'sending_status',
				sortDescFirst: true
			}
		];
	}, []);

	const data: ITableData[] = React.useMemo(() => {
		if (!emails) {
			return [];
		}
		return emails?.results.map((email: IEmailResults) => {
			const date = new Date(email.created_date).toLocaleDateString();
			const time = new Date(email.created_date).toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit'
			});

			return {
				id: email.batch_id,
				survey_name: email.survey_name,
				emails_numb: email.emails_numb,
				created_date: date,
				time: time,
				unique: String(email.unique),
				sending_status: email.sending_status.toLowerCase()
			};
		});
	}, [emails]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				initialState: {
					pageSize: page_size,
					hiddenColumns: ['id']
				},
				manualPagination: true,
				pageCount: pageCount
			},
			usePagination
		);

	const setOrderSearchParam = (column: HeaderGroup<ITableData>) => {
		const alreadySet = column.id === ordering;
		const newOrdering = alreadySet ? `-${column.id}` : column.id;
		const shouldRemove = newOrdering === initialOrdering;

		updateSearchParams({
			ordering: !shouldRemove ? newOrdering : undefined
		});
	};

	if (!data || !emails || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<>
			<div className="mt-6 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
				<table
					{...getTableProps()}
					className="min-w-full divide-y divide-gray-200"
				>
					<thead className="bg-gray-50">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										<React.Fragment key={column.id}>
											{(column.id === 'time' && (
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													<span className="h-4">{column.render('Header')}</span>
												</th>
											)) || (
												<th
													onClick={() => setOrderSearchParam(column)}
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-blue-700 cursor-pointer"
												>
													<span className="flex items-center">
														<span className="h-4">
															{column.render('Header')}
														</span>

														{((ordering === column.id ||
															ordering === `-${column.id}`) && (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgChevron
																	className={`w-3 h-3 ${
																		ordering === column.id
																			? 'rotate-90'
																			: '-rotate-90'
																	}`}
																/>
															</span>
														)) || (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgMinus className="w-3 h-3" />
															</span>
														)}
													</span>
												</th>
											)}
										</React.Fragment>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);

							return (
								<tr
									{...row.getRowProps()}
									className={`hover:bg-blue-50 cursor-pointer ${
										i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'
									}`}
								>
									{row.cells.map(cell => {
										return (
											<td
												{...cell.getCellProps()}
												className="whitespace-nowrap text-sm p-0"
												title={
													cell.column.id === 'survey_name' ? cell.value : ''
												}
											>
												<span
													className="px-6 py-4 block whitespace-normal line-clamp-2"
													onClick={() => onRowClick(row.values.id)}
													role="button"
												>
													<span
														className={classnames('', {
															'text-xs px-2.5 capitalize py-1 rounded-full':
																cell.column.id === 'sending_status',
															'bg-red-100 text-red-900':
																cell.value === 'not sent',
															'bg-yellow-100 text-yellow-900':
																cell.value === 'sending',
															'bg-green-100 text-green-900':
																cell.value === 'sent'
														})}
													>
														{cell.render('Cell')}
													</span>
												</span>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{/* PAGINATION */}
			<div className="flex items-center justify-center relative mt-10">
				<div className="flex items-center justify-center gap-4">
					<button
						aria-label="previous page"
						disabled={page === 1}
						onClick={() => {
							updateSearchParams({
								page: page - 1 !== 1 ? String(page - 1) : undefined
							});
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === 1
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4 rotate-180" />
					</button>

					<p
						className={`h-10 w-20 px-4 flex items-center justify-center bg-white rounded-lg text-sm text-gray-900 border border-gray-300 shadow-sm text-right`}
					>
						<span>
							{page} / {pageCount}
						</span>
					</p>

					<button
						aria-label="next page"
						disabled={page === pageCount}
						onClick={() => {
							updateSearchParams({ page: String(page + 1) });
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === pageCount
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4" />
					</button>
				</div>

				<CustomSelect
					name="page_size"
					options={[
						{
							value: 10,
							display: '10'
						},
						{
							value: 25,
							display: '25'
						},
						{
							value: 40,
							display: '40'
						}
					]}
					initialValue={{
						value: page_size,
						display: String(page_size)
					}}
					onChange={e => {
						updateSearchParams({
							page_size:
								e.value === initialPerPage ? undefined : String(e.value),
							page: undefined
						});
					}}
					className="absolute right-0 top-1/2 -translate-y-1/2"
				/>
			</div>
		</>
	);
};
