import { ISurveyCollapseState } from 'context';
import { createAction } from './actions/actions';

export enum SurveysCollapseActions {
	ExpandQuestion = '[SurveysCollapseContext] Expand Question',
	CollapseQuestion = '[SurveysCollapseContext] Collapse Question',
	ToggleQuestion = '[SurveysCollapseContext] Toggle Question',

	ExpandSection = '[SurveysCollapseContext] Expand Section',
	CollapseSection = '[SurveysCollapseContext] Collapse Section',
	ToggleSection = '[SurveysCollapseContext] Toggle Section'
}

export type SurveysCollapseActionTypes =
	| ISurveysExpandQuestionAction
	| ISurveysCollapseQuestionAction
	| ISurveysToggleQuestionAction
	| ISurveysExpandSectionAction
	| ISurveysCollapseSectionAction
	| ISurveysToggleSectionAction;

export const surveyCollapseReducer = (
	state: ISurveyCollapseState,
	action: SurveysCollapseActionTypes
) => {
	switch (action.type) {
		case SurveysCollapseActions.ExpandQuestion: {
			return {
				...state,
				questions: [...state.questions, action.payload]
			};
		}
		case SurveysCollapseActions.CollapseQuestion: {
			return {
				...state,
				questions: state.questions.filter(
					(question: string) => question !== action.payload
				)
			};
		}
		case SurveysCollapseActions.ToggleQuestion: {
			return {
				...state,
				questions: state.questions.includes(action.payload)
					? state.questions.filter(
							(question: string) => question !== action.payload
					  )
					: [...state.questions, action.payload]
			};
		}
		case SurveysCollapseActions.ExpandSection: {
			return {
				...state,
				sections: [...state.sections, action.payload]
			};
		}
		case SurveysCollapseActions.CollapseSection: {
			return {
				...state,
				sections: state.sections.filter(
					(section: string) => section !== action.payload
				)
			};
		}
		case SurveysCollapseActions.ToggleSection: {
			return {
				...state,
				sections: state.sections.includes(action.payload)
					? state.sections.filter(
							(section: string) => section !== action.payload
					  )
					: [...state.sections, action.payload]
			};
		}
		default: {
			throw new Error(
				'SurveysCollapseReducer: This reducer action does not exist'
			);
		}
	}
};

interface ISurveysExpandQuestionAction {
	type: typeof SurveysCollapseActions.ExpandQuestion;
	payload: string;
}
export const expandQuestion = createAction<
	ISurveysExpandQuestionAction['payload']
>(SurveysCollapseActions.CollapseQuestion);

interface ISurveysCollapseQuestionAction {
	type: typeof SurveysCollapseActions.CollapseQuestion;
	payload: string;
}
export const collapseQuestion = createAction<
	ISurveysCollapseQuestionAction['payload']
>(SurveysCollapseActions.CollapseQuestion);

interface ISurveysToggleQuestionAction {
	type: typeof SurveysCollapseActions.ToggleQuestion;
	payload: string;
}
export const toggleQuestion = createAction<
	ISurveysToggleQuestionAction['payload']
>(SurveysCollapseActions.ToggleQuestion);

interface ISurveysExpandSectionAction {
	type: typeof SurveysCollapseActions.ExpandSection;
	payload: string;
}
export const expandSection = createAction<
	ISurveysExpandSectionAction['payload']
>(SurveysCollapseActions.CollapseSection);

interface ISurveysCollapseSectionAction {
	type: typeof SurveysCollapseActions.CollapseSection;
	payload: string;
}
export const collapseSection = createAction<
	ISurveysCollapseSectionAction['payload']
>(SurveysCollapseActions.CollapseSection);

interface ISurveysToggleSectionAction {
	type: typeof SurveysCollapseActions.ToggleSection;
	payload: string;
}
export const toggleSection = createAction<
	ISurveysToggleSectionAction['payload']
>(SurveysCollapseActions.ToggleSection);
