import { IUserState } from 'context';
import { IRefreshTokenResponse, IAuthData } from 'services/auth';
import { createAction } from './actions/actions';

export enum UserActions {
	UserLogin = '[UserContext] User Login',
	UserRefreshToken = '[UserContext] User Refresh Token',
	UserLogoutAction = '[UserContext] User Logout'
}

export type UserActionTypes =
	| IUserLoginAction
	| IUserRefreshTokenAction
	| IUserLogoutAction;

export const userReducer = (state: IUserState, action: UserActionTypes) => {
	switch (action.type) {
		case UserActions.UserLogin: {
			return {
				...state,
				token: action.payload.auth.access,
				refresh_token: action.payload.auth.refresh,
				username: action.payload.username
			};
		}
		case UserActions.UserRefreshToken: {
			return {
				...state,
				token: action.payload.access
			};
		}
		case UserActions.UserLogoutAction: {
			return {
				token: '',
				refresh_token: '',
				username: ''
			};
		}
		default: {
			throw new Error('UserReducer: This reducer action does not exist');
		}
	}
};

interface IUserLoginAction {
	type: typeof UserActions.UserLogin;
	payload: {
		auth: IAuthData;
		username: string;
	};
}
export const userLoginAction = createAction<IUserLoginAction['payload']>(
	UserActions.UserLogin
);

interface IUserRefreshTokenAction {
	type: typeof UserActions.UserRefreshToken;
	payload: IRefreshTokenResponse;
}
export const userRefreshTokenAction = createAction<
	IUserRefreshTokenAction['payload']
>(UserActions.UserRefreshToken);

interface IUserLogoutAction {
	type: typeof UserActions.UserLogoutAction;
}
export const userLogoutAction = createAction<IUserLogoutAction>(
	UserActions.UserLogoutAction
);
