import React from 'react';
import { InputFeedback } from 'components/form-elements';

import { ReactComponent as SvgEyeOn } from 'assets/icons/eye-on.svg';
import { ReactComponent as SvgEyeOff } from 'assets/icons/eye-off.svg';
import HelpInfo from 'components/common/helpInfo/HelpInfo';

export interface ICustomInputProps
	extends React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	> {
	className?: string;
	name: string;
	label?: string | JSX.Element;
	value?: string;
	placeholder?: string;
	error?: string;
	required?: boolean;
	autoComplete?: string;
	customIcon?: JSX.Element;
}

export const CustomInput: React.FC<ICustomInputProps> = ({
	className,
	name,
	label,
	value,
	placeholder,
	error,
	id,
	type,
	onChange,
	onBlur,
	onFocus,
	required = false,
	autoComplete = 'off',
	customIcon,
	disabled,
	...rest
}) => {
	const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
	const [isHidden, setIsHidden] = React.useState<boolean>(true);
	const [innerValue, setInnerValue] =
		React.useState<HTMLInputElement['value']>('');

	React.useEffect(() => {
		setInnerValue(value || '');
	}, [value]);

	const toggleIsHidden = () => {
		setIsHidden(!isHidden);
		inputRef.current.focus();
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInnerValue(e.target.value);
		onChange && onChange(e);
		e.target.value.length == 0 && setIsHidden(true);
	};

	const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
		onFocus && onFocus(e);
	};

	const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		setIsHidden(true);
		onBlur && onBlur(e);
	};

	return (
		<div className={` ${className || ''} flex flex-col relative`}>
			{label && (
				<label
					className={`block text-sm font-medium text-gray-700 truncate mb-1`}
					htmlFor={name}
				>
					{label}
				</label>
			)}
			{name == "return_url" &&(
				<HelpInfo
					message='Return URL is a web address to which user is redirected after completing a survey'
					icon={true}
					hoverMessageFirstLine='Return URL is a <strong>web address</strong> to which users are redirected after completing a survey.'
					hoverMessageSecondLine='Customize your <strong>default URL</strong>, which will be automatically applied to all surveys on your account for seamless redirection.'
				/>					
			)}	

			<div className={`relative ${disabled ? 'opacity-50' : ''}`}>
				<input
					ref={inputRef}
					data-testid={`${name}-input`}
					className={` leading-6
                    appearance-none border rounded-md shadow-sm placeholder-gray-400 focus:outline-none sm:text-sm
                    flex items-center w-full h-10
                    ${type === 'number' ? 'hide-spinner' : ''}
                    ${
											error
												? 'border-red-500 focus:border-red-500 focus:ring-red-500'
												: 'border-gray-300 focus:ring-blue-700 focus:border-blue-700'
										}
					${customIcon ? 'pl-3 pr-12 truncate' : 'px-3'}
                `}
					id={id}
					type={type === 'password' && !isHidden ? 'text' : type}
					name={name}
					value={innerValue}
					onChange={handleOnChange}
					onFocus={handleOnFocus}
					onBlur={handleOnBlur}
					autoComplete={autoComplete}
					{...rest}
					required={required}
					placeholder={placeholder}
					disabled={disabled}
				/>
				{type === 'password' && (
					<span
						role="button"
						tabIndex={0}
						onClick={toggleIsHidden}
						className="h-6 w-6 text-black hover:text-gray-700 absolute top-1/2 transform -translate-y-1/2 right-3"
					>
						{isHidden ? <SvgEyeOff /> : <SvgEyeOn />}
					</span>
				)}

				{customIcon && (
					<span
						role="button"
						className="h-6 w-6 text-black hover:text-blue-700 absolute top-1/2 transform -translate-y-1/2 right-3"
					>
						{customIcon}
					</span>
				)}
			</div>

			{error && <InputFeedback name={name} error={error} />}
		</div>
	);
};
