import { useParams } from 'react-router-dom';

import { Layout } from 'components/shared';
import { Loader } from 'components/common';
import { SubmissionEntryDisplay, SubmissionInfo } from 'components/submissions';
import { useGetSubmissionById } from 'services/submissions';
import { useGetSurveyByIdQuery } from 'services/surveys';
import { useLanguageContext } from 'context';

import { preProcessSurvey } from 'components/survey/helpers';

function SingleSubmission() {
	const params = useParams();
	const { defaultLanguage } = useLanguageContext();

	const { data: submission, isLoading: isLoadingSubmission } =
		useGetSubmissionById(params.submissionId);

	const { data: survey, isLoading: isLoadingSurvey } = useGetSurveyByIdQuery(
		submission?.survey_id
	);

	if (isLoadingSurvey || isLoadingSubmission) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!survey || !submission) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<p>NO SURVEY/SUBMISSION</p>
				</div>
			</Layout>
		);
	}

	const surveyProccesed = preProcessSurvey(survey);

	return (
		<>
			{submission && <SubmissionInfo {...{ submission }} />}
			<Layout pageTitle={survey.survey_name}>
				{surveyProccesed.general_questions.map(question => {
					const answer = submission.form_json[question.name];
					const translatedAnswer = submission.form_json_translated[question.name];
					const translatedFrom = submission.form_json_translated[question.name+"_original_language"];
					return (
						<SubmissionEntryDisplay
							key={question.name}
							question={question}
							answer={answer}
							translatedAnswer={translatedAnswer}
							translatedFrom={translatedFrom}
						/>
					);
				})}

				{surveyProccesed.sections.map(section => {
					return (
						<div
							key={section.name}
							className="flex flex-col border border-gray-300 bg-white px-4 py-6 rounded-lg mb-6"
						>
							<h2 className="text-xl mb-4 font-bold text-gray-700 truncate">
								{section.label?.[defaultLanguage]}
							</h2>
							{section.questions.map(question => {
								const answer =
									submission?.form_json?.[section.name]?.[question.name];
								const translatedAnswer =
									submission?.form_json_translated?.[section.name]?.[question.name];
								const translatedFrom =
									submission?.form_json_translated?.[section.name]?.[question.name+"_original_language"];	
								return (
									<SubmissionEntryDisplay
										key={question.name}
										question={question}
										answer={answer}
										translatedAnswer={translatedAnswer}
										translatedFrom={translatedFrom}
									/>
								);
							})}
						</div>
					);
				})}
			</Layout>
		</>
	);
}

export default SingleSubmission;
