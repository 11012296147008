import axios from 'axios';

export const deleteProperty = async (id: number) => {
	return await axios
		.delete<any>(`${process.env.REACT_APP_API_URL}/property_api/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error deleting property:`, error);
			return Promise.reject(error);
		});
};
