import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Tooltip
} from 'chart.js';

export interface IBarChartProps {
	labels: string[];
	values: number[];
}

export const BarChart: React.FC<IBarChartProps> = ({ labels, values }) => {
	ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip);

	const data = {
		labels: labels,

		datasets: [
			{
				label: 'Survey usage',
				data: values,
				fill: true,
				backgroundColor: '#00396D',
				borderColor: '#00396D',
				pointRadius: 6,
				pointHoverRadius: 8,
				pointBorderWidth: 2
			}
		]
	};

	const options = {
		scales: {
			x: {
				grid: {
					display: false
				}
			},
			y: {
				display: false,
				grid: {
					display: false
				}
			}
		}
	};

	return <Bar data={data} options={options} />;
};
