import React from 'react';
import { Link } from 'react-router-dom';
import { useSearchParamsWithHelpers } from 'hooks';

import { Column, HeaderGroup, usePagination, useTable } from 'react-table';

import { useGetSubmissionBySurveyIdQuery } from 'services/submissions';
import { ISubmission } from 'services/submissions/interfaces';
import { Button, Loader } from 'components/common';

import { ReactComponent as SvgChevron } from '../../../assets/icons/chevron.svg';
import { ReactComponent as SvgMinus } from '../../../assets/icons/minus.svg';
import { CustomSelect } from 'components/form-elements';

import { saveCsv } from 'utils';
import { getExportSubmission} from 'services/surveys';

export interface ISubmissionsTableProps {
	surveyId: string;
}

interface ITableData {
	href?: string;
	user?: string;
	email?: string;
	end?: Date;
	time_submitted?: Date;
}

export const SubmissionsTable: React.FC<ISubmissionsTableProps> = ({
	surveyId
}) => {
	const { updateSearchParams, getSearchParamWithInitial } =
		useSearchParamsWithHelpers();

	const initialPage = 1;
	const initialPerPage = 10;
	const initialOrdering = '-end';

	const page = getSearchParamWithInitial('page', initialPage);
	const page_size = getSearchParamWithInitial('page_size', initialPerPage);
	const ordering = getSearchParamWithInitial('ordering', initialOrdering);

	const { data: submissions, isLoading } = useGetSubmissionBySurveyIdQuery(
		surveyId,
		{
			params: {
				...(page !== initialPage ? { page } : {}),
				...(page_size !== initialPerPage ? { page_size } : {}),
				...(ordering !== initialOrdering
					? { ordering }
					: { ordering: initialOrdering })
			}
		}
	);


	const csvDownloadHandler = () => {
		getExportSubmission(surveyId).then(csv => {
		saveCsv(
			csv,
			'Survey-'+surveyId+'-SubmissionExport.csv'
				
		)});
	};

	const totalItems = submissions?.count;
	const pageCount = totalItems === 0 ? 1 : Math.ceil(totalItems / page_size);

	const columns: Column<ITableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'Href',
				accessor: 'href',
				sortDescFirst: true
			},
			{
				Header: 'Latest filled',
				accessor: 'user',
				sortDescFirst: true
			},
			{
				Header: 'Email',
				accessor: 'email',
				sortDescFirst: true
			},
			{
				Header: 'Date submitted',
				accessor: 'end',
				sortDescFirst: true
			},
			{
				Header: 'Time submitted',
				accessor: 'time_submitted',
				sortDescFirst: true
			}
		];
	}, []);

	const data: ITableData[] = React.useMemo(() => {
		if (!submissions) {
			return [];
		}
		return submissions?.results?.map((submission: ISubmission) => {
			const date = new Date(submission.end).toLocaleDateString();
			const time = new Date(submission.end).toLocaleTimeString([], {
				hour: '2-digit',
				minute: '2-digit'
			});

			return {
				href: submission.submission_id,
				//@todo: check this
				user:
					typeof submission.user === 'string' ? (
						<span className="capitalize">
							{submission.user.split('_').join(' ')}
						</span>
					) : (
						<span>
							{submission.user.firstname} {submission.user.surname}
						</span>
					),
				email: typeof submission.user !== 'string' ? submission.user.email : '',
				end: date,
				time_submitted: time
			};
		});
	}, [submissions]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable(
			{
				columns,
				data,
				initialState: {
					pageSize: page_size,
					hiddenColumns: ['href']
				},
				manualPagination: true,
				pageCount: pageCount
			},
			usePagination
		);

	const setOrderSearchParam = (column: HeaderGroup<ITableData>) => {
		const alreadySet = column.id === ordering;
		const newOrdering = alreadySet ? `-${column.id}` : column.id;
		const shouldRemove = newOrdering === initialOrdering;

		updateSearchParams({
			ordering: !shouldRemove ? newOrdering : undefined
		});
	};

	if (!data || !submissions || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<>
			<div className="w-full block">
				<div className="w-200  float-right">
				<Button
											text="Download CSV"
											type="button"
											variant="light"
											className="w-full mt-6"
											onClick={csvDownloadHandler}
											/>
				</div>
				<div className="clear-both"></div>
			</div>
			<div className="mt-10 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
				<table
					{...getTableProps()}
					className="min-w-full divide-y divide-gray-200"
				>
					<thead className="bg-gray-50">
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map(column => {
									return (
										<React.Fragment>
											{((column.id === 'email' ||
												column.id === 'time_submitted' ||
												column.id === 'user') && (
												<th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
													<span className="h-4">{column.render('Header')}</span>
												</th>
											)) || (
												<th
													key={column.id}
													onClick={() => setOrderSearchParam(column)}
													className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hover:text-blue-700 cursor-pointer"
												>
													<span className="flex items-center">
														<span className="h-4">
															{column.render('Header')}
														</span>

														{((ordering === column.id ||
															ordering === `-${column.id}`) && (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgChevron
																	className={`w-3 h-3 ${
																		ordering === column.id
																			? 'rotate-90'
																			: '-rotate-90'
																	}`}
																/>
															</span>
														)) || (
															<span className="ml-1 inline-flex items-center justify-center w-4 h-4">
																<SvgMinus className="w-3 h-3" />
															</span>
														)}
													</span>
												</th>
											)}
										</React.Fragment>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row, i) => {
							prepareRow(row);
							return (
								<tr
									{...row.getRowProps()}
									className={`hover:bg-blue-50 ${
										i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'
									}`}
								>
									{row.cells.map(cell => {
										return (
											<td
												{...cell.getCellProps()}
												className="whitespace-nowrap text-sm p-0"
											>
												<Link
													className="px-6 py-4 block whitespace-normal line-clamp-2"
													to={`/submissions/${row.values.href}`}
												>
													{cell.render('Cell')}
												</Link>
											</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>

			{/* PAGINATION */}
			<div className="flex items-center justify-center relative mt-10">
				<div className="flex items-center justify-center gap-4">
					<button
						aria-label="previous page"
						disabled={page === 1}
						onClick={() => {
							updateSearchParams({
								page: page - 1 !== 1 ? String(page - 1) : undefined
							});
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === 1
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4 rotate-180" />
					</button>

					<p
						className={`h-10 w-20 px-4 flex items-center justify-center bg-white rounded-lg text-sm text-gray-900 border border-gray-300 shadow-sm text-right`}
					>
						<span>
							{page} / {pageCount}
						</span>
					</p>

					<button
						aria-label="next page"
						disabled={page === pageCount}
						onClick={() => {
							updateSearchParams({ page: String(page + 1) });
						}}
						className={`w-10 h-10 flex items-center justify-center bg-white border border-gray-300 hover:bg-blue-100 hover:text-blue-700 shadow-sm focus:ring-blue-700 focus:border-blue-700 focus:outline-none focus:ring-1 rounded-lg text-sm ${
							page === pageCount
								? 'pointer-events-none opacity-50 text-gray-500'
								: 'text-gray-900'
						}`}
					>
						<SvgChevron className="w-4 h-4" />
					</button>
				</div>

				<CustomSelect
					name="page_size"
					options={[
						{
							value: 10,
							display: '10'
						},
						{
							value: 25,
							display: '25'
						},
						{
							value: 40,
							display: '40'
						}
					]}
					initialValue={{
						value: page_size,
						display: String(page_size)
					}}
					onChange={e => {
						updateSearchParams({
							page_size:
								e.value === initialPerPage ? undefined : String(e.value),
							page: undefined
						});
					}}
					className="absolute right-0 top-1/2 -translate-y-1/2"
				/>
			</div>
		</>
	);
};
