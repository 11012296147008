import { FieldArrayRenderProps, FieldHelperProps } from 'formik';
import { IOption } from 'components/form-elements';
import { accessPropertyByStringPath } from 'utils';
import {
	IAnswerType,
	IQuestion,
	ISkipLogic,
	ISurveySection,
	ISurveyUI
} from 'services/surveys/interfaces';
import { createUniqueQuestionIndex, getAppearanceOptions } from '.';


export const createInitialChoices = (
	e: IOption,
	helper: FieldArrayRenderProps,
	groupName: string,
	indexInGroup: number,
	properties: IOption[],
	availableLanguages: string[]
) => {
	const choices = accessPropertyByStringPath(helper.form.values, helper.name);

	const isChoiceType = ['select_one', 'select_multiple', 'sex', 'matrix_rating'].includes(
		e.value as string
	);

	if (isChoiceType && (!choices || choices.length === 0)) {
		helper.form.setFieldValue(`${groupName}[${indexInGroup}].choices`, [
			{ name: 'choice_0', label: '' },
			{ name: 'choice_1', label: '' }
		]);
	}
	
	if (e.value==='property') {
		const choicesArray = properties.map((property) => {
			const labelObject: ILabelObject = {};
			
			// Create label object with keys from availableLanguages array
			availableLanguages.forEach(lang => {
			  labelObject[lang] = `${property.display}`;
			});
		  
			return {
			  name: `${property.display}`,
			  label: labelObject,
			  property_id: property.value
			};
		  });
		  
		  helper.form.setFieldValue(`${groupName}[${indexInGroup}].choices`, choicesArray);
	}

	/* if (!isChoiceType) {
		helper.form.setFieldValue(`${groupName}[${indexInGroup}].choices`, []);
	} */
};

export const resetPropertyOptions = (
	helper: FieldArrayRenderProps,
	groupName: string,
	indexInGroup: number,
	properties: IOption[],
	availableLanguages: string[]
) =>{
	const choicesArray = properties.map((property, index) => {
		const labelObject: ILabelObject = {};
		
		// Create label object with keys from availableLanguages array
		availableLanguages.forEach(lang => {
		  labelObject[lang] = `${property.display}`;
		});
	  
		return {
		  name: `choice_${index}`,
		  label: labelObject,
		};
	  });
	  
	  helper.form.setFieldValue(`${groupName}[${indexInGroup}].choices`, choicesArray);
}

//unused helper
const updateSkipLogicQuestionName = (
	skip_logic: ISkipLogic,
	oldName: string,
	newName: string,
	pathToChange: string,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>
) => {
	if (skip_logic.question === oldName) {
		getFieldHelpers(pathToChange).setValue(newName);
	}
};

//unused helper
const updateSkipLogicQuestionNames = (
	oldName: string,
	newName: string,
	helper: FieldArrayRenderProps,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>
) => {
	helper.form.values.general_questions.forEach(
		(question: IQuestion, question_index: number) => {
			question.skip_logic.forEach(
				(skip_logic: ISkipLogic, skip_logic_index: number) => {
					updateSkipLogicQuestionName(
						skip_logic,
						oldName,
						newName,
						`general_questions.${question_index}.skip_logic.${skip_logic_index}.question`,
						getFieldHelpers
					);
				}
			);
		}
	);

	helper.form.values.sections.forEach(
		(section: ISurveySection, section_index: number) => {
			section.skip_logic.forEach(
				(skip_logic: ISkipLogic, skip_logic_index: number) => {
					updateSkipLogicQuestionName(
						skip_logic,
						oldName,
						newName,
						`sections.${section_index}.skip_logic.${skip_logic_index}.question`,
						getFieldHelpers
					);
				}
			);

			section.questions.forEach(
				(question: IQuestion, question_index: number) => {
					question.skip_logic.forEach(
						(skip_logic: ISkipLogic, skip_logic_index: number) => {
							updateSkipLogicQuestionName(
								skip_logic,
								oldName,
								newName,
								`sections.${section_index}.questions.${question_index}.skip_logic.${skip_logic_index}.question`,
								getFieldHelpers
							);
						}
					);
				}
			);
		}
	);
};

//unused helper
export const updateFieldName = (
	helper: FieldArrayRenderProps,
	question: IQuestion,
	survey: ISurveyUI,
	groupName: string,
	indexInGroup: number,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>
) => {
	const oldName = question.name;
	const newName = `q_${createUniqueQuestionIndex(survey)}`;
	helper.form.setFieldValue(`${groupName}[${indexInGroup}].name`, newName);
	updateSkipLogicQuestionNames(oldName, newName, helper, getFieldHelpers);
};

export const resetAppearanceValue = (
	e: IOption,
	helper: FieldArrayRenderProps,
	groupName: string,
	indexInGroup: number
) => {
	helper.form.setFieldValue(
		`${groupName}[${indexInGroup}].appearance`,
		getAppearanceOptions(e.value as IAnswerType)[0].value
	);
	}

export const resetAnalyticsValue = (
	helper: FieldArrayRenderProps,
	groupName: string,
	indexInGroup: number
) => {
	helper.form.setFieldValue(
		`${groupName}.${indexInGroup}.analytics_type`,
		false
	);
};

export const resetRangeValues = (
	helper: FieldArrayRenderProps,
	groupName: string,
	indexInGroup: number
) => {
	Object.entries({
		range_min: '0',
		range_step: '1',
		range_max: '100'
	}).forEach(([key, value]) => {
		helper.form.setFieldValue(`${groupName}[${indexInGroup}][${key}]`, value);
	});
};

export const resetCheckboxValue = (
	e: IOption,
	helper: FieldArrayRenderProps,
	groupName: string,
	indexInGroup: number,
) => {
	const type: string = e.value as string;
	if (![
		'text',
		'date',
		'email',
		'age',
		'sex'
	].includes(type)){
	helper.form.setFieldValue(
		`${groupName}[${indexInGroup}].is_sensitive`,
		false
	);
	}
}

	
interface ILabelObject {
	[key: string]: string;
}