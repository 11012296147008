import axios from 'axios';
import { IRegisterUser } from '.';

export const registerUser = async (data: IRegisterUser) => {
	return await axios
		.patch<IRegisterUser>(
			`${process.env.REACT_APP_API_URL}/registration_complete/`,
			data
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error registering user:`, error);
			return Promise.reject(error);
		});
};
