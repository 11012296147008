import React from 'react';
import { getAnalyticsUrl } from 'services/analytics';
import { AnalyticsDashboard } from 'components/analytics/AnalyticsDashboard';
import { Loader } from 'components/common';
import { Layout } from 'components/shared';

const Analytics = () => {
	const [dashboardUrl, setDashboardUrl] = React.useState<any>();

	React.useEffect(() => {
		getAnalyticsUrl().then(res => {
			setDashboardUrl(res);
		});
	}, []);

	if (dashboardUrl === undefined) {
		return (
			<Layout pageTitle="Analytics" fullPageMode>
				<div className="h-screen flex items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	return (
		<Layout pageTitle="Analytics" fullPageMode>
			<AnalyticsDashboard url={dashboardUrl.EmbedUrl} />
		</Layout>
	);
};

export default Analytics;
