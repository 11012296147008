import React from 'react';
import { Column, useTable } from 'react-table';

import { Loader } from 'components/common';

import { IRule, useGetRulesQuery } from 'services/notifications';

import { ReactComponent as SvgEdit } from 'assets/icons/edit.svg';
import { ReactComponent as SvgTrash } from 'assets/icons/trash.svg';
import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';

export interface INotificationRulesTableProps {
	onEdit: (item: IRule) => void;
	onDelete: (item: IRule) => void;
}

interface INotificationRulesTableData {
	rule_name: string;
	actions?: JSX.Element;
}

export const NotificationRulesTable: React.FC<INotificationRulesTableProps> = ({
	onEdit,
	onDelete
}) => {
	const { data: rules, isLoading } = useGetRulesQuery();

	const columns: Column<INotificationRulesTableData>[] = React.useMemo(() => {
		return [
			{
				Header: 'Rule name',
				accessor: 'rule_name',
				sortDescFirst: true
			},
			{
				Header: 'Actions',
				accessor: 'actions',
				sortDescFirst: true
			}
		];
	}, []);

	const data: INotificationRulesTableData[] = React.useMemo(() => {
		if (!rules) {
			return [];
		}
		return rules.map((item: IRule) => {
			return {
				rule_name: item.rule_name,
				actions: (
					<span className="flex">
						<button
							title="Edit rule"
							role="button"
							aria-label="edit rule"
							onClick={() => onEdit(item)}
							className="flex items-center justify-center text-gray-500 hover:text-blue-700 mr-1 w-8 h-8"
						>
							<SvgEdit className="w-5 h-5" />
						</button>
						<button
							title="Delete template"
							role="button"
							aria-label="delete template"
							onClick={() => onDelete(item)}
							className="flex items-center justify-center text-gray-500 hover:text-blue-700 w-8 h-8"
						>
							<SvgTrash className="w-5 h-5" />
						</button>
					</span>
				)
			};
		});
	}, [rules]);

	const {
		//table
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow
	} = useTable({
		columns,
		data
	});

	if (!data || !rules || isLoading) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	return (
		<div className="mt-6 mb-10 shadow overflow-hidden border-b border-gray-200 sm:rounded-lg align-middle inline-block min-w-full">
			<table
				{...getTableProps()}
				className="min-w-full divide-y divide-gray-200"
			>
				<thead className="bg-gray-50">
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => {
								return (
									<th
										className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider`}
									>
										<span className="h-4">{column.render('Header')}</span>
									</th>
								);
							})}
						</tr>
					))}
				</thead>

				<tbody {...getTableBodyProps()}>
					{rows.map((row, i) => {
						prepareRow(row);

						return (
							<tr
								{...row.getRowProps()}
								className={`${i % 2 === 0 ? 'bg-transparent' : 'bg-gray-50'}`}
							>
								{row.cells.map(cell => {
									return (
										<td
											{...cell.getCellProps()}
											className="whitespace-nowrap text-sm p-0"
										>
											{(cell.column.id === 'actions' && (
												<span className="px-6 py-4 block">
													{cell.render('Cell')}
												</span>
											)) || (
												<span className="px-6 py-4 block whitespace-normal line-clamp-2">
													<span>{cell.render('Cell')}</span>
												</span>
											)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>

			{data.length === 0 && (
				<div className="flex flex-col items-center justify-center p-8">
					<SvgWarning className="w-40 h-40 text-gray-200" />
					<p className="mt-2">Nothing to show</p>
				</div>
			)}
		</div>
	);
};
