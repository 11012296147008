import axios from 'axios';
import { useQuery } from 'react-query';
import { IQuotaResponse } from './interfaces';

export const getQuota = async () => {
	return await axios
		.get<IQuotaResponse>(`${process.env.REACT_APP_API_URL}/get_quota/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting quota:`, error);
			return Promise.reject(error);
		});
};

export const useGetQuotaQuery = () => {
	return useQuery(['quota'], () => getQuota(), {
		refetchOnWindowFocus: false
	});
};
