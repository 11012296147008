import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { ISurveyTag } from './interfaces';

export const getTags = async () => {
	return await axios
		.get<ISurveyTag[]>(`${process.env.REACT_APP_API_URL}/tag/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting tags:`, error);
			return Promise.reject(error);
		});
};

export const useGetTagsQuery = () => {
	return useQuery(['tags'] as QueryKey, () => getTags(), {
		refetchOnWindowFocus: false
	});
};
