import React from 'react';
import { Form, Formik } from 'formik';

import { IOption } from 'components/form-elements';
import { useGetLanguagesQuery } from 'services/languages';
import { useLanguageContext, useLanguageDispatchContext } from 'context';
import {
	setAvailableLanguages,
	setCurrentLanguage,
	setDefaultLanguage
} from 'reducers';
import { getLanguageFlag } from '../helpers';

import { Button, Modal } from 'components/common';
import {
	AutoSubmit,
	FieldCheckbox,
	FieldCheckboxGroup,
	FieldSelect
} from 'components/formik-elements';

export interface ILanguagesModalProps {
	isOpen: boolean;
	onRequestClose: () => void;
}

export const LanguagesModal: React.FC<ILanguagesModalProps> = ({
	isOpen,
	onRequestClose
}) => {
	const { currentLanguage, defaultLanguage, availableLanguages } =
		useLanguageContext();
	const { data: languages } = useGetLanguagesQuery();
	const languageDispatch = useLanguageDispatchContext();

	return (
		<Modal
			headerText="Manage languages"
			isOpen={isOpen}
			onRequestClose={onRequestClose}
		>
			<Formik
				initialValues={{
					defaultLanguage: defaultLanguage,
					selectedAdditional: availableLanguages.filter(
						lang => lang !== defaultLanguage
					)
				}}
				onSubmit={values => {
					languageDispatch(setDefaultLanguage(values.defaultLanguage));
					languageDispatch(
						setCurrentLanguage(
							values.selectedAdditional.includes(currentLanguage) ||
								values.defaultLanguage.includes(currentLanguage)
								? currentLanguage
								: values.defaultLanguage
						)
					);
					languageDispatch(
						setAvailableLanguages([
							values.defaultLanguage,
							...values.selectedAdditional
						])
					);
				}}
			>
				{props => {
					const { values, setFieldValue } = props;
					return (
						<Form>
							{languages && (
								<div className="flex flex-col pb-12">
									<p className="font-bold text-gray-500 mb-3">
										Default language
									</p>
									<FieldSelect
										name="defaultLanguage"
										onChange={(selectedDefaultValue: IOption) => {
											setFieldValue('selectedAdditional', [
												...values.selectedAdditional.filter(
													addLang => addLang !== selectedDefaultValue.value
												),
												defaultLanguage
											]);
										}}
										options={languages.map(language => {
											return {
												value: language.short_name,
												display: (
													<span className="flex items-center">
														{getLanguageFlag(language.short_name)}
														<span className="ml-4">{language.name}</span>
													</span>
												)
											};
										})}
										className="mb-7 max-w-xs"
									/>
									<p className="font-bold text-gray-500 mb-3">
										Additional languages
									</p>
									<FieldCheckboxGroup name="selectedAdditional">
										<div className="flex flex-wrap">
											{languages
												.filter(
													language =>
														language.short_name !== values.defaultLanguage
												)
												.map(language => {
													return (
														<FieldCheckbox
															key={language.short_name}
															name={language.short_name}
															id={language.short_name}
															value={language.short_name}
															variant="toggler"
															className="mb-4 w-1/2"
															labelComponent={
																<span className="flex items-center">
																	{getLanguageFlag(language.short_name)}
																	<span className="ml-4">{language.name}</span>
																</span>
															}
														/>
													);
												})}
										</div>
									</FieldCheckboxGroup>
									<AutoSubmit />
									<Button
										type="button"
										text="Save"
										size="small"
										onClick={onRequestClose}
										className="self-start mt-3"
									/>
								</div>
							)}
						</Form>
					);
				}}
			</Formik>
		</Modal>
	);
};
