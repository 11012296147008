import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const getSurveyById = async (id: string) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/survey_api/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting survey:`, error);
			return Promise.reject(error);
		});
};

export const useGetSurveyByIdQuery = (id?: string) => {
	return useQuery(
		['survey', id] as QueryKey,
		() =>
			id
				? getSurveyById(id)
				: Promise.reject('Error in useGetSurveyByIdQuery: No id provided.'),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};
