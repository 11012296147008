import { Button } from 'components/common';
import { ReactComponent as SvgLogo } from 'assets/icons/logo.svg';

const Unsubscribe = () => {
	return (
		<div className="w-full h-full min-h-screen flex items-center justify-center">
			<div className="flex flex-col items-center">
				<h2 className="flex flex-col items-center justify-center">
					<SvgLogo className="text-blue-700 w-40 h-40 mb-4" />
					<span className="text-5xl font-bold text-gray-900">
						Hospitality Insights
					</span>
				</h2>

				<p className="text-xl mt-8 max-w-md text-center">
					You have successfully unsubscribed!
				</p>
				<Button text="Go home" className="mt-8" href="/" />
			</div>
		</div>
	);
};

export default Unsubscribe;
