import axios from 'axios';




export const getExportSubmission = async (id: string) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/export_submissions/${id}/`)
		.then(response => {
            
			return response.data;
		})
		.catch(error => {
			console.error(`Error getting submission export:`, error);
			return Promise.reject(error);
		});
};