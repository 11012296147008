import React from 'react';
import { useLanguageContext, useLanguageDispatchContext } from 'context';
import { setCurrentLanguage } from 'reducers';

import { LanguagesModal } from '../LanguagesModal';
import { getLanguageFlag } from '../helpers';
import classNames from 'classnames';
import { LanguageTranslateModal } from '../LanguageTranslateModal';

export interface ILanguageBarProps {
	className?: string;
}

export const LanguageBar: React.FC<ILanguageBarProps> = ({ className }) => {
	const { currentLanguage, availableLanguages } = useLanguageContext();
	const languageDispatch = useLanguageDispatchContext();

	const [manageLanguagesShown, setManageLanguagesShown] = React.useState(false);

	const [translateSurveyShown, setTranslateSurveyShown] = React.useState(false);

	React.useEffect(() => {
		const element = document.getElementById('language-bar');
		const observer = new IntersectionObserver(
			([e]) =>
				e.target.classList.toggle('custom-shadow', e.intersectionRatio < 1),
			{ threshold: [1] }
		);
		if (element !== null) {
			observer.observe(element);
		}
	}, []);

	return (
		<>
			<div
				id="language-bar"
				className={`
					sticky -top-px z-10 flex justify-center mb-4 bg-white py-2 px-3 
					${className || ''}
					max-w-[calc(100vw-5rem-40px)]
					ml-[calc(50%-50vw+2.5rem+20px)]
					mr-[calc(50%-50vw+2.5rem+20px)]
					xxl:max-w-[calc(100vw-16rem-40px)]
					xxl:ml-[calc(50%-50vw+8rem+20px)]
					xxl:mr-[calc(50%-50vw+8rem+20px)]
				`}
			>
				<div className="flex justify-between items-center w-full max-w-[960px]">
					<div className="flex flex-1 flex-wrap">
						{availableLanguages.map((language, i) => {
							return (
								<button
									key={language}
									type="button"
									className={`flex items-center p-2 rounded-lg border-2 min-w-[80px] ${
										language === currentLanguage
											? 'bg-blue-100 border-blue-700'
											: 'border-transparent'
									}`}
									onClick={() => languageDispatch(setCurrentLanguage(language))}
									title={i === 0 ? 'Default language' : ''}
								>
									<span className="relative">
										{getLanguageFlag(language)}
										{i === 0 && (
											<span className="bg-blue-700 rounded-full absolute -top-1 -left-1 w-2.5 h-2.5"></span>
										)}
									</span>
									<span
										className={classNames('ml-2 uppercase font-bold ', {
											'text-blue-700': language === currentLanguage,
											'text-gray-500': language !== currentLanguage
										})}
									>
										{language}
									</span>
								</button>
							);
						})}
					</div>
					<button
						type="button"
						onClick={() => {
							setTranslateSurveyShown(true);
									}}
									className="font-bold text-blue-800 underline disabled:opacity-25 hover:no-underline pl-6"
									disabled={availableLanguages.length<2}
								>
						Translate
					</button>
					<button
						type="button"
						className="font-bold text-blue-800 underline hover:no-underline pl-6"
						onClick={() => setManageLanguagesShown(true)}
					>
						Manage languages
					</button>
				</div>
			</div>

			<LanguagesModal
				isOpen={manageLanguagesShown}
				onRequestClose={() => setManageLanguagesShown(false)}
			/>

			<LanguageTranslateModal
				isOpen={translateSurveyShown}
				onRequestClose={() => setTranslateSurveyShown(false)}
			/>
		</>
	);
};
