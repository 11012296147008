import axios, { AxiosRequestConfig } from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

export type IUseGetUsersOptions = UseQueryOptions<any, any, any>;

type IGetUsersQueryParams = {
	page?: number;
	page_size?: number;
	ordering?: string;
};

interface IGetUsersRequestConfig extends AxiosRequestConfig {
	params?: IGetUsersQueryParams;
}

export const getUsers = async (config?: IGetUsersRequestConfig) => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/users/`, {
			...config
		})
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting users:`, error);
			return Promise.reject(error);
		});
};

export const useGetUsersQuery = (
	config?: IGetUsersRequestConfig,
	queryOptions?: IUseGetUsersOptions
) => {
	return useQuery(
		['users', config?.params] as QueryKey,
		() => getUsers(config),
		queryOptions
	);
};
