import axios from 'axios';
import { IClientInfoUpdate } from '.';

export const updateClientInfo = async (
	id: number,
	infoData: IClientInfoUpdate
) => {
	return await axios
		.put<any>(
			`${process.env.REACT_APP_API_URL}/clientinfo_api/${id}/`,
			infoData
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating client info:`, error);
			return Promise.reject(error);
		});
};
