import axios from 'axios';
import { userRefreshTokenAction } from 'reducers';

export interface IRefreshRequestConfig {
	refresh: string;
}

export interface IRefreshTokenResponse {
	access: string;
}

export const userRefreshToken = async (
	config: IRefreshRequestConfig,
	userDispatch: any
): Promise<IRefreshTokenResponse> => {
	return await axios
		.post<IRefreshTokenResponse>(
			`${process.env.REACT_APP_API_URL}/token/refresh`,
			config
		)
		.then(response => {
			userDispatch(userRefreshTokenAction(response.data));
			return Promise.resolve(response.data);
		})
		.catch((err: any) => {
			return Promise.reject(err);
		});
};
