import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';

export const postSurveyPdf = async (
	id?: string,
	data?: IPostSurveyPdfQueryParams
) => {
	if(data?.invitation_text && data.invitation_text!=="" && data.language_list && data.language_list.length>0)
		return await axios
			.post<any>(
				`${process.env.REACT_APP_API_URL}/get_survey_invitation_pdf/?survey_id=${id}`,
				data
			)
			.then(response => {
				return Promise.resolve(response.data);
			})
			.catch(error => {
				console.error(`Error posting survey pdf:`, error);
				return Promise.reject(error);
			});
			else{
				return Promise.reject("query parameters not set");
			}
};

export const usePostSurveyPdfQuery = (
	id?: string,
	data?: IPostSurveyPdfQueryParams
) => {
	return useQuery(
		['survey_pdf', id, data] as QueryKey,
		() =>
			id
				? postSurveyPdf(id, data)
				: Promise.reject('Error in usePostSurveyPdfQuery: No id provided.'),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};

type IPostSurveyPdfQueryParams = {
	invitation_text: string;
	language_list: string[];
};
