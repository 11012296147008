import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { IClientInfo } from '.';

export const getClientInfo = async () => {
	return await axios
		.get<IClientInfo>(`${process.env.REACT_APP_API_URL}/clientinfo_api/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting client info:`, error);
			return Promise.reject(error);
		});
};

export const useGetClientInfoQuery = () => {
	return useQuery('client_info' as QueryKey, () => getClientInfo(), {
		refetchOnWindowFocus: false
	});
};
