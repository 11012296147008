import { FieldArrayRenderProps, FieldHelperProps } from 'formik';
import { createNewQuestion } from '.';
import { IQuestion, ISurveyUI } from 'services/surveys/interfaces';

export const addQuestion = (arrayHelpers: FieldArrayRenderProps) => {
	arrayHelpers.push(createNewQuestion(arrayHelpers.form.values));
};

export const insertQuestionAtIndex = (
	index: number,
	arrayHelpers: FieldArrayRenderProps,
	survey: ISurveyUI
) => {
	arrayHelpers.insert(index, createNewQuestion(survey));
};

export const addQuestionToGeneral = (
	arrayHelpers: FieldArrayRenderProps,
	question: IQuestion
) => {
	arrayHelpers.form.values.general_questions.push(question);
};

export const addQuestionToSection = (
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	survey: ISurveyUI,
	sectionIndex: number,
	question = createNewQuestion(survey),
	pushToStart = false
) => {
	const newSections = survey.sections.map((section, index) => {
		if (index === sectionIndex) {
		  return {
			...section,
			questions: pushToStart
			  ? [question, ...section.questions]
			  : [...section.questions, question],
		  };
		}
		return section;
	  });
	getFieldHelpers('sections').setValue(newSections);
};

export const addQuestionToLastSection = (
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	survey: ISurveyUI
) => {
	addQuestionToSection(getFieldHelpers, survey, survey.sections.length - 1);
};
