import axios from 'axios';

export const deleteTag = async (id?: number) => {
	return await axios
		.delete<any>(`${process.env.REACT_APP_API_URL}/tag/${id}/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error deleting tag:`, error);
			return Promise.reject(error);
		});
};
