import React from 'react';
import { useReducerWithLogger } from 'hooks';
import { userReducer } from 'reducers';
import { getUserState, setUserState } from 'services/auth';

export interface IUserState {
	token: string;
	refresh_token: string;
	username: string;
}

export const initialUserState: IUserState = {
	token: '',
	refresh_token: '',
	username: ''
};

export const UserStateContext =
	React.createContext<IUserState>(initialUserState);
export const UserDispatchContext = React.createContext<any>({});

export const UserProvider: React.FC<any> = ({ children, mockState }) => {
	const { token, refresh_token, username } = getUserState();
	const [state, dispatch] = useReducerWithLogger(
		userReducer,
		{
			...initialUserState,
			token,
			refresh_token,
			username
		},
		true
	);

	React.useEffect(() => {
		setUserState(state);
	}, [state]);

	return (
		<UserStateContext.Provider value={{ ...state, ...mockState }}>
			<UserDispatchContext.Provider value={dispatch}>
				{children}
			</UserDispatchContext.Provider>
		</UserStateContext.Provider>
	);
};

export const useUserStateContext = () => {
	const context = React.useContext(UserStateContext);
	if (context === undefined) {
		throw new Error(
			'useUserStateContext must be used within a StoreStateProvider'
		);
	}
	return context;
};

export const useUserDispatchContext = () => {
	const context = React.useContext(UserDispatchContext);
	if (context === undefined) {
		throw new Error(
			'useUserDispatchContext must be used within a UserDispatchContext'
		);
	}
	return context;
};
