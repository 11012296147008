import axios from 'axios';
import { IProperty } from './interfaces';

export const postProperty = async (data: IProperty) => {
	return await axios
		.post<IProperty>(`${process.env.REACT_APP_API_URL}/property_api/`, data)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error creating user:`, error);
			return Promise.reject(error);
		});
};
