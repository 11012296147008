import { Button } from 'components/common';
import { FieldInput, FieldSelect } from 'components/formik-elements';
import { FieldArray, FormikErrors, FormikProps } from 'formik';
import {
	addNewNotification,
	addNewSurvey,
	getAvailableNotificationTypes,
	getUniqueContactId,
	showAddNewNotificationTypeButton,
	showAddNewSurveyButton
} from '../helpers';
import { QuestionRuleItem } from '../QuestionRuleItem';
import { ReactComponent as SvgClose } from 'assets/icons/close.svg';
import {
	IContact,
	INotification,
	INotificationRule,
	INotificationType
} from 'services/notifications';
import { ISurvey } from 'services/surveys/interfaces';
import { getPropertiesOptions } from 'components/property/helpers/propertyHelper';

export interface IRuleProps {
	notificationTypes: INotificationType[];
	surveys: ISurvey[];
	formProps: FormikProps<INotificationRule>;
	properties:any;
}

export const Rule: React.FC<IRuleProps> = ({
	notificationTypes,
	surveys,
	formProps,
	properties
}) => {
	return (
		<>
			<FieldInput
				name="rule_name"
				label="Rule name"
				id="rule_name"
				type="text"
				error={
					formProps.errors.rule_name && formProps.submitCount > 0
						? formProps.errors.rule_name
						: ''
				}
			/>

			<div className="space-y-4">
				<FieldArray
					key="notifications"
					name="notifications"
					render={notificationsHelper => {
						return (
							<>
								<h2 className="font-semibold text-md">Notifications</h2>

								{formProps.values.notifications.map(
									(notification, notificationIndex) => {
										return (
											<div
												key={notificationIndex}
												className={`border rounded-lg p-4 ${
													formProps.errors.notifications?.[notificationIndex] &&
													formProps.errors.notifications?.[
														notificationIndex
													] !== undefined &&
													formProps.submitCount
														? 'border-red-300 bg-red-50'
														: 'border-gray-300 bg-gray-50'
												}`}
											>
												<div className="flex justify-between mb-4">
													<FieldSelect
														name={`notifications.${notificationIndex}.notification_type_id`}
														label="Select notification type"
														id={`notifications.${notificationIndex}.notification_type_id`}
														onChange={() => {
															formProps.setFieldValue(
																`notifications.${notificationIndex}.contacts`,
																[
																	{
																		contact_id: getUniqueContactId(
																			formProps.values.notifications
																		),
																		contact: ''
																	}
																]
															);
														}}
														options={getAvailableNotificationTypes(
															notificationTypes,
															formProps.values.notifications,
															notification
														)}
													/>
													  
													{formProps.values.notifications.length > 1 && (
														<button
															type="button"
															onClick={() =>
																notificationsHelper.remove(notificationIndex)
															}
															className="flex-none w-8 h-8 p-2 mt-7 ml-4 text-white text-lg flex justify-center items-center bg-blue-700 hover:bg-blue-600 rounded-full"
														>
															<SvgClose />
														</button>
													)}
												</div>

												<FieldArray
													key={`notifications.${notificationIndex}.contacts`}
													name={`notifications.${notificationIndex}.contacts`}
													render={contactsHelper => {
														return (
															<>
																<span className="block text-sm font-medium text-gray-700 mb-1">
																	Contacts
																</span>

																{formProps.values.notifications?.[
																	notificationIndex
																].contacts.map((contact, index) => {
																	return (
																		<div
																			key={index}
																			className="flex w-full mb-4"
																		>
																			<FieldInput
																				name={`notifications.${notificationIndex}.contacts.${index}.contact`}
																				id={`notifications.${notificationIndex}.contacts.${index}.contact`}
																				className="w-1/2"
																				error={
																					(
																						(
																							formProps.errors?.notifications?.[
																								notificationIndex
																							] as FormikErrors<INotification>
																						)?.contacts?.[
																							index
																						] as FormikErrors<IContact>
																					)?.contact && formProps.submitCount
																						? (
																								(
																									formProps.errors
																										?.notifications?.[
																										notificationIndex
																									] as FormikErrors<INotification>
																								)?.contacts?.[
																									index
																								] as FormikErrors<IContact>
																						  )?.contact
																						: ''
																				}
																			/>
																			{properties && (
																			<FieldSelect
																				name={`notifications.${notificationIndex}.contacts.${index}.property_id`}
																				id={`notifications.${notificationIndex}.contacts.${index}.property_id`}
																				placeholder="For property"
																				options={getPropertiesOptions('For all properties',properties.results)}
																				className="w-1/2 ml-2"
																				error={
																					(
																						(
																							formProps.errors?.notifications?.[
																								notificationIndex
																							] as FormikErrors<INotification>
																						)?.contacts?.[
																							index
																						] as FormikErrors<IContact>
																					)?.property_id && formProps.submitCount
																						? (
																								(
																									formProps.errors
																										?.notifications?.[
																										notificationIndex
																									] as FormikErrors<INotification>
																								)?.contacts?.[
																									index
																								] as FormikErrors<IContact>
																						  )?.property_id
																						: ''
																				}
																			/>
																			)}

												

																			{formProps.values.notifications?.[
																				notificationIndex
																			].contacts.length > 1 && (
																				<button
																					type="button"
																					onClick={() =>
																						contactsHelper.remove(index)
																					}
																					className="flex-none w-8 h-8 p-2 mt-1 ml-4 text-blue-700 text-lg flex justify-center items-center bg-blue-50 hover:bg-blue-200 rounded-full"
																				>
																					<SvgClose />
																				</button>
																			)}
																		</div>
																	);
																})}

																<Button
																	type="button"
																	text="Add new contact"
																	variant="light"
																	size="small"
																	onClick={() => {
																		contactsHelper.push({
																			contact_id: getUniqueContactId(
																				formProps.values.notifications
																			),
																			contact: ''
																		});
																	}}
																/>
															</>
														);
													}}
												/>
											</div>
										);
									}
								)}

								{showAddNewNotificationTypeButton(
									notificationTypes,
									formProps.values.notifications
								) && (
									<Button
										type="button"
										text="Add new notification type"
										size="small"
										onClick={() =>
											addNewNotification(
												notificationTypes,
												formProps.values.notifications,
												notificationsHelper
											)
										}
									/>
								)}
							</>
						);
					}}
				/>
			</div>
			<hr />

			<div className="space-y-4">
				<FieldArray
					key="question_rules"
					name="question_rules"
					render={questionRulesHelper => {
						return (
							<>
								<h2 className="font-semibold text-md">Question rules</h2>

								{formProps.values.question_rules.map(
									(question_rule, ruleIndex) => {
										return (
											<QuestionRuleItem
												key={ruleIndex}
												ruleIndex={ruleIndex}
												surveys={surveys}
												question_rule={question_rule}
												questionRulesHelper={questionRulesHelper}
												formProps={formProps}
											/>
										);
									}
								)}

								{showAddNewSurveyButton(
									surveys,
									formProps.values.question_rules
								) && (
									<Button
										type="button"
										text="Add survey"
										size="small"
										onClick={() => {
											addNewSurvey(
												surveys,
												formProps.values.question_rules,
												questionRulesHelper
											);
										}}
									/>
								)}
							</>
						);
					}}
				/>
			</div>

			<hr />

			<Button type="submit" text="Post rule" rounded="medium" />
		</>
	);
};
