export interface IAction<T = any> {
	type: string;
	payload?: T;
}

export function createAction<T>(type: string) {
	return (payload?: T): IAction => ({
		type,
		payload
	});
}
