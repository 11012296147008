import React from 'react';
import { useField, useFormikContext } from 'formik';
import {
	CustomMultiSelect,
	ICustomMultiSelectProps
} from 'components/form-elements';

export const FieldMultiSelect: React.FC<ICustomMultiSelectProps> = props => {
	const { name, onChange, ...rest } = props;
	const [field] = useField(props.name);
	const { setFieldValue } = useFormikContext();

	return (
		<CustomMultiSelect
			{...field}
			{...props}
			onChange={(e: any) => {
				setFieldValue(name, e);
				onChange && onChange(e);
			}}
			{...rest}
		/>
	);
};
