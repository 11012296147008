import { useMediaQuery } from 'hooks';
import React, { useState } from 'react'

type Props = {
    message: string;
    icon: boolean;
    hoverMessageFirstLine?: string;
    hoverMessageSecondLine?: string;
}

export default function HelpInfo({message, icon, hoverMessageFirstLine, hoverMessageSecondLine}: Props) {

    const [showMessage, setShowMessage] = useState<boolean>(false);
    const isSmallScreen = useMediaQuery({ maxWidth: 768 });

    const onMouseEnter = () => {
        if (!isSmallScreen) {
            setShowMessage(true);
        }
    };
    const onMouseLeave = () => {
        if (!isSmallScreen) {
            setShowMessage(false);
        }
    };

    return (
        <div className='flex mb-[1.254rem] gap-1 relative text-sm font-normal'>
            <p className='text-[#6B6B6B]'>{message}</p>
            {icon && (
                <div className='flex items-center'>
                    <span
                        {...(isSmallScreen ? { onClick: () => setShowMessage(true) } : { onMouseEnter, onMouseLeave })}
                        className='relative'
                    >
                        <InfoIcon className='text-[#6B6B6B]'/>
                        {showMessage && (
                            <div id='hover-icon' className='flex flex-col gap-4 bg-[#F5F5F5] w-[16rem] sm:w-[20rem] min-h-fit p-2 rounded-md absolute right-0 top-6 z-50'>
                                {hoverMessageFirstLine && (
                                    <span dangerouslySetInnerHTML={{ __html: hoverMessageFirstLine }} />
                                )}
                                {hoverMessageSecondLine && (
                                    <span dangerouslySetInnerHTML={{ __html: hoverMessageSecondLine }} />
                                )}
                            </div>
                        )}
                    </span>
                </div>
            )}
        </div>
    )
}

const InfoIcon = ({className}:{className:string}) => {
    return (        
        <div className='h-max'>
            <svg stroke="#6B6B6B" fill="#6B6B6B" className={className} strokeWidth='0' viewBox="0 0 512 512" height="20px" width="20px" xmlns="http://www.w3.org/2000/svg"><path d="M256 90c44.3 0 86 17.3 117.4 48.6C404.7 170 422 211.7 422 256s-17.3 86-48.6 117.4C342 404.7 300.3 422 256 422s-86-17.3-117.4-48.6C107.3 342 90 300.3 90 256s17.3-86 48.6-117.4C170 107.3 211.7 90 256 90m0-42C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path><path d="M277 360h-42V235h42v125zm0-166h-42v-42h42v42z"></path></svg>
        </div>
    );
};
