import React from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';

import { useToast } from 'hooks';
import {
	INotificationRule,
	postRule,
	useGetNotificationTypesQuery
} from 'services/notifications';
import { useGetAvailableSurveysQuery } from 'services/surveys';

import { Loader } from 'components/common';
import {
	notificationRuleValidationSchema,
	Rule
} from 'components/notifications';
import { Layout } from 'components/shared';

import { ReactComponent as SvgWarning } from 'assets/icons/warning.svg';
import { useGetProperties } from 'services/properties';

const NewRule = () => {
	const show = useToast();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [isSending, setIsSending] = React.useState(false);

	const { data: notificationTypes, isLoading: isLoadingNotificationTypes } =
		useGetNotificationTypesQuery();

	const { data: surveys, isLoading: isLoadingSurveys } =
		useGetAvailableSurveysQuery({
			refetchOnWindowFocus: false
		});
	const { data: properties } = useGetProperties();

	if (isLoadingSurveys || isLoadingNotificationTypes) {
		return (
			<Layout pageTitle={''}>
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<Loader />
				</div>
			</Layout>
		);
	}

	if (!surveys || surveys.length===0) {
		return (
			<Layout pageTitle="Notification rule">
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">Please add a survey first</p>
				</div>
			</Layout>
		);
	}

	if (!notificationTypes) {
		return (
			<Layout pageTitle="Notification rule">
				<div className="w-full h-[60vh] flex flex-col items-center justify-center">
					<SvgWarning className="w-64 h-64 text-gray-200" />
					<p className="text-xl">No notification rule</p>
				</div>
			</Layout>
		);
	}

	const initialFormState: INotificationRule = {
		rule_name: '',
		notifications: [
			{
				notification_type_id: notificationTypes[0].notification_type_id,
				contacts: [
					{
						contact_id: 1,
						contact: '',
						property_id:null
					}
				]
			}
		],
		question_rules: [
			{
				survey_id: Number(surveys[0].survey_id),
				questions: [
					{
						question_id: 0,
						comparator: '',
						value: ''
					}
				]
			}
		]
	};

	const handleSubmit = (values: INotificationRule) => {
		setIsSending(true);
		postRule(values).then(() => {
			queryClient.invalidateQueries('notification_rules');
			show({
				type: 'success',
				headerText: 'Posted!',
				message: 'Successfully posted rule'
			});
			navigate(`/notifications`);
			setIsSending(false);
		});
	};

	return (
		<Layout pageTitle="Create new rule">
			<Formik
				validationSchema={notificationRuleValidationSchema}
				initialValues={initialFormState}
				onSubmit={handleSubmit}
			>
				{props => {
					return (
						<Form className="space-y-6 pb-6" id="notification-rule-form">
							<Rule
								notificationTypes={notificationTypes}
								surveys={surveys}
								formProps={props}
								properties={properties}
							/>
						</Form>
					);
				}}
			</Formik>

			{isSending && (
				<div className="fixed z-50 top-0 left-0 flex items-center justify-center w-full h-screen bg-white bg-opacity-10 backdrop-filter backdrop-blur-sm">
					<Loader />
				</div>
			)}
		</Layout>
	);
};

export default NewRule;
