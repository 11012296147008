export const saveCsv = (csvData: any, name: string) => {
    const csvBlob = new Blob([csvData], { type: 'text/csv' });

	const csvUrl = URL.createObjectURL(csvBlob);

	const downloadLink = document.createElement('a');

	downloadLink.href = csvUrl;
	downloadLink.download = name;
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);
};