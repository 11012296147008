import axios from 'axios';
import { ISurvey } from './interfaces';

export const updateSurvey = async (id: string, surveyData: ISurvey) => {
	return await axios
		.put<any>(`${process.env.REACT_APP_API_URL}/survey_api/${id}/`, surveyData)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error updating survey:`, error);
			return Promise.reject(error);
		});
};
