import React from 'react';
import { toast, ToastOptions, Slide } from 'react-toastify';

import { ReactComponent as SvgClose } from 'assets/icons/close.svg';

type UseToastType = () => ({
	headerText,
	message,
	type,
	options
}: IUseToastProps) => React.ReactText;

interface IUseToastProps {
	headerText: string;
	message: string;
	type: ToastType;
	options?: ToastOptions;
}

type ToastType = 'success' | 'warning' | 'error' | 'info';

type TextProp = {
	text: string;
};

const ToastHeader = ({ text }: TextProp) => {
	return <h3 className="font-bold">{text}</h3>;
};

const ToastContent = ({ text }: TextProp) => {
	return (
		<p data-testid="toast_text" className="text-sm">
			{text}
		</p>
	);
};

export const useToast: UseToastType = () => {
	const show = ({
		headerText,
		message,
		type = 'success',
		options
	}: IUseToastProps) => {
		toast.clearWaitingQueue();
		const Content = () => (
			<>
				{headerText && <ToastHeader text={headerText} />}
				<ToastContent text={message} />
			</>
		);

		const defaultOptions: ToastOptions = {
			toastId: 1,
			position: 'top-right',
			autoClose: 3000,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			progress: undefined,
			transition: Slide,
			closeButton: () => (
				<button type="button" aria-label="close">
					<SvgClose />
				</button>
			)
		};

		return toast[type](<Content />, {
			...defaultOptions,
			...options
		});
	};
	return show;
};
