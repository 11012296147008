import * as Yup from 'yup';
import { IAnswerType } from 'services/surveys/interfaces';

const maxTwoDecimals = /^\d+(\.\d{0,2})?$/;

export const templateValidationSchema = (langs: any) =>
	Yup.object().shape({
		survey_name: Yup.string()
			.min(2, 'Template name must contain at least 2 characters')
			.max(50, 'Template name must contain a maximum of 50 characters')
			.matches(
				/^[a-zA-Z0-9 ]+$/,
				'Template name should only contain letters and numbers'
			)
			.required('Required field'),
		general_questions: Yup.array().of(
			Yup.object().shape({
				label: Yup.object().shape(
					Object.fromEntries(
						langs.map((lang: string) => {
							return [
								lang,
								Yup.string()
									.min(2, 'Question label must contain at least 2 characters')
									.required('Question label is required')
							];
						})
					)
				),
				type: Yup.string().test('unique-property-question','Only one property question type alowed.',(questionType: any, shema: any) => {

					shema = shema.from[1].value;
					const propertyQuestionInSections: number = shema.sections.map((section:any) => section.questions) // Extract questions arrays from sections
					.flat() 
					.filter((question:any) => question.type === 'property') 
					.length;
					const propertyQuestionInGeneral: number = shema.general_questions
					.filter((question:any) => question.type === 'property') 
					.length;
					return questionType!="property" ? true : (propertyQuestionInGeneral+propertyQuestionInSections)<=1;
				}),
				is_redirect_question: Yup.string().test('unique-redirect-rating-question','Only one rating question type with redirect alowed.',(questionType: any, shema: any) => {

					shema = shema.from[1].value;
					const redirectRatingQuestionInSections: number = shema.sections.map((section:any) => section.questions) // Extract questions arrays from sections
					.flat() 
					.filter((question:any) => question.type === 'rating' && question.is_redirect_question) 
					.length;
					const redirectRatingQuestionInGeneral: number = shema.general_questions
					.filter((question:any) => question.type  === 'rating' && question.is_redirect_question) 
					.length;
					return (redirectRatingQuestionInSections+redirectRatingQuestionInGeneral)<=1;
				}),
				
				redirect_link: Yup.string()
				.url('Invalid URL')
				.nullable() // This makes the field nullable
				.when('is_redirect_question', {
					is: true,
					then: Yup.string().required('Redirect link is required'),
				}),
				
				choices: Yup.array().when('type', {
					is: (type: IAnswerType) =>
						type === 'select_one' ||
						type === 'select_multiple' ||
						type === 'sex' ||
						type === 'matrix_rating',
					then: Yup.array()
						.of(
							Yup.object().shape({
								label: Yup.object().shape(
									Object.fromEntries(
										langs.map((lang: string) => {
											return [
												lang,
												Yup.string()
													.min(
														1,
														'Choice label must contain at least 1 character'
													)
													.required('Choice label is a required field')
											];
										})
									)
								)
							})
						)
						.min(2, 'Provide at least two choices')
				}),

				range_max: Yup.number()
					.typeError('You must specify a number')
					.when('type', {
						is: (type: IAnswerType) => type === 'range',
						then: Yup.number()
							.typeError('You must specify a number')
							.required('This is a required field')
					}),

				range_min: Yup.number()
					.typeError('You must specify a number')
					.when('type', {
						is: (type: IAnswerType) => type === 'range',
						then: Yup.number()
							.typeError('You must specify a number')
							.min(0, 'Must be equal to or greater than 0')
							.required('This is a required field')
					})
					.when(
						'range_max',
						(range_max: number, schema) =>
							range_max &&
							schema.max(range_max - 1, `Must be less than ${range_max}`)
					),

				range_step: Yup.number()
					.test(
						'is-decimal',
						'The amount should be a decimal with maximum two digits after comma',
						(val: any) => {
							if (val != undefined) {
								return maxTwoDecimals.test(val);
							}
							return true;
						}
					)
					.typeError('You must specify a number')
					.when('type', {
						is: (type: IAnswerType) => type === 'range',
						then: Yup.number()
							.typeError('You must specify a number')
							.required('This is a required field')
							.min(1, 'Must be equal to or greater than 1')
					})

					.when('range_min', (range_min: number, schema) => {
						return schema.test({
							test: (range_step: number) => {
								return range_min !== 0 && range_min <= range_step
									? !(range_step % range_min !== 0)
									: true;
							},
							message: 'Range step must be divisible with Range min'
						});
					})
					.when('range_min', (range_min: number, schema) => {
						return schema.test({
							test: (range_step: number) => {
								return range_min !== 0 && range_min >= range_step
									? !(range_min % range_step !== 0)
									: true;
							},
							message: 'Range min must be divisible with Range step'
						});
					})

					.when('range_max', (range_max: number, schema) =>
						schema.when('range_min', (range_min: number, schema: any) =>
							schema.max(
								range_max - range_min,
								`Must be less than or equal to ${range_max - range_min}`
							)
						)
					)
					.when('range_max', (range_max: number, schema) =>
						schema.when('range_min', (range_min: number, schema: any) =>
							schema.test({
								test: (range_step: number) => {
									return !(
										(range_max * 100 - range_min * 100) % (range_step * 100) !==
										0
									);
								},
								message:
									'(Range max - Range min) must be divisible with Range step'
							})
						)
					)
			})
		),

		consent_text: Yup.object().nullable().test(
			'empty-text-validation',
			'If any consent_text value is not empty, all consent_text values must not be empty',
			(obj: { [key: string]: string } | null) => {
			  if(obj !==null && obj !==undefined){
					const values = Object.values(obj);
					if(values.length!==langs.length) return false; //ako za neki jezik ima teksta, za drugi uopće nam postavljeno ništa
					if (values.some((val) => val !== undefined && val !== '')) {
						return values.every((val) => val !== undefined && val !== '');
					}
				}
			  return true;
			}
		  )
	});
