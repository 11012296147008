import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button } from 'components/common';
import { FieldInput } from 'components/formik-elements';
import { IUserInfo, updateUserInfo } from 'services/users';
import { useToast } from 'hooks';

export interface IEditProfileFormProps {
	user: IUserInfo;
	className?: string;
	onSubmit?: (values: IUserInfo) => void;
}

export const EditProfileForm: React.FC<IEditProfileFormProps> = ({
	user,
	className,
	onSubmit
}) => {
	const usernameRegex = /^[a-zA-Z0-9.@]+$/;
	const show = useToast();
	const [isSubmitting, setIsSubmitting] = React.useState(false);

	const [backendError, setBackendError] = React.useState<Record<string, any>>(
		{}
	);

	const handleSubmit = (values: IUserInfo) => {
		setIsSubmitting(true);

		updateUserInfo(values)
			.then(() => {
				show({
					type: 'success',
					headerText: 'Updated!',
					message: 'Successfully updated profile info'
				});
				setIsSubmitting(false);
			})
			.catch(error => {
				if (error.response.data) {
					setBackendError(error.response.data);
				}
				setIsSubmitting(false);
			});

		onSubmit && onSubmit(values);
	};

	return (
		<Formik
			initialValues={{
				username: user?.username || '',
				first_name: user?.first_name || '',
				last_name: user?.last_name || '',
				email: user?.email || ''
			}}
			
			validationSchema={Yup.object().shape({
				username: Yup.string().required('This field is required').matches(usernameRegex,'Enter a valid username. This value may contain only letters, numbers, and "@","." characters.'),
				first_name: Yup.string().required('This field is required'),
				last_name: Yup.string().required('This field is required'),
				email: Yup.string()
					.email('Invalid email format')
					.required('This field is required')
			})}
			onSubmit={handleSubmit}
		>
			{props => {
				const { errors, submitCount } = props;

				return (
					<Form id="user-info-update-form" className={className || ''}>
						<FieldInput
							name="username"
							id="username"
							label="Username"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.username && errors.username) ||
									(backendError.username && backendError.username[0]) ||
									'')
							}
						/>
						<FieldInput
							name="first_name"
							id="first_name"
							label="First name"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.first_name && errors.first_name) ||
									(backendError.first_name && backendError.first_name[0]) ||
									'')
							}
						/>
						<FieldInput
							name="last_name"
							id="last_name"
							label="Last name"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.last_name && errors.last_name) ||
									(backendError.last_name && backendError.last_name[0]) ||
									'')
							}
						/>
						<FieldInput
							name="email"
							id="email"
							label="Email"
							type="email"
							className="mb-6"
							error={
								submitCount > 0 &&
								((errors.email && errors.email) ||
									(backendError.email && backendError.email[0]) ||
									'')
							}
						/>

						<Button
							text="Update"
							size="small"
							type="submit"
							disabled={isSubmitting}
							loading={isSubmitting}
						/>
					</Form>
				);
			}}
		</Formik>
	);
};
