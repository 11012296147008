export interface IInputFeedbackProps {
	name: string;
	error: string;
}

export const InputFeedback: React.FC<IInputFeedbackProps> = ({
	name,
	error
}) => {
	return (
		<p
			data-testid={`${name}-input-error`}
			className="text-xs text-red-500 mt-1 max-w-full w-full errorElement"
		>
			{error}
		</p>
	);
};
