import axios from 'axios';
import { IResetPassword } from '.';

export const resetPassword = async (data: IResetPassword) => {
	return await axios
		.patch<IResetPassword>(
			`${process.env.REACT_APP_API_URL}/password_reset_complete/`,
			data
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error reseting password:`, error);
			return Promise.reject(error);
		});
};
