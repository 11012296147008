import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { IEmailByIdResults } from './interfaces';

export const getEmailById = async (id: number) => {
	return await axios
		.get<IEmailByIdResults[]>(
			`${process.env.REACT_APP_API_URL}/email_api/${id}/`
		)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting email:`, error);
			return Promise.reject(error);
		});
};

export const useGetEmailByIdQuery = (id: number) => {
	return useQuery(
		['email', id] as QueryKey,
		() =>
			id
				? getEmailById(id)
				: Promise.reject('Error in useGetEmailByIdQuery: No id provided.'),
		{
			refetchOnWindowFocus: false,
			enabled: !!id
		}
	);
};
