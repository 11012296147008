import { relevantToSkipLogic } from 'components/survey/helpers';
import React from 'react';
import { IQuestion, ISurvey } from 'services/surveys/interfaces';

export interface ISkipLogicWarningProps {
	selectedQuestions: IQuestion[];
	template: ISurvey;
}

export const SkipLogicWarning: React.FC<ISkipLogicWarningProps> = ({
	selectedQuestions,
	template
}) => {
	const withSkipLogic = relevantToSkipLogic(selectedQuestions);
	const questionNames = selectedQuestions.map(question => question.name);

	const questions = withSkipLogic.filter(question =>
		question.skip_logic.some(
			skipLogicRule => !questionNames.includes(skipLogicRule.question)
		)
	);

	if (questions.length === 0) {
		return null;
	}

	return (
		<div className="w-full px-4 py-4 border border-red-700 bg-red-300 rounded-lg my-8">
			<p className="text-red-700 font-bold">
				You are trying to import one or more questions without its linked
				question(s):
			</p>

			<ul className="my-2 list-disc list-inside pl-4">
				{questions.map(question => {
					const linkedQuestions = question.skip_logic;

					return (
						<li className="text-red-700" key={question.name}>
							<span>
								{question?.label?.[template.survey_default_lang]} is linked to{' '}
							</span>

							{template.questions
								.filter(templateQuestion =>
									linkedQuestions?.some(linkedQuestion =>
										linkedQuestion.question.includes(templateQuestion.name)
									)
								)
								.map((linkedQuestion, i) => {
									return (
										<React.Fragment key={linkedQuestion.name}>
											<span>
												{linkedQuestion?.label?.[template.survey_default_lang]}
											</span>

											{i !== linkedQuestions.length - 1 &&
												i !== linkedQuestions.length - 2 && <span>, </span>}

											{i === linkedQuestions.length - 2 && <span> and </span>}
										</React.Fragment>
									);
								})}
						</li>
					);
				})}
			</ul>

			<p className="text-red-700 font-bold">
				You can proceed with this action but skip logic will be deleted.
			</p>
		</div>
	);
};
