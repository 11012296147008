import axios from 'axios';

export const editTag = async (id?: number, values?: any) => {
	return await axios
		.put<any>(`${process.env.REACT_APP_API_URL}/tag/${id}/`, values)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error editing tag:`, error);
			return Promise.reject(error);
		});
};
