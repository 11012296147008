import axios from 'axios';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

export type IUseGetAvailableSurveysOptions = UseQueryOptions<any, any, any>;

export const getAvailableSurveys = async () => {
	return await axios
		.get<any>(`${process.env.REACT_APP_API_URL}/get_available_surveys/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting surveys:`, error);
			return Promise.reject(error);
		});
};

export const useGetAvailableSurveysQuery = (
	queryOptions?: IUseGetAvailableSurveysOptions
) => {
	
	return useQuery(
		['available_surveys'] as QueryKey,
		() => getAvailableSurveys(),
		queryOptions
	);
};
