import { ReactComponent as SvgAlbania } from 'assets/flags/flag-al.svg';
import { ReactComponent as SvgCzechia } from 'assets/flags/flag-cz.svg';
import { ReactComponent as SvgDeutchland } from 'assets/flags/flag-de.svg';
import { ReactComponent as SvgEspana } from 'assets/flags/flag-es.svg';
import { ReactComponent as SvgGreatBritain } from 'assets/flags/flag-gb.svg';
import { ReactComponent as SvgHrvatska } from 'assets/flags/flag-hr.svg';
import { ReactComponent as SvgItalia } from 'assets/flags/flag-it.svg';
import { ReactComponent as SvgNederland } from 'assets/flags/flag-nl.svg';
import { ReactComponent as SvgNorge } from 'assets/flags/flag-no.svg';
import { ReactComponent as SvgPolska } from 'assets/flags/flag-pl.svg';
import { ReactComponent as SvgPortuguese } from 'assets/flags/flag-pt.svg';
import { ReactComponent as SvgRomania } from 'assets/flags/flag-ro.svg';
import { ReactComponent as SvgSrbija } from 'assets/flags/flag-rs.svg';
import { ReactComponent as SvgRussia } from 'assets/flags/flag-ru.svg';
import { ReactComponent as SvgSverige } from 'assets/flags/flag-se.svg';
import { ReactComponent as SvgSlovenia } from 'assets/flags/flag-si.svg';
import { ReactComponent as SvgSlovakia } from 'assets/flags/flag-sk.svg';
import { ReactComponent as SvgUkraine } from 'assets/flags/flag-ua.svg';
import { ReactComponent as SvgBosnia } from 'assets/flags/flag-ba.svg';
import { ReactComponent as SvgBulgaria } from 'assets/flags/flag-bg.svg';
import { ReactComponent as SvgChina } from 'assets/flags/flag-cn.svg';
import { ReactComponent as SvgDenmark } from 'assets/flags/flag-dk.svg';
import { ReactComponent as SvgEstonia } from 'assets/flags/flag-ee.svg';
import { ReactComponent as SvgFinland } from 'assets/flags/flag-fi.svg';
import { ReactComponent as SvgFrance } from 'assets/flags/flag-fr.svg';
import { ReactComponent as SvgGreece } from 'assets/flags/flag-gr.svg';
import { ReactComponent as SvgJapan } from 'assets/flags/flag-jp.svg';
import { ReactComponent as SvgKorea } from 'assets/flags/flag-kr.svg';
import { ReactComponent as SvgLithuania } from 'assets/flags/flag-lt.svg';
import { ReactComponent as SvgLatvia } from 'assets/flags/flag-lv.svg';
import { ReactComponent as SvgMontenegro } from 'assets/flags/flag-me.svg';
import { ReactComponent as SvgTurkey } from 'assets/flags/flag-tr.svg';

export const getLanguageFlag = (key: string, className?: string) => {
	const _className = `svg-no-prefill ${className || ''}`;
	switch (key) {
		case 'en':
			return <SvgGreatBritain className={_className} />;
		case 'es':
			return <SvgEspana className={_className} />;
		case 'hr':
			return <SvgHrvatska className={_className} />;
		case 'it':
			return <SvgItalia className={_className} />;
		case 'cs':
			return <SvgCzechia className={_className} />;
		case 'sl':
			return <SvgSlovenia className={_className} />;
		case 'sk':
			return <SvgSlovakia className={_className} />;
		case 'sq':
			return <SvgAlbania className={_className} />;
		case 'sr':
			return <SvgSrbija className={_className} />;
		case 'sv':
			return <SvgSverige className={_className} />;
		case 'uk':
			return <SvgUkraine className={_className} />;
		case 'ro':
			return <SvgRomania className={_className} />;
		case 'ru':
			return <SvgRussia className={_className} />;
		case 'pt':
			return <SvgPortuguese className={_className} />;
		case 'pl':
			return <SvgPolska className={_className} />;
		case 'no':
			return <SvgNorge className={_className} />;
		case 'nl':
			return <SvgNederland className={_className} />;
		case 'de':
			return <SvgDeutchland className={_className} />;
		case 'bs':
			return <SvgBosnia className={_className} />;
		case 'bg':
			return <SvgBulgaria className={_className} />;
		case 'zh':
			return <SvgChina className={_className} />;
		case 'da':
			return <SvgDenmark className={_className} />;
		case 'et':
			return <SvgEstonia className={_className} />;
		case 'fi':
			return <SvgFinland className={_className} />;
		case 'fr':
			return <SvgFrance className={_className} />;
		case 'el':
			return <SvgGreece className={_className} />;
		case 'ja':
			return <SvgJapan className={_className} />;
		case 'ko':
			return <SvgKorea className={_className} />;
		case 'lt':
			return <SvgLithuania className={_className} />;
		case 'lv':
			return <SvgLatvia className={_className} />;
		case 'me':
			return <SvgMontenegro className={_className} />;
		case 'tr':
			return <SvgTurkey className={_className} />;
		default:
			return <></>;
	}
};
