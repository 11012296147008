import axios from 'axios';


export const postQrCode= async (
	id?: string,
	data?: IPostQrCodeQueryParams
) => {
	
		return await axios
			.put<any>(
				`${process.env.REACT_APP_API_URL}/qr_code/${id}/`,
				data
			)
			.then(response => {
				return Promise.resolve(response.data);
			})
			.catch(error => {
				console.error(`Error posting survey qr code:`, error);
				return Promise.reject(error);
			});
			
};

export const postQrCodeLogo= async (
	id?: string,
	logoUrl?: IPostQrCodeLogoQueryParams
) => {
	
		return await axios
			.patch<any>(
				`${process.env.REACT_APP_API_URL}/update_qr_logo/?survey_id=${id}`,
				logoUrl
			)
			.then(response => {
				return Promise.resolve(response.data);
			})
			.catch(error => {
				console.error(`Error posting survey qr code:`, error);
				return Promise.reject(error);
			});
			
};


type IPostQrCodeQueryParams = {
	single_color: string;
	gradient: boolean;
    gradient_color:string;
    background_color:string;
    module_drawer:string;
};

type IPostQrCodeLogoQueryParams = {
	url:string |null;
};
