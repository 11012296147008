import React from 'react';
import { Form, Formik } from 'formik';

import { CustomFileUpload } from 'components/form-elements/CustomFileUpload';
import { FieldInput } from 'components/formik-elements';
import { postFile } from 'services/files';
import {
	IClientInfoUpdate,
	updateClientInfo,
	useGetClientInfoQuery
} from 'services/users';
import { Button, Loader } from 'components/common';
import { useToast } from 'hooks';
import HelpInfo from 'components/common/helpInfo/HelpInfo';

export interface IEditClientFormProps {
	className?: string;
}

export const EditClientForm: React.FC<IEditClientFormProps> = ({
	className
}) => {
	const show = useToast();
	const { data: clientInfo, isLoading, refetch } = useGetClientInfoQuery();

	if (isLoading || !clientInfo) {
		return (
			<Loader className="w-full min-h-[50vh] flex items-center justify-center" />
		);
	}

	const handleSubmit = (values: IClientInfoUpdate) => {
		updateClientInfo(clientInfo.client_info_id, values)
			.then(() => {
				show({
					type: 'success',
					headerText: 'Updated!',
					message: 'Successfully updated client info'
				});
				setTimeout(() => {
					refetch();
				}, 1000);
			})
			.catch(e => {
				console.info(e);
				show({
					type: 'error',
					headerText: 'Error!',
					message: `Your session may be expired, try submitting the form again.`
				});
			});
	};

	return (
		<Formik
			initialValues={{
				return_url: clientInfo?.return_url,
				logo: clientInfo?.logo
			}}
			onSubmit={handleSubmit}
		>
			{props => {
				const { values, getFieldHelpers } = props;

				return (
					<Form id="client-info-update-form" className={className || ''}>
						<FieldInput
							name="return_url"
							id="return_url"
							label="Return url"
							className="mb-6 max-w-md"
						/>

						<div className="mb-6">
							<label className="block text-sm font-medium text-gray-700 truncate mb-1">
								Logo
							</label>

							<HelpInfo 
								message='Logo can be used to customize your surveys'
								icon={true}
								hoverMessageFirstLine='Logos are <strong>images</strong> used to <strong>personalize your surveys</strong>, appearing beneath the survey title.'
								hoverMessageSecondLine='Set your default logo here to simplify the process of adding a logo to each new survey.'
							/>

							<div className="flex">
								{values.logo && (
									<img
										src={values.logo}
										alt="logo"
										className="max-w-xs object-contain mr-4"
									/>
								)}
								<CustomFileUpload
									name="logo"
									id="logo"
									onUpload={(e: any) => {
										postFile(e.target.files).then(res => {
											getFieldHelpers('logo').setValue(res.file_url);
										});
									}}
									onClear={() => {
										getFieldHelpers('logo').setValue('');
									}}
									hasValue={values.logo !== ''}
								/>
							</div>
						</div>

						<Button type="submit" text="Submit" />
					</Form>
				);
			}}
		</Formik>
	);
};
