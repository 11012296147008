import axios from 'axios';
import { QueryKey, useQuery } from 'react-query';
import { ILanguage } from '.';

export const getLanguages = async () => {
	return await axios
		.get<ILanguage[]>(`${process.env.REACT_APP_API_URL}/get_languages/`)
		.then(response => {
			return Promise.resolve(response.data);
		})
		.catch(error => {
			console.error(`Error getting languages:`, error);
			return Promise.reject(error);
		});
};

export const useGetLanguagesQuery = () => {
	return useQuery('languages' as QueryKey, () => getLanguages(), {
		refetchOnWindowFocus: false
	});
};
