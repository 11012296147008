// move helpers

import { FieldArrayRenderProps, FieldHelperProps } from 'formik';
import { IQuestion, ISurveyUI } from 'services/surveys/interfaces';
import { addQuestionToGeneral, addQuestionToSection, isLastInGroup } from '.';

export const orderUp = (
	arrayHelpers: FieldArrayRenderProps,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	indexInGroup: number,
	question: IQuestion,
	groupName: string,
	formValues: ISurveyUI
) => {
	if (indexInGroup !== 0) {
		arrayHelpers.move(indexInGroup, indexInGroup - 1);
	} else {
		moveToPrevSection(
			question,
			groupName,
			formValues,
			getFieldHelpers,
			arrayHelpers,
			indexInGroup
		);
	}
};

export const orderDown = (
	arrayHelpers: FieldArrayRenderProps,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	indexInGroup: number,
	question: IQuestion,
	groupName: string,
	formValues: ISurveyUI
) => {
	if (!isLastInGroup(question, formValues)) {
		arrayHelpers.move(indexInGroup, indexInGroup + 1);
	} else {
		moveToNextSection(
			question,
			groupName,
			formValues,
			getFieldHelpers,
			arrayHelpers,
			indexInGroup
		);
	}
};

export const moveToNextSection = (
	question: IQuestion,
	currentGroupName: string,
	survey: ISurveyUI,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	arrayHelpers: FieldArrayRenderProps,
	indexInGroup: number
) => {
	const nextSectionIndex =
		currentGroupName == 'general_questions'
			? 0
			: Number(currentGroupName.match(/\d+/)?.[0]) + 1;

	if (survey.sections[nextSectionIndex]) {
		addQuestionToSection(
			getFieldHelpers,
			survey,
			nextSectionIndex,
			question,
			true
		);
		arrayHelpers.remove(indexInGroup);
	}
};

export const moveToPrevSection = (
	question: IQuestion,
	currentGroupName: string,
	survey: ISurveyUI,
	getFieldHelpers: <Value = any>(name: string) => FieldHelperProps<Value>,
	arrayHelpers: FieldArrayRenderProps,
	indexInGroup: number
) => {
	const prevSectionIndex =
		currentGroupName === 'sections.1.questions'
			? 0
			: Number(currentGroupName.match(/\d+/)?.[0]) - 1;

	if (currentGroupName == 'sections.0.questions') {
		addQuestionToGeneral(arrayHelpers, question);
		arrayHelpers.remove(indexInGroup);
	}

	if (survey.sections[prevSectionIndex]) {
		addQuestionToSection(getFieldHelpers, survey, prevSectionIndex, question);
		arrayHelpers.remove(indexInGroup);
	}
};
